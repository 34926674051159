import { toServerDateTimeFormat, toServerFilterDate } from '@shiftnex/framework/src/libs/dayjs';
import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';

export function initDayJsPlugins() {
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(toServerDateTimeFormat);
  dayjs.extend(toServerFilterDate);
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetweenPlugin);
  dayjs.extend(updateLocale);
}

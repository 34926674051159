import { ComponentProps, ReactNode } from 'react';
import { styled } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { AppLogo, AppLogoText } from '@atoms/appLogo';
import { Flex } from '@atoms/layout';
import { UndecoratedNavLink } from '@molecules/navigation/undecoratedNavLink';
import { SidebarButton } from '@molecules';
import { drawerWidth } from '../../../theme';
import { SidebarMenuButton } from '@molecules/navigation';
import { SidebarSubMenuItem } from '@molecules/navigation/sidebarMenuButton';
import { useNavigate } from 'react-router-dom';

type SidebarProps = {
  topItems?: MenuItem[];
  bottomItems?: MenuItem[];
};
export const Sidebar = ({ topItems = [], bottomItems = [] }: SidebarProps) => {
  return (
    <SidebarDrawer aria-label="SidebarDrawer" variant="permanent" anchor="left">
      <Flex justifyCenter aria-label="SidebarBaseLogoContainer">
        <AppLogo />
      </Flex>

      <ListBoxContainer mt={2} column aria-label="SidebarListBoxContainer" justifyContent="space-between">
        <List aria-label="SidebarTopListContainer">
          <Stack gap={1}>
            {topItems.map((props, idx) => (
              <SidebarItem {...props} key={idx} />
            ))}
          </Stack>
        </List>

        <Flex column alignCenter>
          <List aria-label="SidebarBottomListContainer">
            <Stack gap={1}>
              {bottomItems.map((props, idx) => (
                <SidebarItem {...props} key={idx} />
              ))}
            </Stack>
          </List>
        </Flex>
      </ListBoxContainer>
      <AppLogoText />
    </SidebarDrawer>
  );
};

export const SidebarItem = ({ id, path, icon, label, children, anchorPosition, onClick }: MenuItem) => {
  const navigate = useNavigate();
  if (children?.length) {
    return (
      <SidebarMenuButton
        active={location.pathname.includes(`/${path}`)}
        icon={icon}
        label={label}
        children={children || []}
        anchorPosition={anchorPosition}
        onClick={() => {
          if (onClick) {
            onClick();
            return; // break execution in case if onClick defined
          }
          if (!location.pathname.includes(`/${path}`)) {
            navigate(`/${path}`);
          }
        }}
      />
    );
  }

  if (onClick) {
    return (
      <SidebarButton active={location.pathname.includes(`/${path}`)} icon={icon} label={label} onClick={onClick} />
    );
  }
  return (
    <UndecoratedNavLink to={`/${path}`} key={id}>
      {({ isActive }) => <SidebarButton active={isActive} icon={icon} label={label} />}
    </UndecoratedNavLink>
  );
};

export type MenuItem = {
  id: string;
  label: ReactNode;
  icon: ComponentProps<typeof SidebarButton>['icon'];
  path?: string;
  children?: SidebarSubMenuItem[];
  onClick?: () => void;
  // anchor position for the context submenu
  anchorPosition?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

const ListBoxContainer = styled(Flex)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  scrollbarGutter: '7px',
  [`::-webkit-scrollbar`]: {
    width: '3px',
    height: '3px',
    borderRadius: '3px',
  },
}));

const SidebarDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  max-height: '100%',
  padding-left: 0,
  padding-right: 0,
  flex-shrink: 0;
  & .${drawerClasses.paper} {
    padding-top: 12px;
    padding-bottom: 12px;
    scrollbar-gutter: 7px;
    padding-left: 0;
    padding-right: 0;
    width: ${drawerWidth}px;
    box-sizing: border-box;
    border-radius: 0 16px 16px 0;
    border: none;
  }
`;

import { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormControlLabel, Grid, IconButton, Stack, Switch, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SequenceItem } from '@libs/models/shifts/rotation';
import { FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';

type RotationSequenceSectionProps = {
  localizationSubset: string;
  onChange: (sequence: SequenceItem[]) => void;
};

export function RotationSequenceSection({ localizationSubset, onChange }: RotationSequenceSectionProps) {
  const intl = useIntl();
  const [sequence, setSequence] = useState<SequenceItem[]>([{ isPause: false, durationInDays: 1, order: 0 }]);

  useEffect(() => {
    onChange && onChange(sequence.map((seq, idx) => ({ ...seq, order: idx })));
  }, [sequence]);

  const handleToggleChange = (value: boolean, index: number) => {
    const newVer = [...sequence];
    newVer[index] = { ...newVer[index], isPause: value };
    setSequence(newVer);
  };

  const handleDaysChange = (value: number, index: number) => {
    const newVer = [...sequence];
    newVer[index] = { ...newVer[index], durationInDays: value };
    setSequence(newVer);
  };

  const handleAdd = (index: number) => {
    const newVer = [...sequence];
    newVer.splice(index + 1, 0, { order: index, isPause: false, durationInDays: 1 });
    setSequence(newVer);
  };
  const handleDelete = (index: number) => {
    const newVer = [...sequence];
    newVer.splice(index, 1);
    setSequence(newVer);
  };

  return (
    <Stack direction={'column'} spacing={2}>
      {sequence.map((seq, idx) => (
        <Grid container key={idx}>
          <Grid item xs={6}>
            <FormControlLabel
              checked={!seq.isPause}
              onChange={(evt: SyntheticEvent<Element, Event>, checked: boolean) => handleToggleChange(!checked, idx)}
              control={<Switch />}
              label={intl.formatMessage({ id: `${localizationSubset}.rotationSequence.workingDayToggle.label` })}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} sx={{ width: '100%' }} gap={2}>
              <TextField
                value={seq.durationInDays}
                InputProps={{
                  type: 'number',
                  inputProps: { min: 1 }
                }}
                onChange={(event) => {
                  +event.target.value <= 0 && (event.target.value = '' + 1);
                  event.target.value = '' + parseInt(event.target.value, 10);
                  handleDaysChange(+event.target.value, idx);
                }}
              />
              <Flex>
                <IconButton size="small" onClick={() => handleAdd(idx)} sx={{ width: '40px' }}>
                  <AddIcon sx={{ width: '24px', height: '24px' }} />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDelete(idx)}
                  sx={{ width: '40px', visibility: idx > 0 ? 'visible' : 'hidden' }}
                >
                  <FaIcon name="trash-can" color="inherit" />
                </IconButton>
              </Flex>
            </Stack>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}

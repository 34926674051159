import { TalentListItem } from '@libs/models/talents';
export function prepareTalentsOptions(talents: TalentListItem[], selectedQualifications: string[]) {
  return (
    talents
      .filter((t) => {
        if (t.staffingStatus === 'Deactivated') {
          // Show only active talents
          return false;
        }

        // Show only talents with selected qualifications
        return t.nurseQualifications.some((q) => selectedQualifications.includes(q.id));
      })
      // Internal first, then by name
      .sort((a, b) => {
        if (a.staffingType === b.staffingType) {
          return [a.firstName, a.lastName].join(' ').localeCompare([b.firstName, b.lastName].join(' '));
        }
        if (a.staffingType === 'Internal') {
          return -1;
        } else if (b.staffingType === 'Internal') {
          return 1;
        }
        return a.staffingType.localeCompare(b.staffingType);
      })
      .map((talent) => {
        return {
          key: talent.id,
          staffingType: talent.staffingType,
          // Group by staffing type
          groupBy: talent.staffingType,
          label: [talent.firstName, talent.lastName].filter(Boolean).join(' '),
          value: talent.id,
          qualifications: talent.nurseQualifications,
        };
      })
  );
}

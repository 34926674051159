import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import { Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { useForm } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import {
  CollapsibleFormBlock,
  FormBlock,
  FormCheckbox,
  FormDatePicker,
  FormNumberField,
  FormRadioGroup,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '@molecules/form';
import { useLocationStore } from '@libs/store/locations';
import { SingleShift } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';

import { DayOfWeekFull } from '@libs/models/common';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { SequenceItem, ShiftRotationUpdate } from '@libs/models/shifts/rotation';
import { AssigneesSelect } from './assigneesSelect';
import { QualificationSelect } from './qualificationSelect';
import { RotationScheduleSection } from './rotationScheduleSection';
import { RotationSequenceSection } from './rotationSequenceSection';
import { parseError } from '@libs/api/errors';
import { LocationSelectionSection } from './components/locationSection';
import { useShiftDrawerTalents } from '@libs/store/shifts';

type EditSeriesDrawerProps = {
  onSaveCall?: () => void;
};
export function EditRotationDrawer({ onSaveCall }: EditSeriesDrawerProps) {
  const intl = useIntl();
  const { showErrorIntl } = useNotification();

  const {
    drawerOpen,
    loading: rotationLoading,
    data: currentRotationDetails,
    rotationId,
    closeShiftRotationDrawer,
    load,
    drawerMode,
    createRotation,
  } = useShiftRotationAddEditStore();
  const { locations, loading: locationsLoading, currentLocationID, loadSimple } = useLocationStore();

  useEffect(() => {
    if (!currentLocationID) {
      loadSimple();
    }
  }, [currentLocationID]);

  const qualStore = useDirectQualificationsStore();
  const { load: loadDirectQualifications, loading: isDirectListLoading } = qualStore;
  const directQualifications = qualStore.data || [];

  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = orgQualStore;

  const { load: loadProviders, talents, loading: talentsLoading } = useShiftDrawerTalents();

  const [sequence, setSequence] = useState<SequenceItem[]>([]);
  const [dateTimeSlots, setDateTimeSlots] = useState<
    {
      id: string;
      start: Dayjs;
      end: Dayjs;
    }[]
  >([]);

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addRotationDrawer' : 'shifts.editRotationDrawer';
  const open = drawerOpen;

  const loading = rotationLoading || isOrgQualListLoading || talentsLoading || locationsLoading || isDirectListLoading;

  const initial: ShiftRotationUpdate = {
    numberOfCopies: 1, // positive int
    assignees: [], // array of ids
    staffingType: 'All',
    startDate: dayjs(new Date()).toServerDateTimeFormat(), // here should be a date from the server on Edit operation
    endDate: dayjs(new Date()).toServerDateTimeFormat(), // here should be a date from the server on Edit operation
    dateTimeSlots: [],
    sequence: [],
    departmentNodeId: '',
    specialityId: '',
    locationId: '',
    timeZone: '',
    staffNurseQualificationFilterIds: [], // Qualification ids
    staffOtherQualificationFilterIds: [], // Qualification ids
    tags: [],
    note: '',
    isUrgent: false,
    subDepartmentNodeId: '',
    isNeedToPublish: false,
  };

  const [currentShift, setCurrentShift] = useState<ShiftRotationUpdate>({
    ...initial,
    ...(currentRotationDetails || {}),
  });

  const methods = useForm<ShiftRotationFormData>({
    defaultValues: {
      template: '',
      numberOfCopies: 1,
      isUrgent: currentShift.isUrgent,

      durationType: 'setEndDate',
      startDate: null, //dayjs(currentShift.startDate),
      endDate: null, //dayjs(currentShift.endDate),

      seriesPreset: '',
      shiftTypePreset: '',
      startTime: null, //dayjs(currentShift.startDate),
      endTime: null, //dayjs(currentShift.startDate),

      location: currentLocationID,
      department: '',
      subDepartment: '',
      speciality: '',

      staffingType: 'All',
      qualifications: [],
      assignees: [],

      tags: '',
      notes: '',
    },
  });
  const { watch, setValue } = methods;

  const scheduledUniqueDays = Array.from(new Set(dateTimeSlots.map((slot) => slot.start.format('YYYY-MM-DD'))));

  useEffect(() => {
    if (rotationId) {
      load(rotationId);
    }
  }, [rotationId]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentRotationDetails,
    });
  }, [currentRotationDetails]);

  useEffect(() => {
    if (!directQualifications.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const handleClose = () => {
    closeShiftRotationDrawer();
    methods.reset();
  };

  const handleSave = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, false))();
    onSaveCall && onSaveCall();
  };

  const handlePublish = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, true))();
    onSaveCall && onSaveCall();
  };

  const handleSubmit = async (isPublish: boolean, data: ShiftRotationFormData) => {
    const locationDetails = locations.find((loc) => loc.id === data.location);
    const tagsParsed = (data.tags || '').split(',').map((tag) => tag.trim());

    const dataToSend: ShiftRotationUpdate = {
      numberOfCopies: data.numberOfCopies,
      assignees: data.assignees,
      staffingType: data.staffingType,
      startDate: data.startDate!.toServerDateTimeFormat(),
      endDate: data.endDate!.toServerDateTimeFormat(),
      sequence: sequence,
      dateTimeSlots: dateTimeSlots.map((slot) => {
        if (slot.start.isAfter(slot.end)) {
          // If start date is after end date, shift ends on the next day
          return {
            startDate: slot.start.toServerDateTimeFormat(),
            endDate: slot.end.add(1, 'day').toServerDateTimeFormat(),
          };
        }
        return {
          startDate: slot.start.toServerDateTimeFormat(),
          endDate: slot.end.toServerDateTimeFormat(),
        };
      }),
      departmentNodeId: data.department,
      specialityId: data.speciality,
      locationId: data.location,
      timeZone: locationDetails!.timeZone,
      staffNurseQualificationFilterIds: data.qualifications,
      staffOtherQualificationFilterIds: [],
      tags: tagsParsed,
      note: data.notes || '',
      isUrgent: !!data.isUrgent,
      subDepartmentNodeId: data.subDepartment,
      isNeedToPublish: isPublish,
    };
    try {
      await createRotation(dataToSend);
      handleClose();
    } catch (error) {
      showErrorIntl(parseError(error, `${localizationSubset}.errors`).message);
    }
  };

  const handleCancel = handleClose;

  const durationType = watch('durationType');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const startTime = watch('startTime');
  const endTime = watch('endTime');
  const assigneesIds = watch('assignees');
  const assigned = talents.filter((talent) => assigneesIds.includes(talent.id));
  const locationId = watch('location');
  const numberOfCopies = watch('numberOfCopies');

  useEffect(() => {
    const filters = [];
    filters.push({ key: 'locations', value: [locationId] });
    // TODO: use autocomplete
    loadProviders({ size: 50, filters: filters.map((f) => ({ field: f.key, value: f.value })) });
  }, [loadProviders, locationId]);

  useEffect(() => {
    if (endDate && endDate?.toDate() > dayjs('2099-12-31').toDate()) {
      setValue('endDate', dayjs('2099-12-31'));
    }
  }, [endDate]);

  useEffect(() => {
    if (startDate && startDate?.toDate() > dayjs('2099-12-31').toDate()) {
      setValue('startDate', dayjs('2099-12-31'));
    }
  }, [startDate]);

  useEffect(() => {
    setDateTimeSlots(calcTimeSlots(startDate, endDate, startTime, endTime, sequence));
  }, [startDate, endDate, startTime, endTime, sequence]);

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handlePublish} disabled={loading}>
            <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <MenuButton variant="outlined" onClick={handleSave} disabled={loading}>
            <FormattedMessage id="common.save" />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handleCancel}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }} onSubmit={handleSubmit.bind(handleSubmit, false)}>
          {/* Shift configuration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="template"
                options={[]}
              />
              <FormNumberField
                disabled={loading || !isAddDialog}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                minValue={1}
                name="numberOfCopies"
              />
            </Stack>
            <FormCheckbox
              name="isUrgent"
              label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
              size="medium"
            />
          </FormBlock>

          {/* Date and duration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />}>
            <Stack direction={'column'} spacing={1}>
              <FormRadioGroup
                name="durationType"
                label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.label`} />}
                items={[
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.setEndDate`} />
                    ),
                    value: 'setEndDate',
                  },
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.infiniteLoop`} />
                    ),
                    value: 'infiniteLoop',
                    disabled: true,
                  },
                ]}
                required
                disabled={loading}
                row
              />
              <Grid container direction={'row'}>
                <Grid item xs={6} sx={{ paddingRight: '8px' }}>
                  <FormDatePicker
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                    fullWidth
                    // minDate={dayjs(new Date())}
                    name="startDate"
                    defaultValue={null}
                    required
                  />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: '8px' }}>
                  {durationType === 'setEndDate' && (
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endDate.label`} />}
                      fullWidth
                      minDate={dayjs(startDate)}
                      name="endDate"
                      defaultValue={null}
                      required
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
          </FormBlock>
          {/* Series details */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.rotationDetails.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormSelect
                  disabled
                  label={<FormattedMessage id={`${localizationSubset}.rotationDetails.seriesPreset.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.rotationDetails.seriesPreset.placeholder`,
                  })}
                  fullWidth
                  name="seriesPreset"
                  options={[]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  disabled
                  label={<FormattedMessage id={`${localizationSubset}.rotationDetails.shiftTypePreset.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.rotationDetails.shiftTypePreset.placeholder`,
                  })}
                  fullWidth
                  name="shiftTypePreset"
                  options={[]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTimePicker
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.rotationDetails.startTime.label`} />}
                  fullWidth
                  name="startTime"
                  defaultValue={null}
                  timezone="UTC"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTimePicker
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.rotationDetails.endTime.label`} />}
                  fullWidth
                  name="endTime"
                  timezone="UTC"
                  defaultValue={null}
                  required
                />
              </Grid>
            </Grid>
          </FormBlock>
          {/* Rotation sequence */}
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.rotationSequence.sectTitle`} />}
            expanded
            sectTitleBadges={
              <Chip
                size="small"
                label={
                  sequence.length
                    ? sequence.reduce((acc, seq, idx, array) => {
                        if (array.length === 1 && array[0].durationInDays === 0) {
                          return intl.formatMessage({
                            id: `${localizationSubset}.rotationSequence.sectBadges.noSequence`,
                          });
                        }
                        const label = seq.isPause ? 'Off' : 'On';
                        return `${acc} ${seq.durationInDays} ${label}`;
                      }, '')
                    : intl.formatMessage({ id: `${localizationSubset}.rotationSequence.sectBadges.noSequence` })
                }
              />
            }
          >
            <RotationSequenceSection
              localizationSubset={localizationSubset}
              onChange={(seq) => {
                setSequence(seq);
              }}
            />
          </CollapsibleFormBlock>
          {/* Rotation schedule preview */}
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.rotationSchedulePreview.sectTitle`} />}
            expanded
            sectTitleBadges={
              <Chip
                size="small"
                label={intl.formatMessage(
                  { id: `${localizationSubset}.rotationSchedulePreview.sectBadges.spendTime` },
                  { amount: scheduledUniqueDays.length },
                )}
              />
            }
          >
            <RotationScheduleSection
              localizationSubset={localizationSubset}
              onChange={setDateTimeSlots}
              slots={dateTimeSlots}
              defaultTimeSlot={{
                start: startTime,
                end: endTime,
              }}
            />
          </CollapsibleFormBlock>
          {/* Location */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.location.sectTitle`} />}>
            <LocationSelectionSection localizationSubset={localizationSubset} loading={loading} />
          </FormBlock>

          {/* Talent */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormRadioGroup
                  name="staffingType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
                  items={[
                    {
                      label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                      value: 'All',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                      ),
                      value: 'Internal',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                      ),
                      value: 'External',
                    },
                  ]}
                  required
                  disabled={loading}
                  row
                />
              </Grid>
              <Grid item xs={12}>
                <QualificationSelect
                  fullWidth
                  name="qualifications"
                  required
                  localizationSubset={localizationSubset}
                  disabledOptions={assigned.flatMap((a) => a.nurseQualifications.map((q) => q.id))}
                />
              </Grid>
              <Grid item xs={12}>
                <AssigneesSelect
                  fullWidth
                  name="assignees"
                  numberOfCopies={numberOfCopies}
                  localizationSubset={localizationSubset}
                  selectedQualifications={watch('qualifications')}
                  disabled={watch('qualifications').length === 0}
                />
              </Grid>
            </Grid>
          </FormBlock>

          {/* Additional info */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTextField
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                  })}
                  fullWidth
                  name="tags"
                />
              </Grid>
            </Grid>
          </FormBlock>
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />}
          >
            <FormTextField
              name="notes"
              fullWidth
              multiline
              disabled={loading}
              options={{
                maxLength: 300,
              }}
              InputProps={{
                sx: { height: '150px', overflow: 'hidden' },
              }}
            />
          </CollapsibleFormBlock>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

type DayMark = DayOfWeekFull;

type ShiftRotationFormData = {
  template: string;
  numberOfCopies: number;
  isUrgent: boolean;

  durationType: 'setEndDate' | 'infiniteLoop';
  startDate: Dayjs | null;
  endDate: Dayjs | null;

  seriesPreset: string;
  shiftTypePreset: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;

  location: string;
  department: string;
  subDepartment: string;
  speciality: string;

  staffingType: SingleShift['staffingType'];
  qualifications: string[];
  assignees: string[];

  tags: string;
  notes: string;
};

function calcTimeSlots(
  startDate?: Dayjs | null,
  endDate?: Dayjs | null,
  startTime?: Dayjs | null,
  endTime?: Dayjs | null,
  sequence: SequenceItem[] = [],
) {
  if (!startDate || !endDate || !startTime || !endTime) {
    return [];
  }
  const slotsDays = [];
  let currentDate = startDate
    .clone()
    .set('hour', startTime?.hour() || 0)
    .set('minute', startTime?.minute() || 0);
  const seqMask = sequence.flatMap(({ isPause, durationInDays }) =>
    [...Array(durationInDays).keys()].map(() => (isPause ? 0 : 1)),
  );
  if (seqMask.length === 0) {
    return [];
  }
  let seqIndex = 0;

  while (!currentDate.isAfter(endDate.add(1, 'day'))) {
    if (seqIndex >= seqMask.length) {
      seqIndex = 0;
    }

    if (seqMask[seqIndex] === 1) {
      slotsDays.push(currentDate.clone());
    }
    seqIndex++;
    currentDate = currentDate.add(1, 'day');
  }

  return slotsDays.map((d) => ({
    id: Math.random().toString(36).slice(2, 9),
    start: d,
    end: d
      .clone()
      // Add 1 day if end time is before start time (on the next day)
      .add(startTime.isAfter(endTime) ? 1 : 0, 'day')
      .set('hour', endTime.hour() || 0)
      .set('minute', endTime.minute() || 0),
  }));
}

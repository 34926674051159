import { Box } from '@atoms/layout';
import { Stack, Typography, Chip, Avatar, useTheme } from '@mui/material';
import { Doctor } from '../scheduler/lib/Doctor';
import { FaIcon } from '@atoms/icons';

type CalendarItemProps = {
  data: Doctor;
  hoursScheduled: number;
};

export function ResourceItem({ data, hoursScheduled }: CalendarItemProps) {
  const theme = useTheme();
  const quals = data.nurseQualifications && data.nurseQualifications.length ? data.nurseQualifications : [];
  hoursScheduled = Math.ceil(+hoursScheduled);
  const maxHours = 40;
  return (
    <Stack direction={'column'} flex={1}>
      <Stack
        direction={'row'}
        spacing={1}
        sx={(theme) => ({
          width: '100%',
          height: '64px',
          paddingLeft: '8px',
          paddingTop: '8px',
        })}
      >
        <Avatar src={data.avatar} sx={{ top: '8px', width: '32px', height: '32px' }} />
        <Stack direction={'column'} flex={1} sx={(theme) => ({})}>
          <Stack
            direction={'row'}
            sx={(theme) => ({
              paddingBottom: '4px',
            })}
          >
            <Typography
              sx={(theme) => ({
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 'bolder',
                letterSpacing: '0.1px',
                color: theme.palette.text.primary,
              })}
            >
              {data.firstName || ''} {data.lastName || ''}
            </Typography>
            {/* Rating - non used for now */}
            {/* <Stack direction={'row'} sx={{ marginTop: '1px', marginLeft: '4px' }}>
              <FaIcon name="star" variant="solid" color={'#ffb400'} width={16} height={16} />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                })}
              >
                {data.rating}
              </Typography>
            </Stack> */}
            <Box flex={1} />
          </Stack>

          <Stack direction={'row'} sx={(theme) => ({})}>
            {quals.map((qual, idx) => (
              <Chip label={qual.abbreviation} key={idx} size="small" sx={{ fontWeight: 500 }} />
            ))}
            <Box flex={1} />
            <Stack direction={'row'} sx={{ marginTop: '4px', marginLeft: '4px', marginRight: '4px' }} spacing={0.5}>
              <FaIcon
                name="clock"
                variant="solid"
                width={16}
                height={16}
                color={
                  hoursScheduled > maxHours
                    ? theme.palette.error.main
                    : hoursScheduled === maxHours
                      ? theme.palette.success.main
                      : theme.palette.text.primary
                }
              />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color:
                    hoursScheduled > maxHours
                      ? theme.palette.error.main
                      : hoursScheduled === maxHours
                        ? theme.palette.success.main
                        : theme.palette.text.primary,
                })}
              >
                {hoursScheduled} / {maxHours}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

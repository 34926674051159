import { ReactNode } from 'react';
import { noop } from '@libs/utils/empty';
import { alpha, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
import { Divider, listItemClasses } from '@mui/material';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { SidebarButton } from '@molecules';
import { useIntl } from 'react-intl';

type Icon = ReactNode | ((props: { active: boolean }) => ReactNode);
type SidebarMenuButtonProps = {
  active: boolean;
  label: ReactNode;
  icon: Icon;
  onClick?: () => void;
  children: SidebarSubMenuItem[];
  anchorPosition?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

type SubItemTypes = 'element' | 'divider' | 'heading';

export type SidebarSubMenuItem = {
  type: SubItemTypes;
  label: string;
  path?: string;
  icon?: React.ComponentProps<typeof SidebarButton>['icon'];
  disabled?: boolean;
};

export const SidebarMenuButton = ({
  icon,
  label,
  active,
  onClick = noop,
  anchorPosition,
  children,
}: SidebarMenuButtonProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const iconElement = typeof icon === 'function' ? icon({ active }) : icon;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuButtonClick: React.MouseEventHandler<HTMLDivElement> = (evt) => {
    handleClick(evt as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>);
    onClick();
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    handleClose();
  };

  return (
    <>
      <SidebarItem selected={active} onClick={handleMenuButtonClick}>
        <Icon>{iconElement}</Icon>
        <Typography variant={active ? 'subtitle2' : 'body2'} lineHeight="20px">
          {label}
        </Typography>
      </SidebarItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorPosition || { vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children.map(({ type, label, path, disabled }, i) => {
          let element = null;
          element = type === 'divider' ? <Divider key={i} /> : element;
          element =
            type === 'heading' ? <MenuHeading key={i}>{intl.formatMessage({ id: label })}</MenuHeading> : element;
          element =
            type === 'element' ? (
              <StyledMenuItem key={path} disabled={disabled} onClick={() => handleMenuItemClick(path as string)}>
                {intl.formatMessage({ id: label })}
              </StyledMenuItem>
            ) : (
              element
            );
          return element;
        })}
      </Menu>
    </>
  );
};

const MenuHeading = styled(MenuItem)`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.1px;
`;
const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  margin-left: 16px;
`;

const SidebarItem = styled(ListItemButton)`
  border-radius: 8px;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};
  &:hover {
    background: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
  }
  &.${buttonBaseClasses.focusVisible} {
    background: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
  }
  &.${listItemClasses.selected} {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Icon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 24px;
  height: 24px;
  min-width: 0;
  display: flex;
  justify-content: center;
`;

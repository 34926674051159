import { Column, DateHelper, PresetManager, ProjectModelConfig, ResourceModel } from '@bryntum/schedulerpro';
import { BryntumGridProps, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';
import { Shift } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { CalendarItem } from './components/calendarItem';
import { ResourceItem } from './components/resourceItem';
import dayjs from 'dayjs';
import { renderToString } from 'react-dom/server';
import { ThemeProvider } from '../../../../theme';
import { IntlProvider } from '@libs/intl';
import { gridConfigFeatureOffs, schedulerConfigFeatureOffs} from './scheduler/featureTurnOffs';

export const projectConfig: ProjectModelConfig = {
  resourceStore: {
    useRawData: true,
    modelClass: Doctor,
    sorters: [{ field: 'name', ascending: true }],
  },

  // events: data.events.rows,
  eventStore: {
    // Unassigned events should remain in store
    removeUnassignedEvent: false,
    syncDataOnLoad: false,
    modelClass: Shift,
  },

  calendars: [
    {
      id: 'workweek',
      name: 'Work week',
      intervals: [
        {
          recurrentStartDate: 'on Sat',
          recurrentEndDate: 'on Mon',
          isWorking: false,
        },
      ],
    },
    {
      id: 'allday',
      name: 'All day',
      unspecifiedTimeIsWorking: false,
      intervals: [
        {
          recurrentStartDate: 'every weekday at 01:00',
          recurrentEndDate: 'every weekday at 23:59',
          isWorking: true,
        },
      ],
    },
  ],
  calendar: 'dayAndWeek',
  dependencies: [],
};

PresetManager.registerPreset('myDayWeek', {
  id: 'myDayWeek',
  base: 'dayAndWeek',
  shiftUnit: 'week',
  timeResolution: {
    increment: 1,
    unit: 'd',
  },
  headers: [
    {
      unit: 'd',
      align: 'center',
      dateFormat: 'ddd DD',
    },
  ],
});

export const schedulerConfig: BryntumSchedulerProProps = {
  ...schedulerConfigFeatureOffs,
  rowHeight: 64,
  barMargin: 10,
  tickSize: 150,
  fillTicks: true,
  eventStyle: 'border',
  eventColor: 'blue',
  allowOverlap: true,

  columns: [
    {
      type: 'template',
      field: 'search',
      text: 'Search Provider',
      width: 220,
      sortable(userLeft, userRight) {
        const user1 = userLeft as Doctor;
        const user2 = userRight as Doctor;
        const user1Constraint = user1.firstName || user1.lastName;
        const user2Constraint = user2.firstName || user2.lastName;
        return user1Constraint < user2Constraint ? -1 : 1;
      },
      template: (props) => {
        const talent = props.record as Doctor;
        // The issue tread: https://github.com/bryntum/support/issues/6987
        const component = (
          <ThemeProvider>
            <ResourceItem data={talent} hoursScheduled={talent.getBookedHours()} />
          </ThemeProvider>
        );
        return renderToString(component);
      },
    },
  ],
  searchFeature: {},
  viewPreset: {
    id: 'myDayWeek',
    base: 'dayAndWeek',
    shiftUnit: 'week',
    timeResolution: {
      increment: 1,
      unit: 'd',
    },
    headers: [
      {
        unit: 'd',
        align: 'center',
        dateFormat: 'ddd DD',
      },
    ],
  },
  presets: [
    {
      id: 'myDayWeek',
      base: 'dayAndWeek',
      shiftUnit: 'week',
      timeResolution: {
        increment: 1,
        unit: 'd',
      },
      headers: [
        {
          unit: 'd',
          align: 'center',
          dateFormat: 'ddd DD',
        },
      ],
    },
  ],

  timeRangesFeature: {
    showHeaderElements: true,
  },
  groupFeature: {
    field: 'staffingType',
    disabled: false,
    headerHeight: 48,
    renderer({ groupRowFor, column }: { groupRowFor: string; column: Column }) {
      if (column.parentIndex === 0) {
        return `${groupRowFor}`;
      }
      return '';
    },
  },
  timeSpanHighlightFeature: true,
  getDateConstraints: (resourceRecord, eventRecord) => {
    return {
      start: new Date(eventRecord?.startDate || Date.now()),
      end: new Date(eventRecord?.startDate || Date.now()),
    };
  },
  onEventDragAbort(event) {
    (event.source as any).unhighlightTimeSpans();
  },
  filterBarFeature: {
    compactMode: true,
  },

  overlappingEventSorter: (a, b) => {
    return new Date((a as Shift).actualStartDate).getTime() - new Date((b as Shift).actualStartDate).getTime();
  },
  eventLayout: {
    type: 'stack',
  },
};

// Custom grid that holds unassigned appointments
export const gridConfig: BryntumGridProps = {
  ...gridConfigFeatureOffs,
  selectionMode: {
    cell: false,
    // multiSelect : false,
  },
  rowHeight: 64,
  stripeFeature: true,
  sortFeature: 'startDate',
  filterFeature: true,
  filterBarFeature: {
    compactMode: true,
  },
  groupFeature: {
    field: 'grouping',
    // ascending : false,
    groupSortFn(first, second) {
      return (
        dayjs(first.grouping, 'MMM D, YYYY').toDate().getTime() -
        dayjs(second.grouping, 'MMM D, YYYY').toDate().getTime()
      );
    },
    renderer({ groupRowFor, column }: { groupRowFor: Date; column: Column }) {
      if (column.parentIndex === 0) {
        return `${DateHelper.format(new Date(groupRowFor), 'MMM D, YYYY')}`;
      }
      return '';
    },
  },
  columns: [
    {
      type: 'template',
      field: 'search',
      text: 'Unassigned Shifts',
      flex: 1,
      autoHeight: true,
      cellCls: 'unscheduledNameCell',
      template: ({ record }) => {
        const component = (
          <ThemeProvider>
            <IntlProvider>
              <CalendarItem data={record as Shift} mode="grid" />
            </IntlProvider>
          </ThemeProvider>
        );

        return renderToString(component);
      },
    },
  ],
  disableGridRowModelWarning: true,
};

import { type IRenderer, type Method } from '../types';
import { METHODS } from '../constants';

const methodsMap = {
  [METHODS.LOG]: console.log,
  [METHODS.DEBUG]: console.log,
  [METHODS.INFO]: console.info,
  [METHODS.WARN]: console.warn,
  [METHODS.ERROR]: console.error,
  [METHODS.FATAL]: console.error,
  [METHODS.CRITICAL]: console.error,
};

export class Console implements IRenderer {
  write(method: Method, tags: string[], ...rest: any[]) {
    methodsMap[method](...tags.map((tag) => `[${tag}]`), ` [${method.toUpperCase()}] `, ...rest);
  }
}

import { flattenMessages } from '../flattenMessages';

export default flattenMessages({
  common: {
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    save: 'Save',
    submit: 'Submit',
    confirm: 'Confirm',
    weekDays: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    greeting: 'Hello, {name}!',
    clear: 'Clear',
    applyFilters: 'Apply filters',
    back: 'Back',
    next: 'Next',
    search: 'Search',
    filter: 'Filter',
    all: 'All',
    name: 'Name',
    actions: 'Actions',
    clearAllFilters: 'Clear all filters',
    status: 'Status',
    location: 'Location',
    yes: 'Yes',
    no: 'No',
    unknownError: 'Something went wrong',
    qualification: 'Position',
    shiftType: 'Shift Type',
    view: 'View',
    edit: 'Edit',
    deleteImage: 'Delete image',
    fileValidationError: 'Wrong file format: image should be PNG or JPG format, {sizeMb}MB max.',
    errors: {
      base: 'Something went wrong',
    },
    selectNone: 'None',
  },
  serverErrors: {
    shifts: {
      OverlapDetected: {
        simple: 'Current assignee has time overlap for chosen day\\time',
        // @TODO add parameters for the nex message {overlapStartDate} and {overlapEndDate}
        extended: 'Current assignee has time overlap for chosen day\\time',
      },
    },
  },
  auth: {
    login: {
      title: 'Login',
      subtitle: 'Please, provide your email or phone number to receive an OTP',
      tabEmailTitle: 'Email',
      tabPhoneTitle: 'Phone number',
      emailPlaceholder: 'e.g. jhondoe@gmail.com',
      signUpText: 'Don’t have a ShiftNex account yet? <link>Sign Up</link>',
    },
    otp: {
      title: 'Secure verification',
      subtitle: 'We’ve just sent you a temporary 6-digit code verification. Please check email and/or phone.',
      inputPlaceholder: 'Enter the code',
      resendCode: 'Resend the code',
      resendCodeSuccess: 'Code successfully resent',
    },
    adminSignUp: {
      title: 'Welcome to ShiftNex!',
      titleWithName: '{name}, welcome to ShiftNex!',
      subtitle: 'Join the ShiftNex Healthcare Platform and enjoy the way we manage healthcare staffing operations',
      otpBySmsLabel: 'Receive OTP by sms',
      otpBySmsInstructions: 'Please, fill in your phone number if you want receive the one-time password  by SMS',
      submitLabel: 'Create account',
    },
    adminSignUpDetails: {
      title: 'Agency',
      subtitle: 'Please, fill in your agency details.',
    },
    adminSignUpAddress: {
      title: 'Address',
      subtitle: 'Please, fill in your agency address details.',
    },
    footer: {
      legalMessage:
        'By using {name} you agree to the <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
      version: 'Version {version}',
    },
    submitLabel: 'Continue',
    signInText: 'Already have a ShiftNex account? <link>Sign In</link>',
  },
  sidebar: {
    poweredBy: 'Powered by Shiftnex',
    staffingPartners: 'Staffing Partners',
    dashboard: 'Dashboard',
    schedule: 'Schedule',
    contacts: 'Contacts',
    talent: 'Providers',
    attendance: 'Attendance',
    reports: 'Reports',
    marketplace: 'Marketplace',
    settings: 'Settings',
    billing: 'Billing',
    help: 'Help',
  },
  onboarding: {
    progress: {
      gettingStarted: 'Getting started',
      menuHeader: 'Onboarding',
    },
    organization: {
      title: 'Organization details',
      subtitle: 'Fill in your organization details',
      subtitleLong: 'Fill in basic information about your organization',
    },
    users: {
      title: 'Users',
      titleAlternative: 'Users',
      subtitle: 'Add users and set up the roles',
      subtitleLong: 'Create and invite system users of your organization',
    },
    qualifications: {
      title: 'Positions & Specialties',
      titleAlternative: 'Positions & Specialties',
      subtitle: 'Set up positions and specialties used in your organization.',
      subtitleLong:
        'Set up positions and specialties used in your organization. You can select them from default system list or create custom ones',
    },
    departments: {
      title: 'Departments',
      titleAlternative: 'Departments',
      subtitle: 'Create departments and related sub-entities of your organization structure',
      subtitleLong: 'Create departments and related sub-entities of your organization structure',
    },
    locations: {
      title: 'Locations',
      titleAlternative: 'Locations',
      subtitle: 'Add and manage your organization’s locations.',
      subtitleLong:
        'Add and manage your organization’s locations. You can create a unique structure for each location here',
    },
    providers: {
      title: 'Providers',
      titleAlternative: 'Providers',
      subtitle: 'Add healthcare providers so you can assign them to shifts',
      subtitleLong: 'Add healthcare providers so you can assign them to shifts',
    },
    staffingPartners: {
      title: 'Staffing partners',
      subtitle: 'Set up the relationship with agencies',
      subtitleLong:
        'View staffing partners available on ShiftNex platform. You can share your shifts with them, so they can propose their providers for assignment',
    },
    schedule: {
      title: 'Schedule',
      subtitle: 'Create and assign shifts',
      subtitleLong: 'Manage the schedule of your organization. Here you can create, edit, assign and share the shifts',
    },
    billing: {
      title: 'Billing',
      subtitle: "Control your organizations' billing and manage payment methods.",
      subtitleLong:
        "Control your organizations' billing and manage payment methods. The invoices from your staffing partners and platform fees will be displayed in this module",
    },
    widget: {
      hide: 'Hide',
      show: 'Show Onboarding',
      previousStep: 'Previous step',
      nextStep: 'Next step',
      addDetails: 'Add details',
      setBranding: 'Set branding',
      getStarted: 'Get started',
    },
  },
  license: {
    trial: {
      daysLeft: '{days} days left in trial',
      headerButtonLabel: 'Upgrade',
    },
    freeBeta: {
      headerButtonLabel: 'Free beta',
      popup: {
        heading: 'Welcome to ShiftNex Free Beta!',
        headingSubtitle:
          "W're excited to have you on board! Enjoy the beta experience, and let us know how we can improve.",
        sects: {
          knowledgeBase: {
            heading: 'Knowledge base',
            headingSubtitle: 'Access helpful text and video instructions to guide you through every feature',
          },
          supportChat: {
            heading: 'Support chat',
            headingSubtitle: 'Have questions? Connect with our support team for real-time assistance',
          },
        },
        contact: {
          heading: 'Account managers',
        },
        links: {
          termsNConditions: 'Terms & Conditions',
          privacyPolicy: 'Privacy Policy',
          aggreement: 'ShiftNex Closed Beta Agreement: Version 25.1',
          hipaa: 'HIPAA Acknowledgment Form',
          ccpa: 'CCPA Notice',
        },
      },
    },
  },
  header: {
    manageAccount: 'Manage account',
    profileMenu: {
      pauseNotificationsLabel: 'Pause notifications',
      profileSettingsLabel: 'Profile settings',
      supportLabel: 'Support chat',
      reportLabel: 'Report an issue',
      logOutLabel: 'Log out',
    },
    notificationSchedule: {
      label: 'Pause notifications for...',
      postponeMins: 'For {value, plural, one {# minute} other {# minutes}}',
      postponeHours: 'For {value, plural, one {# hour} other {# hours}}',
      postponeTomorrow: 'Until tomorrow',
    },
    refresh: {
      label: 'Last synced',
    },
  },
  comingSoon: {
    title: 'Coming soon...',
    description: 'The page is not yet available but we are working on it',
    votableDescription: {
      active:
        'We’re working hard to bring you powerful reporting features. Let us know how important this functionality is to you!',
      inactive: 'Thanks for voting! We’ll notify you when this feature is live.',
    },
    voteButton: {
      label: 'Vote for the feature',
    },
  },
  settings: {
    menu: {
      organization: {
        title: 'Organization',
      },
      rolesAndUsers: {
        title: 'Users',
      },
      systemUsers: {
        title: 'System Users',
      },
      privacyAndSecurity: {
        title: 'Privacy and security',
      },
      organizationDetails: {
        title: 'Organization details',
      },
      documents: {
        title: 'Documents',
      },
      branding: {
        title: 'Branding',
      },
      qualificationsSpecialties: {
        title: 'Positions & Specialties',
      },
      qualifications: {
        title: 'Positions',
      },
      specialties: {
        title: 'Specialties',
      },
      departments: {
        title: 'Departments',
      },
      locations: {
        title: 'Locations',
      },
    },
    organization: {
      savedSuccessfully: 'Organization details saved successfully',
      title: 'Organization details',
      subtitle: 'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
      mainDetails: {
        title: 'Main details',
        subtitle: 'Please, fill in organization contact details and EIN',
      },
      address: {
        title: 'Facility address',
        subtitle: 'Please, fill in your facility address details.',
      },
      contact: {
        title: 'Main facility contact',
        subtitle: 'Please, fill in contact details',
      },
      operational: {
        title: 'Operational setup',
        subtitle: 'Set up the way organization operates',
      },
      fields: {
        orgName: {
          label: 'Organization name',
        },
        orgType: {
          label: 'Facility type',
          placeholder: 'Please select a types of your facility',
        },
        orgRegistrationDate: {
          label: 'Registration date',
        },
        numberOfEmployees: {
          label: 'Number of employees',
          placeholder: 'PLease select an option',
        },
        website: {
          label: 'Facility website',
          placeholder: 'http://example.com',
        },
        NPI: {
          label: 'NPI',
          placeholder: 'e.g. 123-45-6789',
        },
      },
    },
    qualSpeck: {
      qualifications: {
        title: 'Positions',
        subtitle:
          'In this section you can add positions used in your organization. You can select default values provided by the system or create your custom ones. Please note that position is mandatory for all users. Also, system default entities cannot be edited or deleted.',

        directQualBox: {
          title: 'Clinical',
          titleInfoTooltip:
            'Once a position is in use (mapped to a department and assigned at least to one user) it cannot be deleted or unchecked',
          emptyListPlaceholderText: 'No positions inside "Nurses" category',
          editQualListButtonLabel: 'Edit positions',
        },
        editDirectQalDialog: {
          dialogHeading: 'Clinical',
          listCounter: '{amount} positions',
          addButton: 'Add position',
          selectTitle: 'Position',
          selectPlaceholder: 'Position',
          abbreviationTitle: 'Abbreviation',
          noAbbreviationLabel: 'no position chosen',
        },
        otherQualBox: {
          title: 'Other',
          titleInfoTooltip:
            'Once a position is in use (mapped to a department and assigned at least to one user) it cannot be deleted or unchecked',
          emptyListPlaceholderText: 'No positions inside "Other" category',
          editQualListButtonLabel: 'Edit positions',
        },
        editOtherQalDialog: {
          dialogHeading: 'Other',
          listCounter: '{amount} positions',
          addButton: 'Add position',
          selectTitle: 'Position',
          abbreviationTitle: 'Abbreviation',
        },
        errors: {
          QualificationInUseBySpecialty:
            'This position is used for one or multiple entities of your organization and cannot be deleted',
          NurseQualificationInUseByInternalStaff:
            'This position is used for one or multiple entities of your organization and cannot be deleted',
          OtherQualificationInUseByDepartment: 'Non-clinical position is in use by one or more departments',
          NurseQualificationInUseByShift: 'This position is used for one or multiple shifts and cannot be deleted',
          NurseQualificationInUseByDepartment:
            'This position is used for one or multiple entities of your organization and cannot be deleted',
          NurseQualificationInUseByExternalStaff:
            'This position is used for one or multiple entities of your organization and cannot be deleted',
        },
      },
      specialties: {
        title: 'Specialties',
        subtitle:
          'Here you can add specialties of your facility. Each specialty should be mapped to one or multiple positions.',
        specialtiesCounter: '{amount} Specialties',
        addSpecialtyButtonLabel: 'Add specialty',
        table: {
          noRows: {
            title: 'No specialties yet',
            subtitle: "You don't have any specialties yet",
          },
          columns: {
            specialty: {
              heading: 'Specialty',
            },
            qualification: {
              heading: 'Position',
            },
          },
        },
        editSpecialtyDialog: {
          heading: 'Edit Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical positions',
          otherQualificationsSectHeading: 'Other positions',
          errors: {
            qualNotSelected: 'Please select at least one position',
          },
        },
        addSpecialtyDialog: {
          heading: 'New Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical positions',
          otherQualificationsSectHeading: 'Other positions',
          errors: {
            qualNotSelected: 'Please select at least one position',
          },
        },
      },
    },
    departments: {
      listPage: {
        title: 'Departments',
        subtitle:
          'Add departments to your organization structure. Than you can add sub-departments and lower sub-levels (up to five). In this tab you can also select positions and specialties required for each department. Note that sub-departments positions can be added from department’s list.',
        departmentsCounter: '{amount} Departments',
        addDepartmentButton: 'Add department',
        itemTypeLabels: {
          department: 'Department',
          subdepartment: 'Sub-department',
        },
        itemMenu: {
          editButton: {
            tooltip: 'Edit',
          },
          removeButton: {
            tooltip: 'Delete',
          },
          addButton: {
            tooltip: 'Add a sub element',
          },
        },
      },
      newDepartmentDialog: {
        dialogHeading: 'New department',
        directQualificationsSectHeading: 'Clinical positions',
        otherQualificationsSectHeading: 'Other positions',
        specialtiesSectHeading: 'Specialties',
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      editDepartmentDialog: {
        dialogHeading: 'Edit department',
        directQualificationsSectHeading: 'Clinical positions',
        otherQualificationsSectHeading: 'Other positions',
        specialtiesSectHeading: 'Specialties',
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      newSubDepartmentDialog: {
        dialogHeading: 'New sub-department',
        directQualificationsSectHeading: 'Clinical positions',
        otherQualificationsSectHeading: 'Other positions',
        specialtiesSectHeading: 'Specialties',
        deptParentTitleSelection: {
          title: 'Parent entity title',
        },
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      editSubDepartmentDialog: {
        dialogHeading: 'Edit sub-department',
        directQualificationsSectHeading: 'Clinical positions',
        otherQualificationsSectHeading: 'Other positions',
        specialtiesSectHeading: 'Specialties',
        deptParentTitleSelection: {
          title: 'Parent entity title',
        },
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      locationSpecific: 'Location specific',
      qualificationIsRequiredError: 'At least one position should be selected',
      errors: {
        DepartmentNodeHasAssociatedLocation:
          'This entity is currently in use. You cannot edit or delete it. Please remove any dependencies before trying again.',
        DepartmentNodeHasShifts:
          'There are shifts scheduled for this organization entity. Cancel the shifts before deletion',
      },
    },
    locations: {
      listPage: {
        title: 'Locations',
        subtitle:
          'This section displays the list of locations included in your facility. In ‘Structure’ sub-tab you can view and edit your organization hierarchical tree and it’s entities',
        locationsCounter: '{amount} Locations',
        addLocationButtonLabel: 'Add location',
        table: {
          noRows: {
            title: 'No locations yet',
            subtitle: "You don't have any locations yet",
          },
          columns: {
            name: {
              heading: 'Location name',
            },
            region: {
              heading: 'Region',
            },
            contact: {
              heading: 'Main contact',
            },
            email: {
              heading: 'Email',
            },
            status: {
              heading: 'Status',
            },
          },
        },
      },
      newLocationPage: {
        heading: 'New location',
      },
      editPage: {
        backButton: {
          label: 'Back to Locations',
        },
        tabs: {
          general: {
            label: 'General details',
            form: {
              main: {
                heading: 'Main details',
                subtitle: "Enter the location's basic details such as name and contact information.",
                locationName: {
                  label: 'Location name',
                  placeholder: 'Please add a name for location',
                },
                isActive: {
                  label: 'Location is active',
                },
                isHeadquarters: {
                  label: 'Location is a headquarters',
                },
              },
              address: {
                heading: 'Location address',
                subtitle: 'Provide the full address for this location.',
              },
              contact: {
                heading: 'Main location contact',
                subtitle: 'Add the primary contact person’s details for this location.',
                primaryContactName: {
                  label: "Primary contact's full name",
                  placeholder: 'Enter full name',
                },
                contactJobTitle: {
                  label: 'Job title',
                  placeholder: 'Enter job title',
                },
                primaryPhoneNumber: {
                  label: 'Primary phone number',
                  placeholder: 'Enter job title',
                },
                secondaryPhoneNumber: {
                  label: 'Secondary phone number',
                  placeholder: 'Enter job title',
                },
              },
              operational: {
                heading: 'Operational setup',
                subtitle: 'Choose the starting day of the operational week for this location.',
              },
            },
          },
          structure: {
            label: 'Location structure',
            addDeptButton: {
              label: 'Add department',
            },
          },
        },
      },
      table: {
        noRows: {
          title: 'No locations yet',
          subtitle: "You don't have any locations yet",
        },
      },
      errors: {
        OneOrManyDepartmentNodesInUseBy:
          'There are shifts scheduled for this organization entity. Cancel the shifts before deletion',
      },
    },
    users: {
      title: 'System users',
      subtitle:
        'This section displays non-clinical personnel, such as administrative and management staff, who handle operational tasks but are not eligible for shift assignments',
      status: {
        InviteFailed: 'Invite failed',
        Invited: 'Invited',
        Pending: 'Pending',
        Active: 'Active',
        Deactivated: 'Deactivated',
      },
      usersCount: '{number, plural, =0 {No users} one {# user} other {# users} }',
      table: {
        noRows: {
          title: 'No users yet',
          subtitle: 'You don’t have any users yet added',
        },
      },
      addUserButtonLabel: 'Add user',
      userForm: {
        title: 'Add system user',
        editTitle: 'Edit system user',
        stepGeneral: 'General',
        stepEmployment: 'Employment & Positions',
        success: 'User has been created successfully',
        submitNew: {
          label: 'Invite',
        },
        validationErrorIconTooltip: 'Some validation errors occurred',
      },
      error: {
        409: 'User with this email already exists',
      },
      resendInvite: 'Resend invite',
      sendInvite: {
        success: 'Invite has been sent successfully',
      },
      delete: 'Delete',
      deactivate: 'Deactivate',
      deleteConfirmation: {
        title: 'Delete user',
        content: 'Are you sure you want to delete this user?',
        success: 'User has been deleted successfully',
      },
    },
  },

  forms: {
    requiredFieldsMissing: '{number} mandatory fields missing',
    fieldIsRequired: 'This field is required',
    maxLengthError: 'Max length is {number}',
    minLengthError: 'Min length is {number}',
    invalidPhoneNumber: 'Please, enter valid phone number',
    invalidEIN: 'Please, check the format of the input',
    invalidZipCode: 'Please, enter valid zip code',
    invalidEmail: 'The entered value is not an email',
    emptyLinesOfBusiness: 'Select at least one business line of the organization',
    invalidWebsite: 'Please, enter valid website',
    positiveNumberOnly: 'Please, enter a positive number',
    maxValueError: 'Max number is {number}',
    minValueError: 'Min number is {number}',
  },
  fields: {
    firstName: 'First name',
    firstNamePlaceholder: 'e.g. Jhon',
    lastName: 'Last name',
    lastNamePlaceholder: 'e.g. Doe',
    legalName: 'Legal name',
    agencyName: 'Agency name',
    agencyNamePlaceholder: 'Enter agency name',
    doingBusinessAs: 'Doing business as',
    agencyType: 'Agency type',
    agencyTypePlaceholder: 'Select agency type',
    registrationDate: 'Registration date',
    organizationWebsite: 'Organization website',
    organizationWebsitePlaceholder: 'Enter organization website',
    phone: 'Phone',
    phonePlaceholder: '+1 (XXX) XXX-XXXX',
    employerIdentificationNumber: 'Employer Identification Number (EIN)',

    primaryContactsFullName: 'Primary contact’s full name',
    primaryContactsFullNamePlaceholder: 'Enter primary contact’s full name',
    jobTitle: 'Job title',
    jobTitlePlaceholder: 'Enter job title',
    primaryPhoneNumber: 'Primary phone number',
    useOrganizationPhoneNumber: 'Use organization phone number',
    secondaryPhoneNumber: 'Secondary phone number',
    email: 'Email',
    emailPlaceholder: 'e.g. jhondoe@gmail.com',
    organizationsContactEmail: 'Enter organization’s contact email',

    addressLine1: 'Street address 1',
    addressLine1NoLocations: 'No locations',
    addressLine2: 'Street address 2',
    addressLinePlaceholder: 'Enter street name, number',
    cityAndState: 'City & State',
    region: 'Region',
    regionHelper:
      'Region is the zone where your location operates. It is fetched from city and state indicated in location’s address',
    zipCode: 'Zip code',

    weekStartDay: 'Working week start',
    linesOfBusiness: 'Lines of business of the organization',
    linesOfBusinessShort: 'Lines of business',
    linesOfBusinessPlaceholder: 'Select lines of business',
    jobQualifications: 'Provider Job Positions',
    jobQualificationsPlaceholder: 'Select positions that apply',
    qualifications: 'What positions do you staff?',
    qualificationsPlaceholder: 'Select the positions you staff',
    specialty: 'Specialty',

    professionals: 'Professionals',
    statuses: 'Statuses',
    timezone: 'Timezone',
    timezonePlaceholder: 'Select a timezone',
    talents: 'Providers',
    minValueError: 'Minimum value is {value}',
  },
  shifts: {
    type: {
      shifts: 'Shifts',
      series: 'Series & Rotations',
    },
    table: {
      noRows: {
        title: 'No shifts yet',
        subtitle: 'You don’t have any shifts yet',
      },
    },
    fields: {
      shiftType: 'Shift type',
      staffingtypes: 'Staffing type',
      recurrence: 'Recurrence',
      location: 'Location',
      qualification: 'Position',
      qualifications: 'Positions',
      specialty: 'Specialty',
      specialties: 'Specialty',
      status: 'Status',
      statuses: 'Status',
      notes: 'Notes',
      assignee: 'Assignee',
      startDate: 'Start Date',
      shiftDate: 'Shift date',
      sharedWithin: 'Shared within',
      cityAndState: 'City & State',
      locationName: 'Location name',
      address: 'Address',
      department: 'Department',
      subDepartment: 'Sub department',
      talents: 'Providers',
      staff: 'Providers',
      duration: 'Duration',
      date: 'Dates',
      departments: 'Departments',
      isShared: 'Shared',
    },
    submenu: {
      calendarViews: {
        label: 'Calendar views',
      },
      weeklyByAssignee: {
        label: 'Weekly by assignee',
      },
      weeklyByRole: {
        label: 'Weekly by role',
      },
      monthlyPlanning: {
        label: 'Monthly planning',
      },
      listViews: {
        label: 'List views',
      },
      allShifts: {
        label: 'All shifts',
      },
      seriesAndRotations: {
        label: 'Series and rotations',
      },
      templates: {
        label: 'Templates',
      },
      singleShiftsSeriesNRotations: {
        label: 'Single shifts, series & rotations',
      },
      shiftTypes: {
        label: 'Shift types',
      },
      historyLog: {
        label: 'History log',
      },
    },
    status: {
      assigned: 'Assigned',
      reassigned: 'Re-assigned',
      pending: 'Pending',
      completed: 'Completed',
      canceled: 'Canceled',
      na: 'N/A',
      opened: 'Open',
      unassigned: 'Unassigned',
      reopened: 'Reopened',
      noshow: 'No Show',
      inprogress: 'In Progress',
      unfilled: 'Unfilled',
      expired: 'Expired',
      deleted: 'Deleted',

      // @TODO remove next one keys once make all the statuses in lower case on resolve
      inProgress: 'In progress',
      reAssigned: 'Re-assigned',
      Opened: 'Open',
      Pending: 'Pending',
      Assigned: 'Assigned',
      Unassigned: 'Unassigned',
      Reopened: 'Reopened',
      NoShow: 'No Show',
      InProgress: 'In Progress',
      Completed: 'Completed',
      Canceled: 'Canceled',
      Unfilled: 'Unfilled',
      Expired: 'Expired',
    },
    lengthAndType: '{length}h, {type}',
    length: '{length}h',
    emptyNote: 'There is no note related to the shift',
    assignBtnLabel: 'Assign',
    assign: 'Assign',
    menuItemUnassign: 'Unassign',
    menuItemReAssign: 'Re-assign',
    assignSuccessful: 'Shift has been assigned successfully',
    unassignSuccessful: 'Shift has been unassigned successfully',
    deleteSuccessful: 'Shift has been deleted successfully',
    copySuccessful: 'Shift has been copied successfully',
    assignError: {
      StaffNotFound: 'Provider not found',
      StaffDeactivated: 'Provider is deactivated',
      ShiftNotFound: 'Shift not found',
      ShiftRotationNotFound: 'Shift rotation not found',
      ShiftSeriesNotFound: 'Shift series not found',
      OverlapDetected:
        'This provider has another shift scheduled for this date. The changes won’t be applied. In order to change start date or time unassign the scheduled shift first',
    },
    unassignDialog: {
      title: 'Unassign shift',
      subtitle: 'Please, specify the reason',
      reasonLabel: 'Reason of unassigning',
      reasonPlaceholder: 'Select the reason',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
      reasonCommentLabel: 'What is the issue?',
      reasonCommentPlaceholder: 'Please, specify the reason',
    },
    unassignButton: {
      disabledTooltip: "You can't unassign shift with this status",
    },
    cancelDialog: {
      title: 'Cancel shift',
      subtitle: 'Please, specify the reason',
      reasonLabel: 'Reason of canceling',
      reasonPlaceholder: 'Select the reason',
      reasonCommentLabel: 'What is the issue?',
      reasonCommentPlaceholder: 'Please, specify the reason',
      cancelSuccessful: 'Shift has been canceled successfully',
    },
    recurrence: {
      single: 'Single',
      series: 'Series',
      rotation: 'Rotation',
    },
    assignDialog: {
      title: 'Assign shift',
      placeholder: 'Please, specify the provider',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
    },
    startDateAll: 'All',
    shiftDetails: 'Shift details',
    locationDetails: 'Location details',
    mainScheduler: 'Main scheduler',
    afterHoursScheduler: 'After-hours scheduler',
    generalInfo: 'General info',
    notes: 'Notes',
    billing: 'Billing',
    noNotes: {
      title: 'No notes yet',
      subtitle: 'There are no notes related to this shift',
    },
    dayOneInstructions: 'Day one instructions',
    additionalNotes: 'Additional notes',
    historyLog: 'History log',

    logs: {
      created: 'Shift created',
      shared: 'Shift shared',
      assigned: 'Shift assigned:',
      started: 'Shift started:',
      shiftTime: '{date}, {timeRange}, {shiftLength}h {shiftType}',
    },
    switchForTodayButton: {
      label: 'Today',
    },
    addFilterButton: {
      label: 'Filter',
    },
    publishShiftButton: {
      label: 'Publish',
    },
    shareShiftButton: {
      label: 'Share',
    },
    newShiftButton: {
      label: 'Create',
      menu: {
        singleShift: {
          label: 'Single shift',
        },
        newSeries: {
          label: 'Series',
        },
        newRotation: {
          label: 'Rotation',
        },
      },
    },
    calendarView: {},
    stuffingType: {
      all: 'Any',
      internal: 'Internal',
      external: 'External',
    },
    addShiftDrawer: {
      dialogHeading: 'New single shift',
      internalIDPlaceholder: 'ID: {shiftId}',

      menuButtons: {
        back: {
          label: 'Back',
        },
        publish: {
          label: 'Publish',
        },
        saveDraft: {
          label: 'Save draft',
        },
        delete: {
          label: 'Delete',
        },
        save: {
          label: 'Save',
        },
      },

      shiftConfiguration: {
        sectTitle: 'Shift configuration',
        shiftTemplate: {
          label: 'Shift template',
          placeholder: 'Shift template',
        },
        quantity: {
          label: 'Quantity',
        },
        markAsUrgent: {
          label: 'Mark as urgent',
        },
      },
      talentConfiguration: {
        sectTitle: 'Provider',
        talentType: {
          label: 'Provider type',
          items: {
            all: 'Any',
            internal: 'Internal',
            external: 'External',
          },
        },
        assignType: {
          label: 'Assign type',
          items: {
            individual: 'Assign to individual(s)',
            team: 'Assign to a team',
          },
        },
        qualifications: {
          label: 'Positions',
          placeholder: 'Positions',
        },
        assignees: {
          label: 'Assignees',
          placeholder: 'Assignees',
        },
      },
      dateAndDuration: {
        sectTitle: 'Date and duration',
        startDate: {
          label: 'Start date',
        },
        shiftTypePreset: {
          label: 'Shift time preset',
          placeholder: 'Shift time preset',
        },
        startTime: {
          label: 'Start time',
          placeholder: 'Shift start time',
        },
        endTime: {
          label: 'End time',
          placeholder: 'Shift end time',
        },
        duration: {
          sectTitle: 'Duration',
          empty: 'start and end time not selected',
        },
        timePreset: {
          sectTitle: 'Time preset',
          checkboxLabel: 'Save time preset',
        },
      },
      location: {
        sectTitle: 'Location',
        locationName: {
          label: 'Location name',
          placeholder: 'Location name',
        },
        department: {
          label: 'Department',
          placeholder: 'Department',
        },
        subDepartment: {
          label: 'Sub-department',
          placeholder: 'Sub-department',
          emptyPlaceholder: 'No sub-department available',
          emptyToolTip: 'Chosen department has no sub-departments',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
        specialty: {
          label: 'Specialty',
          placeholder: 'Specialty',
          emptyPlaceholder: 'No specialties available',
          emptyToolTip: 'Chosen department and sub-department has no specialties',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
      },
      additionalInfo: {
        sectTitle: 'Additional info',
        tag: {
          label: 'Tag',
          placeholder: 'Tag',
        },
        notes: {
          sectTitle: 'Notes',
        },
      },
    },
    editShiftDrawer: {
      dialogHeading: 'Edit single shift',
      internalIDPlaceholder: 'ID: {shiftId}',

      menuButtons: {
        back: {
          label: 'Back',
        },
        publish: {
          label: 'Publish',
        },
        saveDraft: {
          label: 'Save draft',
        },
        delete: {
          label: 'Delete',
        },
        copy: {
          label: 'Copy',
        },
        cancel: {
          label: 'Cancel',
        },
        save: {
          label: 'Save',
        },
        share: {
          label: 'Share',
        },
      },

      shiftConfiguration: {
        sectTitle: 'Shift configuration',
        shiftTemplate: {
          label: 'Shift template',
          placeholder: 'Shift template',
        },
        quantity: {
          label: 'Quantity',
        },
        markAsUrgent: {
          label: 'Mark as urgent',
        },
      },
      talentConfiguration: {
        sectTitle: 'Provider',
        talentType: {
          label: 'Provider type',
          items: {
            all: 'Any',
            internal: 'Internal',
            external: 'External',
          },
        },
        assignType: {
          label: 'Assign type',
          items: {
            individual: 'Assign to individual(s)',
            team: 'Assign to a team',
          },
        },
        qualifications: {
          label: 'Positions',
          placeholder: 'Positions',
        },
        assignees: {
          label: 'Assignees',
          placeholder: 'Assignees',
        },
      },
      dateAndDuration: {
        sectTitle: 'Date and duration',
        startDate: {
          label: 'Start date',
        },
        shiftTypePreset: {
          label: 'Shift time preset',
          placeholder: 'Shift time preset',
        },
        startTime: {
          label: 'Start time',
          placeholder: 'Shift start time',
        },
        endTime: {
          label: 'End time',
          placeholder: 'Shift end time',
        },
        duration: {
          sectTitle: 'Duration',
          empty: 'start and end time not selected',
        },
        timePreset: {
          sectTitle: 'Time preset',
          checkboxLabel: 'Save time preset',
        },
      },
      location: {
        sectTitle: 'Location',
        locationName: {
          label: 'Location name',
          placeholder: 'Location name',
        },
        department: {
          label: 'Department',
          placeholder: 'Department',
          labelTooltip:
            'Select the department from those mapped to your location. Note that if you edit department, sub-department and specialty values will be reset',
        },
        subDepartment: {
          label: 'Sub-department',
          placeholder: 'Sub-department',
          emptyPlaceholder: 'No sub-department available',
          emptyToolTip: 'Chosen department has no sub-departments',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
        specialty: {
          label: 'Specialty',
          placeholder: 'Specialty',
          emptyPlaceholder: 'No specialties available',
          emptyToolTip: 'Chosen department and sub-department has no specialties',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
      },
      additionalInfo: {
        sectTitle: 'Additional info',
        tag: {
          label: 'Tag',
          placeholder: 'Tag',
        },
        notes: {
          sectTitle: 'Notes',
        },
      },
      disabledQualificationTooltip:
        'To remove this position, unassign all providers currently associated with it first',
      errorNumberOfAssignees: 'Assignees count should not exceed number of copies',
      error: {
        OverlapDetected:
          'This provider has another shift scheduled for this date. The changes won’t be applied. In order to change start date or time unassign the scheduled shift first',
      },
    },
    addSeriesDrawer: {
      dialogHeading: 'New series',

      menuButtons: {
        back: {
          label: 'Back',
        },
        publish: {
          label: 'Publish',
        },
      },

      errors: {
        OverlapDetected: 'You have specified an overlapped schedule for the series.',
        EmptySchedule: 'You have not specified any schedule for the series.',
        InvalidScheduleValues: 'One or more schedule items are empty or invalid',
      },

      shiftConfiguration: {
        sectTitle: 'Shift configuration',
        shiftTemplate: {
          label: 'Shift template',
          placeholder: 'Shift template',
        },
        quantity: {
          label: 'Quantity',
        },
        markAsUrgent: {
          label: 'Mark as urgent',
        },
      },

      dateAndDuration: {
        sectTitle: 'Date and duration',
        startDate: {
          label: 'Start date',
        },
        endDate: {
          label: 'End date',
          endDateLessError: 'Shift end date should be greater than start',
        },
        durationType: {
          label: 'Duration type',
          items: {
            setEndDate: 'Set end date',
            infiniteLoop: 'Infinite loop',
          },
        },
      },
      seriesDetails: {
        sectTitle: 'Series details',
        seriesPreset: {
          label: 'Series preset',
          placeholder: 'Series preset',
        },
        shiftTypePreset: {
          label: 'Shift type preset',
          placeholder: 'Shift type preset',
        },
        startTime: {
          label: 'Shift start time',
        },
        endTime: {
          label: 'Shift end time',
        },
      },
      seriesSchedule: {
        sectTitle: 'Series schedule',
        sectBadges: {
          spendTime: '{amount} / week',
        },
        daySchedule: {},
        preset: {
          sectTitle: '', // to be implemented
          seriesPresetCheckbox: {
            label: 'Save series preset',
          },
          presetName: {
            label: 'Preset name',
            placeholder: 'Enter preset name',
          },
        },
      },
      location: {
        sectTitle: 'Location details',
        locationName: {
          label: 'Location',
          placeholder: 'Location',
        },
        department: {
          label: 'Department',
          placeholder: 'Department',
          labelTooltip:
            'Select the department from those mapped to your location. Note that if you edit department, sub-department and specialty values will be reset',
        },
        subDepartment: {
          label: 'Sub-department',
          placeholder: 'Sub-department',
          emptyPlaceholder: 'No sub-department available',
          emptyToolTip: 'Chosen department has no sub-departments',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
        specialty: {
          label: 'Specialty',
          placeholder: 'Specialty',
          emptyPlaceholder: 'No specialties available',
          emptyToolTip: 'Chosen department and sub-department has no specialties',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
      },
      talentConfiguration: {
        sectTitle: 'Provider',
        talentType: {
          label: 'Provider type',
          items: {
            all: 'Any',
            internal: 'Internal',
            external: 'External',
          },
        },
        assignType: {
          label: 'Assign type',
          items: {
            individual: 'Assign to individual(s)',
            team: 'Assign to a team',
          },
        },
        qualifications: {
          label: 'Positions',
          placeholder: 'Positions',
        },
        assignees: {
          label: 'Assignees',
          placeholder: 'Assignees',
        },
      },
      additionalInfo: {
        sectTitle: 'Additional info',
        tag: {
          label: 'Tag',
          placeholder: 'Tag',
        },
        notes: {
          sectTitle: 'Notes',
        },
      },
    },
    editSeriesDrawer: {
      dialogHeading: 'Edit Series',
      // not yet implemented
    },
    addRotationDrawer: {
      dialogHeading: 'New Rotation',
      menuButtons: {
        back: {
          label: 'Back',
        },
        publish: {
          label: 'Publish',
        },
      },

      shiftConfiguration: {
        sectTitle: 'Shift configuration',
        shiftTemplate: {
          label: 'Shift template',
          placeholder: 'Shift template',
        },
        quantity: {
          label: 'Quantity',
        },
        markAsUrgent: {
          label: 'Mark as urgent',
        },
      },

      dateAndDuration: {
        sectTitle: 'Date and duration',
        startDate: {
          label: 'Start date',
        },
        endDate: {
          label: 'End date',
        },
        durationType: {
          label: 'Duration type',
          items: {
            setEndDate: 'Set end date',
            infiniteLoop: 'Infinite loop',
          },
        },
      },
      rotationDetails: {
        sectTitle: 'Rotation details',
        seriesPreset: {
          label: 'Rotation preset',
          placeholder: 'Rotation preset',
        },
        shiftTypePreset: {
          label: 'Shift type preset',
          placeholder: 'Shift type preset',
        },
        startTime: {
          label: 'Shift start time',
        },
        endTime: {
          label: 'Shift end time',
        },
      },
      rotationSequence: {
        sectTitle: 'Rotation sequence',
        sectBadges: {
          noSequence: 'no sequence set',
        },
        workingDayToggle: {
          label: 'Working day',
        },
      },
      rotationSchedulePreview: {
        sectTitle: 'Rotation schedule preview',
        sectBadges: {
          spendTime: '{amount} days',
        },
        tabs: {
          calendarView: {
            label: 'Calendar view',
          },
          weeklyView: {
            label: 'Weekly view',
          },
        },
      },
      location: {
        sectTitle: 'Location details',
        locationName: {
          label: 'Location',
          placeholder: 'Location',
        },
        department: {
          label: 'Department',
          placeholder: 'Department',
          labelTooltip:
            'Select the department from those mapped to your location. Note that if you edit department, sub-department and specialty values will be reset',
        },
        subDepartment: {
          label: 'Sub-department',
          placeholder: 'Sub-department',
          emptyPlaceholder: 'No sub-department available',
          emptyToolTip: 'Chosen department has no sub-departments',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
        specialty: {
          label: 'Specialty',
          placeholder: 'Specialty',
          emptyPlaceholder: 'No specialties available',
          emptyToolTip: 'Chosen department and sub-department has no specialties',
          departmentIsNotSelectedTooltip: 'Please select department first',
        },
      },
      talentConfiguration: {
        sectTitle: 'Provider',
        talentType: {
          label: 'Provider type',
          items: {
            all: 'Any',
            internal: 'Internal',
            external: 'External',
          },
        },
        assignType: {
          label: 'Assign type',
          items: {
            individual: 'Assign to individual(s)',
            team: 'Assign to a team',
          },
        },
        qualifications: {
          label: 'Positions',
          placeholder: 'Positions',
        },
        assignees: {
          label: 'Assignees',
          placeholder: 'Assignees',
        },
      },
      additionalInfo: {
        sectTitle: 'Additional info',
        tag: {
          label: 'Tag',
          placeholder: 'Tag',
        },
        notes: {
          sectTitle: 'Notes',
        },
      },
    },
    editRotationDrawer: {
      dialogHeading: 'Edit Rotation',
    },
    shiftInProgressDrawer: {
      dialogHeading: 'Edit Rotation',
    },
    shiftsShareDrawer: {
      dialogHeading: 'Share shifts',
      menuButtons: {
        proceed: {
          label: 'Proceed',
        },
        share: {
          label: 'Share',
        },
        back: {
          label: 'Back',
        },
      },
      shiftsPickPage: {
        shiftsSelectionCounter: '{amount} shifts selected',
      },
      agencyPickPage: {
        shiftsSelectionCounter: '{amount} shifts selected',
        agenciesSelectionCounter: '{amount} agencies selected',
      },
    },
    shiftsPublishDrawer: {
      dialogHeading: 'Publish shifts',
      menuButtons: {
        publish: {
          label: 'Publish',
        },
        back: {
          label: 'Back',
        },
      },
      shiftsPickPage: {
        shiftsSelectionCounter: '{amount} shifts selected',
      },
      agencyPickPage: {
        shiftsSelectionCounter: '{amount} shifts selected',
        agenciesSelectionCounter: '{amount} agencies selected',
      },
    },
    durationHoursLong: '{duration} hours',
    startType: {
      day: 'Day',
      eve: 'Evening',
      noc: 'Night',
    },
    unassignedShiftsDrawer: {
      header: 'Unassigned shifts',
    },
    published: 'Published',
  },
  billing: {
    bonusRate: 'Bonus rate',
    rate: {
      perHour: 'Per hour',
      perShift: 'Per shift',
    },
    rates: 'Rates',
    totals: 'Totals',
    billRate: 'Bill rate',
    payRate: 'Pay rate',
    bonusAmount: 'Bonus amount',
    billAmount: 'Bill amount',
    payAmount: 'Pay amount',
    grossMargin: 'Gross margin',
    totalTime: 'Total time',
  },
  filters: {
    dateRange: {
      previousQuarter: 'Previous quarter',
      previousWeek: 'Previous week',
      today: 'Today',
      thisWeek: 'This week',
      thisQuarter: 'This quarter',
      nextWeek: 'Next week',
      nextQuarter: 'Next quarter',
    },
    sharedWithin: {
      menuLabel: 'Shared within',
      last24Hours: 'Last 24 hours',
      last48Hours: 'Last 48 hours',
      allTime: 'All time',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
    },
    applyButtonLabel: 'Apply filters',
    departments: {
      label: 'Departments',
    },
    statuses: {
      label: 'Status',
    },
  },
  talents: {
    labelOne: 'Provider',
    addButtonLabel: 'Add provider',
    addMenu: {
      addSingle: 'Individual provider',
      csvImport: 'CSV import',
    },
    qualifications: 'Positions',
    lastWorked: 'Last worked',
    statuses: {
      active: 'Active',
      deactivated: 'Deactivated',
      deleted: 'Deleted',
      invitefailed: 'Invite failed',
      invited: 'Invited',
      pending: 'Pending',
    },
    qualification: 'Position',
    editForm: {
      header: 'Add internal provider',
      editHeader: 'Edit internal provider',
      personalInformation: 'Personal Information',
      profileImageTitle: 'Upload profile image',
      profileImageDisclaimer: 'Image should be PNG or JPG format, 2MB max.',
      profileImageSubmitTitle: 'Upload image',
      contactInformation: 'Contact Information',
      hrInformation: 'HR Information',
      primaryRegion: 'Primary region',
      secondaryRegion: 'Secondary region',
      roleAndPosition: 'Location and job title',
      qualificationAndSpecialties: 'Position and specialties',
      specialtiesApplied: 'Specialties applied',
      specialties: 'Specialties',
      rateType: 'Rate type',
      baseRate: 'Base rate',
      deleteQualification: 'Delete position',
      addQualification: 'Add position',
      deleteSpecialty: 'Delete specialty',
      addSpecialty: 'Add specialty',
      travel: 'Travel',
      availableForTravel: 'Available for travel',
      schedule: 'Schedule',
      success: 'Provider has been created successfully',
      successEdit: 'Provider has been edited successfully',
      maxTravelDistance: 'Max. travel distance (in miles)',
      stepGeneral: 'General',
      stepRoleAndPay: 'Role & Pay rate',
      stepAvailability: 'Availability',
      minShiftsValidationError: 'At least one shift is required',
      errors: {
        DuplicateEmail: 'User with this email already exists',
      },
    },
    rateTypes: {
      hourly: 'Hourly',
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
    table: {
      noRows: {
        title: 'No providers yet',
        subtitle: 'You don’t have any providers yet',
      },
    },
    deactivateUser: 'Deactivate',
    deactivateUsers: 'Deactivate',
    activateUser: 'Activate',
    activateUsers: 'Activate',
    talentsHaveActiveShiftsError: `Failed to deactivate {count, plural, one {provider {items}} other {# providers: {items}}}. Reason: providers have shifts assigned. Please reassign their shifts before retrying`,
    talentHasActiveShiftsError:
      'You cannot deactivate provider with scheduled shifts. To proceed, reassign their shifts to another provider before attempting deactivation',
    sendInvites: 'Send invite(s)',
    resendInvites: 'Resend invite(s)',
    staffingType: {
      label: 'Staffing type',
      internal: 'Internal',
      agency: 'Agency',
      directContractor: 'Direct contractor',
    },
    employmentType: {
      label: 'Employment type',
      Contract: 'Contract',
      SalariedPartTime: 'Salaried Part Time',
      SalariedFullTime: 'Salaried Full Time',
      HourlyPartTime: 'Hourly Part Time',
      HourlyFullTime: 'Hourly Full Time',
      PerDiemOrPRN: 'Per Diem or PRN',
    },
    fields: {
      specialities: 'Specialities',
      nurseQualifications: 'Positions',
      staffingType: 'Staffing type',
    },
    deactivateSuccess: '{count, plural, one {Provider has} other {# providers have}} been deactivated successfully',
    activateSuccess: '{count, plural, one {Provider has} other {# providers have}} been activated successfully',
    specialty: 'Specialty',
    selector: {
      stuffingType: {
        // Currently we separate talents to two groups: Internal and External
        Internal: 'Internal providers',
        Agency: 'External providers',
        DirectContractor: 'External providers',
      },
    },
  },
  locations: {
    status: {
      Active: {
        tableChip: {
          label: ' Active',
        },
      },
      Deactivated: {
        tableChip: {
          label: 'Deactivated',
        },
      },
    },
    structure: {
      minSelectedError: "You have to select at least one department overwise you won't be able to create the shifts",
    },
  },
  reports: {
    title: 'Reporting',
  },
  staffingPartners: {
    menu: {
      addButton: {
        label: 'Invite agency',
      },
      toMarket: {
        label: 'Go to marketplace',
      },
    },
    filters: {
      regions: {
        label: 'Regions',
      },
      relation: {
        label: 'Relation',
      },
      status: {
        label: 'Status',
      },
    },
    statuses: {
      Pending: 'Pending',
      Disconnected: 'Disconnected',
      Active: 'Active',
      OnReview: 'OnReview',
      Invited: 'Invited',
    },
    relations: {
      Direct: 'Direct',
      Managed: 'Managed',
    },
    table: {
      noRows: {
        title: 'No staffing partners yet',
        subtitle: 'You don’t have any connected agencies yet',
      },
      fields: {
        agencyName: {
          heading: 'Agency name',
        },
        mainContact: {
          heading: 'Contact person',
        },
        region: {
          heading: 'Region',
        },
        phone: {
          heading: 'Phone',
        },
        email: {
          heading: 'Email',
        },
        status: {
          heading: 'Invite status',
        },
      },
    },
    drawers: {
      inviteAgencyDrawer: {
        menuButtons: {
          back: {
            label: 'Close',
          },
          cancel: {
            label: 'Cancel',
          },
          connect: {
            label: 'Connect',
          },
          invite: {
            label: 'Invite',
          },
          addMore: {
            label: 'Add another partner',
          },
        },
        invitation: {
          connect: {
            dialogHeading: 'Add new staffing partner',
            title: 'Connect',
            subTitle:
              'Connect to staffing partners within the ShiftNex platform. Also you can invite a new staffing partner to use the ShiftNex platform if they don’t have an account yet. Select the locations where the staffing partner can fill the shifts.',
            agencyBlockHeading: 'Agency',
            agency: {
              label: 'Agency Name',
              placeholder: 'Agency Name',
            },
            locationSelect: {
              label: 'Locations',
              placeholder: 'Locations',
            },
          },
          invite: {
            dialogHeading: 'Add new staffing partner',
            title: 'Invite',
            subTitle:
              'Invite a staffing partner to use the ShiftNex platform. Select the locations where the staffing partner can fill the shifts.',
            agencyBlockHeading: 'Agency',
            agencyTextField: {
              label: 'Agency name',
              placeholder: 'Enter agency name',
            },
            locationSelect: {
              label: 'Locations',
              placeholder: 'Locations',
            },
            agencyContactBlockHeading: "Agency's contact person details",
            contactFirstName: {
              label: 'Contact person first name',
              placeholder: 'Contact person first name',
            },
            contactLastName: {
              label: 'Contact person last name',
              placeholder: 'Contact person last name',
            },
            contactEmail: {
              label: 'Contact person email',
              placeholder: 'Contact person email',
            },
            contactPhone: {
              label: 'Contact person phone',
              placeholder: 'Contact person phone',
            },
          },
        },
        success: {
          connect: {
            dialogHeading: 'Connection Successful!',
            messageTitle: 'You’ve successfully connected with {agencyName}.',
            subTitle: 'You’re all set! Share your open shifts, and {agencyName} will start filling them right away.',
          },
          invite: {
            dialogHeading: 'Invitation Sent Successfully!',
            messageTitle: 'We’ve sent your invite to {agencyName}.',
            subTitle:
              'We’ll verify your partner within the next 24 hours. You’ll receive an email notification when {agencyName} is approved and ready to join the platform. Once they log in for the first time, you’ll get another email update so you can start sharing your staffing needs immediately. Stay tuned!',
          },
          supportChat: {
            heading: 'Support chat',
            headingSubtitle: 'Need help? Contact our 24/7 support',
          },
        },
      },
    },
  },
});

import { useState, PropsWithChildren } from 'react';
import { useShiftAddEditStore, useShiftsAssignment } from '@libs/store/shifts';
import { useNotification } from '@libs/snackbar';
import { isServerError } from '@libs/utils/error';
import { parseError } from '@libs/api/errors';
import { CancelDialog } from '@organisms/shifts/cancelDialog';
import { LoadingButton } from '@atoms/buttons';

type CancelShiftButtonProps = {
  shiftId: string;
  disabled?: boolean;
  onReasonDialogClose?: () => void;
};
export function CancelShiftButton({
  shiftId,
  disabled,
  children,
  onReasonDialogClose,
}: PropsWithChildren<CancelShiftButtonProps>) {
  const { showSuccessIntl, showError, showErrorIntl } = useNotification();
  const { cancelShift } = useShiftsAssignment();
  const { load } = useShiftAddEditStore();
  const [loading, setLoading] = useState(false);
  const onCancelClick = () => {
    setOpedDialog(true);
  };

  const [opedDialog, setOpedDialog] = useState(false);
  const handleCancel = async (reason: string, reasonComment: string) => {
    if (loading) return;

    setLoading(true);
    try {
      await cancelShift(shiftId, reason === 'custom' ? reasonComment : reason);
      await load(shiftId);

      showSuccessIntl('shifts.cancelDialog.cancelSuccessful');
      setOpedDialog(false);
      onReasonDialogClose && onReasonDialogClose();
    } catch (error) {
      if (isServerError(error) && !error.response?.data.errors) {
        showErrorIntl(`shifts.assignError.${error.response?.data.title}`);
        return;
      }
      showError(parseError(error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingButton variant="outlined" color={'error'} onClick={onCancelClick} disabled={disabled} loading={loading}>
        {children}
      </LoadingButton>

      <CancelDialog open={opedDialog} onClose={() => setOpedDialog(false)} onSubmit={handleCancel} loading={loading} />
    </>
  );
}

import { create } from 'zustand';
import { createShiftSeries } from '@libs/api/shifts';
import { ShiftSeries, ShiftSeriesUpdate } from '@libs/models/shifts/series';

type State = {
  loading: boolean;
  drawerOpen: boolean;
  seriesId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  data: ShiftSeries | null;
  load: (id: string) => void;
  openShiftSeriesDrawer: (id?: string) => void;
  closeShiftSeriesDrawer: () => void;
  createSeries: (shift: ShiftSeriesUpdate) => Promise<void>;
};

export const useShiftSeriesAddEditStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  seriesId: '',
  data: null,
  load: async (id) => {
    set({ loading: true });
    try {
      // const resp = await fetchShiftsList(page, perPage, org);
      set({
        loading: false,
        // data: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftSeriesDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', seriesId: id, drawerOpen: true });
  },
  closeShiftSeriesDrawer: () => {
    set({ drawerOpen: false });
  },
  createSeries: async (series) => {
    await createShiftSeries({
      ...series,
    });
  },
}));

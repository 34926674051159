import { create } from 'zustand';
import { createOrgLocation, getOrgLocation, getOrgLocations, updateOrgLocation } from '@libs/api/settings';
import { LocationExtra, LocationList } from '@libs/models/locations';

type InitialState = {
  locationsLoading: boolean;
  orgLocations: LocationList[];
  page: number;
  total: number;
  perPage: number;

  editLocationLoading: boolean;
  editLocationDetails: LocationExtra | null;

  loadLocationsList: (page?: number, perPage?: number) => void;
  loadLocation: (locationId: string) => void;
  createLocation: (location: LocationExtra) => Promise<void>;
  updateLocation: (id: string, location: LocationExtra) => Promise<void>;
  flushEdit: () => void;
};

export const useOrgLocationsStore = create<InitialState>()((set, get) => ({
  locationsLoading: false,
  predefinedSpecialtiesLoading: false,
  orgLocations: [],
  page: 0,
  perPage: 10,
  total: 0,

  editLocationLoading: false,
  editLocationDetails: null,

  loadLocationsList: async (page = 0, perPage = 10) => {
    set({ locationsLoading: true });
    try {
      const resp = await getOrgLocations();
      set({
        orgLocations: resp.data,
        total: resp.total,
        page,
        perPage,
      });
    } finally {
      set({ locationsLoading: false });
    }
  },
  loadLocation: async (locationId: string) => {
    set({ editLocationLoading: true });
    try {
      const resp = await getOrgLocation(locationId);
      set({
        editLocationDetails: resp,
      });
    } finally {
      set({ editLocationLoading: false });
    }
  },
  flushEdit: () => {
    set({
      editLocationLoading: false,
      editLocationDetails: null,
    });
  },
  createLocation: async (location: LocationExtra) => {
    // set({ editLocationLoading: true });
    try {
      const resp = await createOrgLocation(location);
    } finally {
      // set({ editLocationLoading: false });
    }
  },
  updateLocation: async (id: string, location: LocationExtra) => {
    // set({ editLocationLoading: true });
    try {
      const resp = await updateOrgLocation(id, location);
    } finally {
      // set({ editLocationLoading: false });
    }
  },
}));

import { create } from 'zustand';

type State = {
  lastUpdate: Date;
  refreshTrigger: string;
  callRefresh: () => void;
};

export const useRefreshSyncStore = create<State>()((set, get) => ({
  lastUpdate: new Date(),
  refreshTrigger: '',

  callRefresh: async () => {
    set({ lastUpdate: new Date(), refreshTrigger: new Date().toISOString() });
  },
}));

import { create } from 'zustand';
import dayjs from 'dayjs';
import { DateRangeShortcutsItem } from '@libs/models/common';
import { dateRangeShortcuts } from '@libs/models/shifts';
import { createDefaultFilterSlice, DefaultFilterState } from '../defaultFilterSlice';
import { persist } from 'zustand/middleware';
import { getTalents } from '@libs/api/talents';
import { TalentListItem } from '@libs/models/talents';

type ShiftFilterState = {
  dateRangeShortcuts: DateRangeShortcutsItem[];
  loading: boolean;
  filterProviders: TalentListItem[];
  loadData: (options: { locationId: string }) => Promise<void>;
};
export const useShiftsFilter = create<DefaultFilterState & ShiftFilterState>()(
  persist(
    (set, get, store) => ({
      ...createDefaultFilterSlice(set, get, store),
      dateRangeShortcuts: dateRangeShortcuts,
      loading: false,
      filterProviders: [],

      loadData: async ({ locationId }) => {
        set({
          loading: true,
        });
        try {
          const { data } = await getTalents({
            page: 1,
            size: 50,
            filters: [
              {
                field: 'locations',
                value: [locationId],
              },
            ],
          });
          set({
            filterProviders: data.items.map((t) => ({
              ...t,
              lastWorkedTime: dayjs(t.lastWorkedTime),
            })),
            loading: false,
          });
        } catch (error) {
          set({
            loading: false,
          });
          throw error;
        }
      },
    }),
    {
      name: 'shiftsList',
    },
  ),
);

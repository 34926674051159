import { styled } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { paperClasses } from '@mui/material/Paper';

export type PrimaryDialogProps = DialogProps;
export function PrimaryDialog({ sx, ...props }: DialogProps) {
  return <DialogStyled {...props} />;
}

const DialogStyled = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
  },
}));

import { create } from 'zustand';
import { login, resendOtp, submitOtp, getUserInfo, getFreshToken } from '@libs/api/auth';
import { updateToken } from '@libs/api/request';
import { parseError } from '@libs/api/errors';
import { SystemUser } from '@libs/models/common';
import { Analytics } from '@libs/analytics';
import { parseJwt } from '@libs/utils/jwt';

type State = {
  loading: boolean;
  userData: SystemUser | null;
  userDataLoading: boolean;
  token: string | null;
  refreshToken: string | null;
  refreshTimer: null | NodeJS.Timeout;

  fetchUserData: () => Promise<void>;
  login: (email: string) => Promise<void>;
  flushToken: () => void;
  setToken: (token: string, refreshToken: string) => void;
  submitOtp: (email: string, otp: string) => Promise<void>;
  resendOtp: (email: string) => Promise<void>;
  isAuthenticated: () => boolean;
  initRefreshTokenProcedure: () => void;
};

export const useAuthStore = create<State>()((set, get) => ({
  loading: false,
  userDataLoading: false,
  userData: null,
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  refreshTimer: null,

  fetchUserData: async () => {
    set({
      userDataLoading: true,
    });
    const userData = await getUserInfo();
    set({
      userData,
      userDataLoading: false,
    });
  },
  login: async (email) => {
    set({
      loading: true,
    });
    try {
      await login(email);
      Analytics.track('Login: Fill Form', {source: 'email'});
    } finally {
      set({
        loading: false,
      });
    }
  },

  flushToken: () => {
    const { refreshTimer } = get();
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    updateToken(null);
    set({ token: null, refreshToken: null });
  },

  setToken: (token, refreshToken) => {
    const { initRefreshTokenProcedure } = get();
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    updateToken(token);
    set({ token, refreshToken });
    initRefreshTokenProcedure();
  },

  submitOtp: async (email, otp) => {
    set({
      loading: true,
    });
    const { setToken } = get();
    try {
      const res = await submitOtp(email, otp);
      const { token, refreshToken } = res.data;
      setToken(token, refreshToken);
      Analytics.track('Login: Otp Entered', {value: 'success'});
    } catch (error) {
      Analytics.track('Login: Otp Entered', {value: 'fail'}) ;
      throw parseError(error);
    } finally {
      set({
        loading: false,
      });
    }
  },

  resendOtp: async (email) => {
    set({
      loading: true,
    });
    try {
      await resendOtp(email);
      Analytics.track('Login: Resend Otp', {value: 'success'}) ;
    } catch (error) {
      Analytics.track('Login: Resend Otp', {value: 'fail'}) ;
      throw parseError(error);
    } finally {
      set({
        loading: false,
      });
    }
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('token');
  },

  initRefreshTokenProcedure: () => {
    checkForRefresh();
    async function checkForRefresh() {
      const { token, setToken, refreshTimer, refreshToken } = get();
      if (!token || !refreshToken) {
        return;
      }
      const data = parseJwt<{ exp: number }>(token);
      const now = Date.now() / 1000;
      // 5 min before token expires
      const refreshTime = data.exp - 60 * 5;

      if (now >= refreshTime) {
        const response = await getFreshToken(refreshToken);
        setToken(response.data.token, response.data.refreshToken);
        checkForRefresh();
        return;
      }

      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
      set({
        // 1 ms after refreshTime
        refreshTimer: setTimeout(checkForRefresh, (refreshTime - now) * 1000 + 1),
      });
    }
  },
}));

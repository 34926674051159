import { Dayjs } from 'dayjs';

export type Filter = StringFilter | NumberFilter | DateRangeFilter | StringArrayFilter;
export type StringFilter = FilterGeneral<string>;
export type StringArrayFilter = FilterGeneral<string[]>;
export type NumberFilter = FilterGeneral<number>;
export type DateRangeFilter = FilterGeneral<{ from?: Dayjs; to?: Dayjs; timezone: string }>;
export type FilterGeneral<T> = {
  key: string;
  value: T;
  // Value prepared to be rendered on the UI (mostly for the filters Chips)
  valueView?: string;
};

export function isDateRangeFilter(filter: Filter): filter is DateRangeFilter {
  return typeof filter.value === 'object' && 'from' in filter.value;
}

import { PropsWithChildren } from 'react';
import { styled, useTheme } from '@mui/material';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

export interface ContainedIconButtonProps extends IconButtonProps {}

export const ContainedIconButton = ({ children, ...props }: PropsWithChildren<ContainedIconButtonProps>) => {
  const theme = useTheme();

  return (
    <StyledButton
      {...props}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(MuiIconButton, { shouldForwardProp: (prop: string) => !['rounded'].includes(prop) })<{
  rounded?: boolean;
}>(({ theme, rounded }) => ({
  backgroundColor: theme.palette.common.white,
  aspectRatio: '1 / 1',
}));

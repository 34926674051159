import { useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { FormControlLabel, Grid, IconButton, Stack, Switch, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { TimePicker } from '@atoms/inputs';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';
import { ShiftsCalendar } from './shiftsCalendar';

type RotationScheduleSectionProps = {
  localizationSubset: string;
  onChange: (
    sequence: {
      id: string;
      start: Dayjs;
      end: Dayjs;
    }[],
  ) => void;
  slots: {
    id: string;
    start: Dayjs;
    end: Dayjs;
  }[];
  defaultTimeSlot?: {
    start?: Dayjs | null;
    end?: Dayjs | null;
  };
};

export function RotationScheduleSection({
  localizationSubset,
  onChange,
  slots,
  defaultTimeSlot,
}: RotationScheduleSectionProps) {
  const intl = useIntl();
  const [tabNumber, setTab] = useState('1');
  const [selectedDay, setSelectedDay] = useState<Dayjs>();
  const selectedSlots = slots.filter((s) => s.start.isSame(selectedDay, 'day'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabNumber}>
        <Box sx={{ width: '100%' }}>
          <TabList onChange={handleTabChange} sx={{ width: '100%' }}>
            <Tab
              label={intl.formatMessage({
                id: `${localizationSubset}.rotationSchedulePreview.tabs.calendarView.label`,
              })}
              value="1"
              sx={{ width: '50%' }}
            />
            <Tab
              label={intl.formatMessage({
                id: `${localizationSubset}.rotationSchedulePreview.tabs.weeklyView.label`,
              })}
              value="2"
              sx={{ width: '50%' }}
              disabled
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={6}>
              <ShiftsCalendar slots={slots} onDaySelect={setSelectedDay} />
            </Grid>
            <Grid item xs={6}>
              {selectedDay && (
                <Flex column>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChange([
                              ...slots,
                              makeSlotFromDefault(selectedDay, defaultTimeSlot?.start, defaultTimeSlot?.end),
                            ]);
                          } else {
                            onChange(slots.filter((s) => !s.start.isSame(selectedDay, 'day')));
                          }
                        }}
                        checked={selectedSlots.length > 0}
                      />
                    }
                    label={
                      <FormattedDate
                        value={selectedDay.toDate()}
                        day="numeric"
                        weekday="long"
                        month="2-digit"
                        year="numeric"
                      />
                    }
                  />
                  <Stack spacing={2}>
                    {selectedSlots.map((s, i) => (
                      <Flex gap={1} key={s.id}>
                        <TimePicker
                          defaultValue={s.start}
                          onAccept={(v) => {
                            if (!v) return;
                            const newSlots = [...slots];
                            const i = newSlots.findIndex((item) => item.id === s.id);
                            newSlots[i] = {
                              start: v,
                              end: newSlots[i].end,
                              id: Math.random().toString(36).slice(2, 9),
                            };
                            onChange(newSlots);
                          }}
                        />
                        <TimePicker
                          defaultValue={s.end}
                          onAccept={(v) => {
                            if (!v) return;
                            const newSlots = [...slots];
                            newSlots[i] = {
                              start: newSlots[i].start,
                              end: v,
                              id: Math.random().toString(36).slice(2, 9),
                            };
                            onChange(newSlots);
                          }}
                        />
                        {i > 0 ? (
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => onChange(slots.filter((item) => item.id !== s.id))}
                          >
                            <FaIcon name="trash-can" />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() =>
                              onChange([
                                ...slots,
                                { start: s.start, end: s.end, id: Math.random().toString(36).slice(2, 9) },
                              ])
                            }
                          >
                            <FaIcon name="plus" />
                          </IconButton>
                        )}
                      </Flex>
                    ))}
                  </Stack>
                </Flex>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          Weekly view here
        </TabPanel>
      </TabContext>
    </Box>
  );
}

function makeSlotFromDefault(date: Dayjs, start?: Dayjs | null, end?: Dayjs | null) {
  const defaultStart = start ?? date.startOf('day');
  const defaultEnd = end ?? date.endOf('day');
  const startOnDate = dayjs(date)
    .set('hour', defaultStart.get('hour'))
    .set('minute', defaultStart.get('minute'))
    .set('second', defaultStart.get('second'));
  const endOnDate = dayjs(date)
    .set('hour', defaultEnd.get('hour'))
    .set('minute', defaultEnd.get('minute'))
    .set('second', defaultEnd.get('second'));

  return {
    start: startOnDate,
    end: endOnDate,
    id: Math.random().toString(36).slice(2, 9),
  };
}

import { Box } from '@atoms/layout';
import { Stack, Typography, Chip, alpha } from '@mui/material';
import { Shift } from '../scheduler/lib/Shift';
import { DateHelper } from '@bryntum/schedulerpro';
import { HotShiftIcon } from './icons/hotIcon';
import { SharedShiftIcon } from './icons/sharedShift';
import dayjs from 'dayjs';
import { getShiftType } from '@libs/models/shifts/shift';
import { useIntl } from 'react-intl';

type CalendarItemProps = {
  data: Shift;
  mode: 'grid' | 'scheduler' | 'holder';
};

const BadgesColorMap: Record<string, string> = {
  day: '#a9cad9',
  eve: '#fdd835',
  noc: '#abb9ff',
};

const StatusesColorMap: Record<string, 'info' | 'warning' | 'success' | 'error' | 'default' | 'primary' | 'secondary'> =
  {
    Opened: 'info',
    Pending: 'warning',
    Assigned: 'success',
    Unassigned: 'warning',
    Reopened: 'info',
    NoShow: 'error',
    InProgress: 'success',
    Completed: 'success',
    Canceled: 'info',
  };

export function CalendarItem({ data, mode = 'grid' }: CalendarItemProps) {
  const intl = useIntl();
  const startTimeLabel = dayjs(new Date(data.actualStartDate)).tz(data.timeZone).format('h a');
  let durationLabel = `${data.duration}h`;

  if (data.duration < 1) {
    durationLabel = `${DateHelper.as('minutes', +new Date(data.endDate) - +new Date(data.startDate))}min`;
  }

  const badge = getShiftType(dayjs(data.actualStartDate));
  const badgeColor = BadgesColorMap[badge];
  const statusChipColor = StatusesColorMap[data.status];

  return (
    <Stack
      direction={'row'}
      shiftnex-id={data.id}
      sx={(theme) => ({
        maxWidth: mode === 'scheduler' ? '150px' : '100%',
        height: mode === 'scheduler' ? '54px' : '60px',
        width: '100%',
        paddingLeft: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Box
        className={`calendar-item-schedule-group-${badge} ${mode === 'holder' && 'calendar-item-holder-badge'}`}
        sx={{
          width: '6px',
          minWidth: mode === 'scheduler' ? '6px' : undefined,
          height: '100%',
          backgroundColor: badgeColor,
          marginRight: '4px',
        }}
      />
      <Stack
        direction={'column'}
        flex={1}
        sx={(theme) => ({
          maxWidth: mode === 'scheduler' ? '144px' : undefined,
          paddingTop: '4px',
          paddingBottom: '4px',
        })}
      >
        <Stack
          direction={'row'}
          sx={(theme) => ({ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.03)}`, paddingBottom: '4px' })}
        >
          <Typography
            sx={(theme) => ({
              fontSize: mode === 'scheduler' ? '12px' : '14px',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: theme.palette.text.primary,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: mode === 'scheduler' ? '50%' : undefined,
            })}
          >
            {(data.nurseQualifications || []).map((qual) => qual.abbreviation).join('/')}
          </Typography>
          <Box flex={1} />
          {mode !== 'holder' && (
            <Chip
              label={intl.formatMessage({
                id: `shifts.status.${data.status.toLowerCase()}`,
              })}
              color={statusChipColor}
              size="small"
            />
          )}
        </Stack>
        <Stack direction={'row'}>
          <Typography
            className={mode === 'holder' ? 'calendar-item-holder-timingText' : ''}
            sx={{ fontSize: mode === 'scheduler' ? '12px' : '14px' }}
          >{`${startTimeLabel}・${durationLabel}`}</Typography>
          <Box flex={1} />
          <Stack direction={'row'} spacing={1} sx={{ marginTop: '2px' }}>
            {data.isUrgent && mode !== 'holder' && (
              <Box sx={{ width: '16px', height: '16px' }}>
                <HotShiftIcon />
              </Box>
            )}
            {data.isShared && mode !== 'holder' && (
              <Box sx={{ width: '16px', height: '16px' }}>
                <SharedShiftIcon />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

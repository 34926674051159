import { StaffingPartnerSimple } from '@libs/models/staffingPartners/staffingPartners';
import { request } from '../request';

export function inviteAgency(agencyId: string, locationIds: string[]) {
  return request.post<string>('/v2/agencies/invite', {
    agencyId,
    locationIds,
  });
}

export function inviteNewAgency(data: StaffingPartnerSimple) {
  return request.post<string>('/v2/agencies/external-invite', data);
}

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer, Flex, PrimaryPaper } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useShiftAddEditStore, useShiftDrawerTalents } from '@libs/store/shifts';
import { Shift, unassignReasons } from '@libs/models/shifts';
import { v4 as uuidV4 } from 'uuid';
import { DateHelper } from '@bryntum/schedulerpro';
import { useForm, useFormContext } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import {
  FormBlock,
  FormCheckbox,
  FormDatePicker,
  FormNumberField,
  FormRadioGroup,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '@molecules/form';
import { useLocationStore } from '@libs/store/locations';
import { getShiftTypeByTimeRange } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { QualificationSelect } from './qualificationSelect';
import { AssigneesSelect } from './assigneesSelect';
import { SingleShiftEditData } from '@libs/api/shifts/create';
import { parseError } from '@libs/api/errors';
import { Analytics } from '@libs/analytics/amplitude';
import { LocationSelectionSection } from './components/locationSection';
import { ShiftStatusChip } from '@atoms/dataDisplay';
import { ShiftFormData } from './shiftFormData';
import { EditShiftControls } from './components/editShiftControls';

type EditShiftDrawerProps = {
  onSaveCall?: () => void;
};

export function EditShiftDrawer({ onSaveCall }: EditShiftDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: shiftLoading,
    data: currentShiftDetails,
    shiftId,
    closeShiftDrawer,
    load,
    drawerMode,
    createShift,
    updateShift,
    clearCurrentShift,
    publishShift,
  } = useShiftAddEditStore();
  const { locations, loading: locationsLoading, currentLocationID, loadSimple } = useLocationStore();
  const {
    load: loadDirectQualifications,
    loading: isDirectListLoading,
    data: directQualData,
  } = useDirectQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = useOrgQualificationsStore();
  const { departmentsTreeLoading } = useOrgDepartmentsStore();
  const { loading: specialtiesLoading } = useOrgSpecialtiesStore();
  const { load: loadProviders, loading: providersLoading, talents } = useShiftDrawerTalents();

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addShiftDrawer' : 'shifts.editShiftDrawer';
  const open = drawerOpen;
  const isOpenStatus = currentShiftDetails?.status === 'Opened';
  const isAssigned = currentShiftDetails?.status === 'Assigned';
  const isUnassigned = currentShiftDetails?.status === 'Unassigned';
  const isPending = currentShiftDetails?.status === 'Pending';
  const isInProgress = currentShiftDetails?.status === 'InProgress';
  const isCanceled = currentShiftDetails?.status === 'Canceled';
  const isCompleted = currentShiftDetails?.status === 'Completed';

  const loading =
    shiftLoading ||
    isOrgQualListLoading ||
    specialtiesLoading ||
    providersLoading ||
    departmentsTreeLoading ||
    isDirectListLoading ||
    locationsLoading;

  useEffect(() => {
    if (!currentLocationID) {
      loadSimple();
    }
  }, [currentLocationID]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    if (shiftId) {
      load(shiftId);
    }
  }, [shiftId]);

  useEffect(() => {
    if (!directQualData || !directQualData.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const initial: Shift = {
    id: currentShiftDetails?.id || '',
    staffingType: 'All',
    internalId:
      currentShiftDetails?.internalId || `ABC-${DateHelper.format(new Date(), 'DDMMYYYY')}-${uuidV4().split('-')[0]}`,
    staffNurseQualificationFilters: currentShiftDetails?.staffNurseQualificationFilters || [],
    staffOtherQualificationFilters: currentShiftDetails?.staffOtherQualificationFilters || [],
    staff: currentShiftDetails?.staff || {
      firstName: '',
      id: '',
      imageURL: '',
      lastName: '',
      nurseQualifications: [],
      specialties: [],
    },
    startDate: dayjs(currentShiftDetails?.startDate || new Date()).toServerDateTimeFormat(),
    endDate: dayjs(currentShiftDetails?.endDate || new Date()).toServerDateTimeFormat(),
    timeZone: currentShiftDetails?.timeZone || '',
    department: currentShiftDetails?.department || {
      id: '',
      label: '',
    },
    subDepartment: currentShiftDetails?.subDepartment || {
      id: '',
      label: '',
    },
    speciality: currentShiftDetails?.speciality || {
      id: '',
      title: '',
      category: '',
    },
    tags: currentShiftDetails?.tags || [],
    note: currentShiftDetails?.note || '',
    isUrgent: !!currentShiftDetails?.isUrgent,
    status: currentShiftDetails?.status || 'Opened',
    location: currentShiftDetails?.location || {
      id: '',
      label: '',
    },
  };

  const methods = useForm<ShiftFormData>({
    defaultValues: {
      staffingType: 'All',
      assignType: 1,
      numberOfCopies: 1,
      qualifications: [],
      department: initial.department.id,
      subDepartment: '',
      assignees: [],
      startDate: null,
      startTime: null,
      endTime: null,
      location: currentLocationID || '',
      isUrgent: false,
      template: '',
      shiftTypePreset: '',
      speciality: '',
      // Default value to simplify validation
      unassignReasonSelect: 'sick',
    },
  });
  const { setValue, setError, watch } = methods;

  const [currentShift, setCurrentShift] = useState<Shift>({
    ...initial,
    ...(currentShiftDetails || {}),
  });

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentShiftDetails,
    });
    if (currentShiftDetails) {
      setValue('startDate', dayjs(new Date(currentShiftDetails.startDate).toISOString()));
      setValue('startTime', dayjs(new Date(currentShiftDetails.startDate).toISOString()));
      setValue('endTime', dayjs(new Date(currentShiftDetails.endDate).toISOString()));
      setValue('location', currentShiftDetails.location.id);
      setValue('department', currentShiftDetails.department?.id || '');
      setValue('subDepartment', currentShiftDetails.subDepartment?.id || '');
      setValue('speciality', currentShiftDetails.speciality?.id || '');
      setValue('isUrgent', !!currentShiftDetails.isUrgent);
      setValue('tags', currentShiftDetails.tags.join(', '));
      setValue('staffingType', currentShiftDetails.staffingType);
      setValue('notes', currentShiftDetails.note);
      setValue(
        'qualifications',
        (currentShiftDetails.staffNurseQualificationFilters || []).map((qal) => qal.id),
      );
      currentShiftDetails.staff && setValue('assignees', [currentShiftDetails.staff.id]);
    }
  }, [currentShiftDetails]);

  const handleClose = () => {
    closeShiftDrawer();
    clearCurrentShift();
    methods.reset();
  };

  const handleSubmit = async (
    isPublish: boolean,
    {
      department,
      qualifications,
      speciality,
      location,
      tags,
      notes,
      startDate: startDay,
      startTime,
      endTime,
      subDepartment,
      unassignReasonSelect,
      unassignReasonNote,
      ...data
    }: ShiftFormData,
  ) => {
    if (!startDay || !startTime || !endTime) {
      throw new Error('Please select start and end time');
    }

    if (data.assignees.length > data.numberOfCopies) {
      setError('assignees', {
        type: 'manual',
        message: intl.formatMessage({ id: 'shifts.editShiftDrawer.errorNumberOfAssignees' }),
      });
      return;
    }
    const dayOfStart = dayjs.utc(startDay.format('YYYY-MM-DDT00:00:00.000+00:00'));

    const startDate = dayjs(dayOfStart)
      .set('hour', startTime.get('hour'))
      .set('minute', startTime.get('minute'))
      .set('second', startTime.get('second'));

    let endDate = dayjs(startDate)
      .set('hour', endTime.get('hour'))
      .set('minute', endTime.get('minute'))
      .set('second', endTime.get('second'));

    if (+startDate.toDate() > +endDate.toDate()) {
      endDate = endDate.add(1, 'day');
    }

    const durationInSeconds = endDate.diff(startDate, 'seconds');

    const locationDetails = locations.find((loc) => loc.id === location);

    const tagsParsed = (tags || '').split(',').map((tag) => tag.trim());

    const sendingData: SingleShiftEditData = {
      ...data,
      startDate: startDate.toServerDateTimeFormat(),
      durationInSeconds,
      timeZone: locationDetails!.timeZone,
      staffOtherQualificationFilterIds: [],
      staffNurseQualificationFilterIds: qualifications,
      departmentNodeId: department,
      subDepartmentNodeId: subDepartment,
      specialityId: speciality,
      locationId: location,
      tags: tagsParsed,
      note: notes || '',
      isNeedToPublish: isPublish,
      unassignReason: unassignReasonSelect !== 'other' ? unassignReasonSelect : unassignReasonNote,
    };

    try {
      if (isAddDialog) {
        await createShift(sendingData);
      } else {
        await updateShift(shiftId, sendingData);
      }
      if (!isAddDialog && isPublish) {
        await publishShift(shiftId);
        Analytics.track('Shift: Published', {
          shift_id: currentShift.internalId,
          provider_id: data.assignees.join(', '),
          provider_qualification: qualifications.join(', '),
          shift_specialty: speciality,
          shift_location: location,
          shift_department: department,
        });
      }

      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        // TODO: remove it and uncomment commented single line after release 17.03.2025
        const messageParts = parseError(error).message.split(',');
        if (messageParts[0] === 'OverlapDetected') {
          if (messageParts.length > 1) {
            // TODO add a params for the message here
            const title = messageParts[0];
            showError(intl.formatMessage({ id: `serverErrors.shifts.${title}.extended` }));
          } else {
            showError(intl.formatMessage({ id: `serverErrors.shifts.${parseError(error).message}.simple` }));
          }
        } else {
          showError(intl.formatMessage({ id: `serverErrors.shifts.${parseError(error).message}` }));
        }
        // showError(intl.formatMessage({ id: `shifts.editShiftDrawer.error.${parseError(error).message}` }));
      }
    }
  };

  const assigneesIds = watch('assignees');
  const startDate = watch('startDate');
  const numberOfCopies = watch('numberOfCopies');
  const assigned = talents.filter((talent) => assigneesIds.includes(talent.id));
  const currentAssigneeId = currentShiftDetails?.staff?.id;
  const showUnassignReasonInput = !isOpenStatus && !!currentAssigneeId && !assigneesIds.includes(currentAssigneeId);
  const locationId = watch('location');

  useEffect(() => {
    const filters = [];
    filters.push({ key: 'locations', value: [locationId] });
    // TODO: use autocomplete
    loadProviders({ size: 50, filters: filters.map((f) => ({ field: f.key, value: f.value })) });
  }, [loadProviders, locationId]);

  useEffect(() => {
    if (startDate && startDate?.toDate() > dayjs('2099-12-31').toDate()) {
      setValue('startDate', dayjs('2099-12-31'));
    }
  }, [startDate]);

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <EditShiftControls
          loading={loading}
          onClose={handleClose}
          onUpdated={onSaveCall}
          shiftInternalId={currentShiftDetails?.internalId}
          shiftStatus={currentShiftDetails?.status}
          formMethods={methods}
        />
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
          <Flex gap={1} alignCenter>
            <Typography variant="caption" sx={{ marginTop: '6px' }}>
              <FormattedMessage
                id={`${localizationSubset}.internalIDPlaceholder`}
                values={{
                  shiftId: currentShift.internalId,
                }}
              />
            </Typography>
            {currentShiftDetails && <ShiftStatusChip value={currentShiftDetails.status} />}
            {isInProgress && (
              <Chip
                label={<FormattedMessage id={`shifts.published`} />}
                color="primary"
                size="small"
                variant="outlined"
              />
            )}
          </Flex>
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }} onSubmit={handleSubmit.bind(handleSubmit, false)}>
          {/* Shift configuration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="template"
                options={[]}
              />
              <FormNumberField
                disabled={loading || !isAddDialog}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                minValue={1}
                name="numberOfCopies"
              />
            </Stack>
            <FormCheckbox
              name="isUrgent"
              label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
              disabled={isInProgress || isCanceled || isCompleted || loading}
              size="medium"
            />
          </FormBlock>

          {/* Date and duration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />}>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} spacing={2}>
                <FormDatePicker
                  disabled={
                    loading || isUnassigned || isAssigned || isPending || isInProgress || isCanceled || isCompleted
                  }
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                  fullWidth
                  name="startDate"
                  defaultValue={null}
                  required
                />
                <FormSelect
                  disabled={true}
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.shiftTypePreset.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.dateAndDuration.shiftTypePreset.placeholder`,
                  })}
                  fullWidth
                  name="shiftTypePreset"
                  options={[]}
                />
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <FormTimePicker
                  disabled={loading || isPending || isInProgress || isCanceled || isCompleted}
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startTime.label`} />}
                  fullWidth
                  name="startTime"
                  defaultValue={null}
                  required
                  placeholder={intl.formatMessage({ id: `${localizationSubset}.dateAndDuration.startTime.label` })}
                />
                <FormTimePicker
                  disabled={loading || isPending || isInProgress || isCanceled || isCompleted}
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endTime.label`} />}
                  fullWidth
                  name="endTime"
                  defaultValue={null}
                  required
                  placeholder={intl.formatMessage({ id: `${localizationSubset}.dateAndDuration.endTime.label` })}
                />
              </Stack>
              <Box>
                <Typography variant="subtitle2">
                  <FormattedMessage id={`${localizationSubset}.dateAndDuration.duration.sectTitle`} />
                </Typography>
                <ShiftLength localizationSubset={localizationSubset} />
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  <FormattedMessage id={`${localizationSubset}.dateAndDuration.timePreset.sectTitle`} />
                </Typography>
                <FormCheckbox
                  name="savePreset"
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.timePreset.checkboxLabel`} />}
                  size="medium"
                  disabled
                />
              </Box>
            </Stack>
          </FormBlock>

          {/* Location */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.location.sectTitle`} />}>
            <LocationSelectionSection
              localizationSubset={localizationSubset}
              loading={loading}
              status={currentShiftDetails?.status}
            />
          </FormBlock>

          {/* Talent */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <FormRadioGroup
                  name="staffingType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
                  items={[
                    {
                      label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                      value: 'All',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                      ),
                      value: 'Internal',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                      ),
                      value: 'External',
                    },
                  ]}
                  required
                  disabled={
                    loading || isUnassigned || isAssigned || isPending || isInProgress || isCanceled || isCompleted
                  }
                  row
                />
              </Grid>
              {/* TODO: Hidden so far (moved to future release) */}
              {/* <FormRadioGroup
              name="assignType"
              label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.label`} />}
              items={[
                {
                  label: (
                    <FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.items.individual`} />
                  ),
                  value: 1,
                },
                {
                  label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.items.team`} />,
                  value: 2,
                },
              ]}
              required
              disabled={loading}
              row
            /> */}
              <Grid xs={12}>
                <QualificationSelect
                  fullWidth
                  name="qualifications"
                  required
                  localizationSubset={localizationSubset}
                  disabledOptions={assigned.flatMap((a) => a.nurseQualifications.map((q) => q.id))}
                  disabled={
                    loading || isUnassigned || isAssigned || isPending || isInProgress || isCanceled || isCompleted
                  }
                />
              </Grid>
              <Grid xs={12}>
                <AssigneesSelect
                  fullWidth
                  name="assignees"
                  numberOfCopies={numberOfCopies}
                  localizationSubset={localizationSubset}
                  selectedQualifications={watch('qualifications')}
                  disabled={watch('qualifications').length === 0 || isInProgress || isCanceled || isCompleted}
                />
              </Grid>
              <Collapse in={showUnassignReasonInput} unmountOnExit sx={{ width: '100%' }}>
                <Grid xs={12}>
                  <FormSelect
                    name="unassignReasonSelect"
                    fullWidth
                    label={<FormattedMessage id="shifts.unassignDialog.reasonLabel" />}
                    options={unassignReasons.map((r) => ({
                      key: r.id,
                      label: r.label,
                      value: r.id,
                    }))}
                    required
                  />
                </Grid>
              </Collapse>
              <Collapse
                in={showUnassignReasonInput && watch('unassignReasonSelect') === 'custom'}
                timeout={150}
                unmountOnExit
                sx={{ width: '100%' }}
              >
                <Grid xs={12}>
                  <FormTextField
                    name="unassignReasonNote"
                    label={<FormattedMessage id="shifts.unassignDialog.reasonCommentLabel" />}
                    placeholder={intl.formatMessage({
                      id: 'shifts.unassignDialog.reasonCommentPlaceholder',
                    })}
                    required
                    fullWidth
                  />
                </Grid>
              </Collapse>
            </Grid>
          </FormBlock>

          {/* Additional info */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormTextField
                  disabled={loading || isCanceled || isCompleted}
                  label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                  })}
                  fullWidth
                  name="tags"
                />
              </Grid>
            </Grid>
          </FormBlock>
          <PrimaryPaper sx={{ mt: 1 }}>
            <Accordion sx={{ background: 'transparent' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />
              </AccordionSummary>
              <AccordionDetails>
                <FormTextField
                  name="notes"
                  fullWidth
                  multiline
                  disabled={loading || isCanceled || isCompleted}
                  options={{
                    maxLength: 300,
                  }}
                  InputProps={{
                    sx: { height: '150px', overflow: 'hidden' },
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </PrimaryPaper>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

function getShiftType(startDate: Dayjs | null, startTime: Dayjs | null, endTime: Dayjs | null) {
  if (startDate && startTime && endTime) {
    const dateStart = startDate?.hour(startTime?.hour())?.minute(startTime?.minute());
    const dateEnd = startDate?.hour(endTime?.hour())?.minute(endTime?.minute());
    const isEndOnNextDay = dateStart.toDate() > dateEnd.toDate();
    return getShiftTypeByTimeRange(dateStart, dateEnd.add(isEndOnNextDay ? 1 : 0, 'day'));
  }

  return null;
}

function ShiftLength({ localizationSubset }: { localizationSubset: string }) {
  const { current } = useLocationStore();
  const timezone = current?.timezone;
  const { watch } = useFormContext<ShiftFormData>();
  // Une current location timezone to calculate shift length to prevent issues with DST
  const startDate = dayjs.tz(watch('startDate'), timezone);
  const startTime = watch('startTime');
  const endTime = watch('endTime');
  const shiftType = getShiftType(startDate, startTime, endTime);
  if (shiftType) {
    return (
      <Chip
        label={
          <Flex>
            <FormattedMessage
              id="shifts.durationHoursLong"
              values={{ duration: shiftType.durationHour > 0 ? shiftType.durationHour : 0 }}
            />
            ・
            <FormattedMessage id={`shifts.startType.${shiftType.type}`} />
          </Flex>
        }
        color="primary"
      />
    );
  }

  return <Chip label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.duration.empty`} />} disabled />;
}

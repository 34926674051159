import { create } from 'zustand';
import dayjs from 'dayjs';
import { SHIFT_STATUSES, ShiftList } from '@libs/models/shifts/shift';
import { getAssignments, getEvents } from '@libs/api/shifts';
import { Filter } from '@libs/models/common';
import { getTalents, TalentsResponseItem } from '@libs/api/talents';

type State = {
  loading: boolean;
  initiated: boolean;
  eventsList: ShiftList[];

  calendarDataLoading: boolean;

  assignmentsInitiated: boolean;
  assignmentsLoading: boolean;
  assignmentsList: { staffId: string; shiftId: string }[];

  resourcesInitiated: boolean;
  resourcesListLoading: boolean;
  resourcesList: TalentsResponseItem[];
  flushInitiated: () => void;
  loadCalendarData: (startDate: Date, endDate: Date, timezone: string, filters?: Filter[]) => Promise<void>;
};

export const useCalendarShiftsStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  eventsList: [],

  calendarDataLoading: true,

  assignmentsInitiated: false,
  assignmentsLoading: true,
  assignmentsList: [],

  resourcesInitiated: false,
  resourcesListLoading: true,
  resourcesList: [],

  flushInitiated: () => {
    set({
      initiated: false,
      assignmentsInitiated: false,
      resourcesInitiated: false,
    });
  },
  loadCalendarData: async (startDate: Date, endDate: Date, timezone: string, filters = []) => {
    set({ loading: true, assignmentsLoading: true, calendarDataLoading: true });
    const staffingTypesFilter = filters.find((f) => f.key === 'staffingtypes');
    // We want to see all unassigned shifts when filter by staff is used, so we remove staff filter
    const eventsFilters = filters.filter((f) => f.key !== 'staff');
    // add filter for Deleted status, if it's not already there
    if (!eventsFilters.find((f) => f.key === 'status')) {
      eventsFilters.push({
        key: 'status',
        value: [
          SHIFT_STATUSES.ASSIGNED,
          SHIFT_STATUSES.CANCELED,
          SHIFT_STATUSES.COMPLETED,
          // SHIFT_STATUSES.DELETED,
          SHIFT_STATUSES.EXPIRED,
          SHIFT_STATUSES.IN_PROGRESS,
          SHIFT_STATUSES.OPENED,
          SHIFT_STATUSES.PENDING,
          SHIFT_STATUSES.REASSIGNED,
          SHIFT_STATUSES.UNASSIGNED,
          SHIFT_STATUSES.UNFILLED,
        ],
      });
    }

    // Need to add 'All' staffing type to the filter if filter is not empty
    // Because any staffing type could fill shift with staffing type marked as `All`
    if (staffingTypesFilter) {
      const staffingTypesFilterIndex = eventsFilters.findIndex((f) => f.key === 'staffingtypes');
      eventsFilters.splice(staffingTypesFilterIndex, 1);
      if (Array.isArray(staffingTypesFilter.value) && staffingTypesFilter.value.length > 0) {
        eventsFilters.push({
          key: 'staffingtypes',
          value: ['All', ...staffingTypesFilter.value],
        });
      }
    }

    const talentsFilters = filters
      .filter((f) => ['locations', 'qualifications', 'specialties'].includes(f.key))
      .map((f) => {
        let field = f.key;
        if (field === 'qualifications') {
          field = 'nurseQualifications';
        }
        if (field === 'specialties') {
          field = 'specialities';
        }
        return { field: field, value: f.value };
      });
    talentsFilters.push({
      field: 'staffingStatuses',
      value: ['Active'],
    });
    const filterByStaff = filters.find((f) => f.key === 'staff');
    // staffingType
    if (staffingTypesFilter) {
      talentsFilters.push({
        field: 'staffingType',
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        value: staffingTypesFilter.value.toString(),
      });
    }

    try {
      const events = await getEvents({
        timezone,
        startDate: dayjs(startDate),
        endDate: dayjs(endDate),
        filters: eventsFilters,
      });
      const assignments = await getAssignments(startDate.toISOString(), endDate.toISOString(), timezone, filters);
      const talents = await getTalents({
        page: 1,
        // TODO: potential issue, we expect talents amount to be more than 50
        size: 50,
        filters: talentsFilters,
      });
      set({
        loading: false,
        eventsList: events.data,
        assignmentsLoading: false,
        assignmentsList: assignments.data,
        // TODO: currently the only available method to filter by staff is is to filter on frontend
        resourcesList: talents.data.items.filter((t) =>
          filterByStaff && Array.isArray(filterByStaff.value) ? filterByStaff.value.includes(t.id) : true,
        ),
        resourcesListLoading: false,
        calendarDataLoading: false,
      });
    } finally {
      set({ loading: false, initiated: true, assignmentsInitiated: true, resourcesInitiated: true });
    }
  },
}));

import { request } from '../request';
import { StaffingPartnerSimpleListItem } from '@libs/models/staffingPartners/staffingPartners';

export async function fetchExternalStaffingPartnersList(): Promise<StaffingPartnerSimpleListItem[]> {
  const response = await request.get<AgenciesResponseItem[]>('/v2/agencies/external');
  return response.data.map((item) => ({
    id: item.id,
    name: item.name,
    contact: {
      email: item.contact.email.value,
      name: [item.contact.firstName, item.contact.lastName].filter(Boolean).join(' '),
      phoneNumber: item.contact.phoneNumber.value,
    },
  }));
}

type AgenciesResponseItem = {
  id: string;
  name: string;
  contact: {
    firstName: string;
    lastName: string;
    phoneNumber: {
      value: string;
    };
    email: {
      value: string;
    };
  };
};

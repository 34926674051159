import { DateHelper, ResourceModel } from '@bryntum/schedulerpro';
import { Qualification } from '@libs/models/settings';
import { SpecialtyShort } from '@libs/models/settings/organization';
import { EmploymentType, StaffingStatus, TalentStaffingType } from '@libs/models/talents/talent';
import { Shift } from './Shift';

// Custom Doctor resource model, based on ResourceModel with additional fields
export class Doctor extends ResourceModel {
  declare department: string;
  declare avatar: string;

  declare staffingType: TalentStaffingType;
  declare staffingRoles: string[];
  declare employmentType: EmploymentType;
  declare firstName: string;
  declare lastName: string;
  declare phoneNumber: string;
  declare email: string;
  declare hourlyPayRate: number;
  declare staffingStatus: StaffingStatus;
  declare otherQualifications: Qualification[];
  declare specialities: SpecialtyShort[];
  declare lastWorkedTime: string;
  declare nurseQualifications: Qualification[];

  declare calendar: string;
  static fields = ['staffingType', 'roleIconCls'];

  getBookedHours() {
    return this.events.reduce((acc, el) => acc + el.duration, 0);
  }

  haveFreeTime(start: string | number | Date, end: string | number | Date): boolean {
    return (this.events as Shift[]).every(
      (evt: Shift) =>
        !DateHelper.intersectSpans(
          new Date(start),
          new Date(end),
          new Date(evt.actualStartDate),
          new Date(evt.actualEndDate),
        ),
    );
  }
}

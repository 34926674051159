import { create } from 'zustand';
import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { fetchStaffingPartnersList } from '@libs/api/staffingPartners/fetch';
import { Filter } from '@libs/models/common';

type InitialState = {
  loadingList: boolean;
  staffingPartners: StaffingPartnerList[];
  loadStaffingPartners: (filters: Filter[]) => Promise<void>;
};

export const useStaffingPartnersList = create<InitialState>()((set) => ({
  loadingList: false,
  staffingPartners: [],
  loadStaffingPartners: async (filters) => {
    set({ loadingList: true });
    try {
      const resp = await fetchStaffingPartnersList({ filters });
      set({
        loadingList: false,
        staffingPartners: resp.items,
      });
    } finally {
      set({ loadingList: false });
    }
  },
}));

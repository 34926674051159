import { create } from 'zustand';
import { createSingleShift, updateSingleShift } from '@libs/api/shifts';
import { publishShifts, SingleShiftEditData } from '@libs/api/shifts/create';
import { getSingleShift } from '@libs/api/shifts';
import { Shift } from '@libs/models/shifts';

type State = {
  loading: boolean;
  drawerOpen: boolean;
  shiftId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  data: Shift | null;
  load: (id: string) => Promise<void>;
  openShiftDrawer: (id?: string) => void;
  closeShiftDrawer: () => void;
  createShift: (shift: SingleShiftEditData) => Promise<void>;
  updateShift: (shiftId: string, shift: SingleShiftEditData) => Promise<void>;
  publishShift: (shiftId: string) => Promise<void>;
  clearCurrentShift: () => void;
};

export const useShiftAddEditStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  shiftId: '',
  data: null,
  load: async (id) => {
    set({ loading: true });
    try {
      const shift = await getSingleShift(id);
      set({
        loading: false,
        data: shift,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', shiftId: id, drawerOpen: true });
  },
  closeShiftDrawer: () => {
    set({ drawerOpen: false });
  },
  updateShift: async (shiftId, shiftData) => {
    await updateSingleShift(shiftId, shiftData);
  },
  createShift: async (shift) => {
    set({ loading: true });
    await createSingleShift({
      ...shift,
    }).finally(() => {
      set({ loading: false });
    });
  },
  publishShift: async (shiftId: string) => {
    await publishShifts([shiftId]);
  },
  clearCurrentShift: () => {
    set({ data: null });
  },
}));

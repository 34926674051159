import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';
import { Box, ClosableDrawer, PrimaryPaper } from '@atoms/layout';
import { Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { ShiftList } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShiftsIcon } from '@atoms/icons';
import { DataGrid, EmptyListPlaceholder } from '@molecules/dataDisplay';
import { useShiftListColumns } from './shiftListColumns';
import { Flex } from '@atoms/layout';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FilterMultiSelect, renderCheckboxOption, SearchField } from '@atoms/inputs';
import { StartDateRangeFilterInputControlled } from '@organisms/shifts/startDateRangeFilterInput';
import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { useStaffingPartnersShortListColumns } from '@pages/staffingPartners/listPage/table/columns';
import { useStaffingPartnersList } from '@libs/store/staffingPartners';
import { useShiftsAssignment } from '@libs/store/shifts';
import { useLocationStore } from '@libs/store/locations';
import { LocationExtra } from '@libs/models/locations';
import { TIMEZONE_FALLBACK } from '@libs/config';

type ShiftInProgressDrawerProps = {
  onSaveCall?: () => void;
};
const FallbackTimezone = TIMEZONE_FALLBACK;
export function ShareShifts({ onSaveCall }: ShiftInProgressDrawerProps) {
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: shiftLoading,
    shiftsToShare,
    closeShiftShareDrawer,
    loadSharableShifts,
    selectedShifts,
    setSelectedShifts,
    currentStep,
    setCurrentStep,
    shiftsPassedFromOutside,
  } = useShareDrawerStore();
  const { staffingPartners, loadingList: staffingPartnersLoading, loadStaffingPartners } = useStaffingPartnersList();
  const { shareShifts } = useShiftsAssignment();
  const { current: currentLocation, loading: locationsLoading, currentLocationID } = useLocationStore();

  const [selectedAgencies, setSelectedAgencies] = useState<GridRowSelectionModel>([]);
  const [shiftStatusFilter, setShiftStatusFilter] = useState<string[]>([]);
  const [shiftsDateFilter, setShiftsDateFilter] = useState<DateFilterValue | null>();
  const [sharingProgress, setSharingProgress] = useState(false);

  const localizationSubset = 'shifts.shiftsShareDrawer';
  const open = drawerOpen;

  const loading = shiftLoading || staffingPartnersLoading || locationsLoading || sharingProgress;

  useEffect(() => {
    loadSharableShifts(shiftsDateFilter?.value || null, shiftStatusFilter, currentLocationID);
  }, [loadSharableShifts, shiftStatusFilter, shiftsDateFilter]);

  useEffect(() => {
    loadStaffingPartners([{ key: 'status', value: ['Active'] }]);
  }, [loadStaffingPartners]);

  const handleClose = () => {
    closeShiftShareDrawer();
  };

  const handleSubmit = async () => {
    setSharingProgress(true);
    try {
      await shareShifts(selectedShifts, selectedAgencies as string[]);
      onSaveCall && onSaveCall();
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
    setSharingProgress(false);
  };

  const handleNextPage = async () => {
    if (currentStep === 'shifts') {
      setCurrentStep('agencies');
    } else {
      await handleSubmit();
    }
  };
  const handlePrevPage = () => {
    if (shiftsPassedFromOutside) {
      handleClose();
      return;
    }

    if (currentStep === 'agencies') {
      setCurrentStep('shifts');
    } else {
      handleClose();
    }
  };

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handleNextPage} disabled={loading || !selectedShifts.length}>
            <FormattedMessage
              id={`${localizationSubset}.menuButtons.${currentStep === 'agencies' ? 'share' : 'proceed'}.label`}
            />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handlePrevPage}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>

        {currentStep === 'shifts' && (
          <ShiftsPage
            loading={shiftLoading}
            rows={shiftsToShare}
            currentLocation={currentLocation}
            localizationSubset={localizationSubset}
            onSelectionChange={(values) => {
              setSelectedShifts(values.map((v) => v.toString()));
            }}
            onStatusFilterChange={setShiftStatusFilter}
            onDateFilterChange={setShiftsDateFilter}
          />
        )}
        {currentStep === 'agencies' && (
          <AgenciesPage
            loading={staffingPartnersLoading}
            rows={staffingPartners}
            onSelectionChange={setSelectedAgencies}
            shiftsSelected={selectedShifts.length}
            localizationSubset={localizationSubset}
          />
        )}
      </Stack>
    </ClosableDrawer>
  );
}

type DateFilterValue = { key: string; value: { from: Dayjs; to: Dayjs; timezone: string } };
type ShiftsPageProps = {
  rows: ShiftList[];
  loading: boolean;
  localizationSubset: string;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
  onStatusFilterChange: (values: string[]) => void;
  onDateFilterChange: (value: DateFilterValue | null) => void;
  currentLocation: LocationExtra | null;
};

function ShiftsPage({
  rows,
  loading,
  localizationSubset,
  onSelectionChange,
  onStatusFilterChange,
  onDateFilterChange,
  currentLocation,
}: ShiftsPageProps) {
  const intl = useIntl();

  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const dateLabel = [dateFilter?.value.from?.format('MMM D, YYYY'), dateFilter?.value.to?.format('MMM D, YYYY')]
    .filter(Boolean)
    .join(' - ');

  useEffect(() => {
    onDateFilterChange && onDateFilterChange(dateFilter);
  }, [dateFilter]);
  useEffect(() => {
    onStatusFilterChange && onStatusFilterChange(statusFilter);
  }, [statusFilter]);
  useEffect(() => {
    onSelectionChange && onSelectionChange(rowSelectionModel);
  }, [rowSelectionModel]);

  const localizationSet = `${localizationSubset}.shiftsPickPage`;

  const statusFilterOptions = ['Unassigned', 'Pending'].map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <PrimaryPaper sx={{ mt: 1 }}>
      <Flex column gap={2} sx={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <StartDateRangeFilterInputControlled
              loading={loading}
              timezone={currentLocation?.timezone || FallbackTimezone}
              onClear={() => setDateFilter(null)}
              onSetFilter={(filter) => setDateFilter(filter as DateFilterValue)}
              dateLabel={dateLabel}
              dateValue={dateFilter?.value}
            />
          </Grid>
          <Grid item xs={4}>
            <FilterMultiSelect
              size="small"
              disabled={loading}
              options={statusFilterOptions}
              inlineLabel
              fullWidth
              label={`${intl.formatMessage({ id: 'filters.statuses.label' })}:`}
              onApply={(v) => setStatusFilter(v)}
              onClear={() => setStatusFilter([])}
              value={statusFilter ?? []}
              renderOption={renderCheckboxOption}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                label={intl.formatMessage(
                  { id: `${localizationSet}.shiftsSelectionCounter` },
                  { amount: rowSelectionModel.length },
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Flex height="100%" minHeight={300}>
          <DataGrid
            rows={rows}
            loading={loading}
            rowHeight={64}
            columns={useShiftListColumns()}
            autoPageSize
            hideFooter
            sx={{ minHeight: '300px' }}
            disableVirtualization={false}
            checkboxSelection
            filterModel={{
              items: [{ field: 'name', operator: 'contains', value: search }],
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            noRowsOverlay={
              <EmptyListPlaceholder
                icon={<ShiftsIcon active />}
                title={<FormattedMessage id="shifts.table.noRows.title" />}
                subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
              />
            }
          />
        </Flex>
      </Flex>
    </PrimaryPaper>
  );
}

type AgenciesPageProps = {
  loading: boolean;
  shiftsSelected: number;
  localizationSubset: string;
  rows: StaffingPartnerList[];
  onSelectionChange: (selection: GridRowSelectionModel) => void;
};

function AgenciesPage({
  shiftsSelected,
  localizationSubset,
  rows,
  onSelectionChange,
  loading = false,
}: AgenciesPageProps) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const localizationSet = `${localizationSubset}.agencyPickPage`;

  useEffect(() => {
    const agenciesUrls = rows.filter((row, index) => rowSelectionModel.includes(row.id)).map((row) => row.partnerUrl);
    onSelectionChange && onSelectionChange(agenciesUrls);
  }, [rowSelectionModel]);

  const rowsFiltered = search
    ? rows.filter((row) => {
        const record = JSON.stringify(row);
        if (record.includes(search)) {
          return true;
        }
      })
    : rows;

  return (
    <PrimaryPaper sx={{ mt: 1 }}>
      <Stack direction={'column'} spacing={2} sx={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container>
          <Grid item xs={4}>
            <SearchField
              disabled={loading}
              sx={{ width: 260, flexShrink: 0 }}
              value={search}
              onChange={(elem: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setSearch(elem.target.value)
              }
            />
          </Grid>
          <Grid item xs={5}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                color="primary"
                label={intl.formatMessage(
                  { id: `${localizationSet}.shiftsSelectionCounter` },
                  { amount: shiftsSelected },
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                label={intl.formatMessage(
                  { id: `${localizationSet}.agenciesSelectionCounter` },
                  { amount: rowSelectionModel.length },
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Flex height="100%" minHeight={0}>
          <DataGrid
            rows={rowsFiltered}
            loading={loading}
            rowHeight={64}
            columns={useStaffingPartnersShortListColumns()}
            autoPageSize
            hideFooter
            disableVirtualization={false}
            checkboxSelection
            filterModel={{
              items: [{ field: 'name', operator: 'contains', value: search }],
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            noRowsOverlay={
              <EmptyListPlaceholder
                icon={<ShiftsIcon active />}
                title={<FormattedMessage id="shifts.table.noRows.title" />}
                subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
              />
            }
          />
        </Flex>
      </Stack>
    </PrimaryPaper>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

import { AxiosError, isAxiosError } from 'axios';

export class ErrorWithDetails extends Error {
  details: ErrorDetails;
  constructor(message: string, details: ErrorDetails) {
    super(message);
    this.name = 'AppError';
    this.details = details;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorDetails = { type: string; values: Record<string, any> };

export type ServerErrorData = {
  status: number;
  type: string;
  title: string;
  detail?: string;
  instance: string;
  errors?: Record<string, string[] | undefined>;
};

export function isServerError(error: unknown): error is AxiosError<ServerErrorData> {
  if (!isAxiosError(error)) {
    return false;
  }
  const data = error.response?.data;

  return (data as ServerErrorData).status !== undefined;
}

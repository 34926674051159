import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer, PrimaryPaper } from '@atoms/layout';
import { Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { DateHelper } from '@bryntum/schedulerpro';
import { ShiftList } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { ShiftsIcon } from '@atoms/icons';
import { DataGrid, EmptyListPlaceholder } from '@molecules/dataDisplay';
import { useShiftListColumns } from './shiftListColumns';
import { Flex } from '@atoms/layout';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { StartDateRangeFilterInputControlled } from '@organisms/shifts/startDateRangeFilterInput';
import { useShiftsAssignment } from '@libs/store/shifts';
import { usePublishDrawerStore } from '@libs/store/shifts/shiftPublishStore';
import { useLocationStore } from '@libs/store/locations';
import { LocationExtra } from '@libs/models/locations';
import { TIMEZONE_FALLBACK } from '@libs/config';

type ShiftInProgressDrawerProps = {
  onSaveCall?: () => void;
};

const FallbackTimezone = TIMEZONE_FALLBACK;
export function PublishShiftsDrawer({ onSaveCall }: ShiftInProgressDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: shiftLoading,
    shiftsToPublish,
    closeShiftPublishDrawer,
    loadPublishableShifts,
  } = usePublishDrawerStore();
  const { publishShifts } = useShiftsAssignment();
  const { current: currentLocation, loading: locationsLoading, currentLocationID } = useLocationStore();

  const [selectedShifts, setSelectedShifts] = useState<GridRowSelectionModel>([]);
  const [shiftsDateFilter, setShiftsDateFilter] = useState<DateFilterValue | null>(null);

  const localizationSubset = 'shifts.shiftsPublishDrawer';
  const open = drawerOpen;

  const loading = shiftLoading || locationsLoading;

  useEffect(() => {
    loadPublishableShifts(
      shiftsDateFilter?.value ?? {
        from: dayjs().startOf('day'),
        timezone: currentLocation?.timezone || FallbackTimezone,
      },
      currentLocationID
    );
  }, [loadPublishableShifts, shiftsDateFilter, currentLocation]);

  const handleClose = () => {
    closeShiftPublishDrawer();
  };

  const handleSubmit = async () => {
    try {
      await publishShifts(selectedShifts as string[]);
      onSaveCall && onSaveCall();
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  };

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handleSubmit} disabled={loading || !selectedShifts.length}>
            <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handleClose}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>
        <ShiftsPage
          loading={shiftLoading}
          rows={shiftsToPublish}
          currentLocation={currentLocation}
          localizationSubset={localizationSubset}
          onSelectionChange={setSelectedShifts}
          onDateFilterChange={setShiftsDateFilter}
        />
      </Stack>
    </ClosableDrawer>
  );
}

type DateFilterValue = { key: string; value: { from: Dayjs; to: Dayjs; timezone: string } };
type ShiftsPageProps = {
  rows: ShiftList[];
  loading: boolean;
  localizationSubset: string;
  currentLocation: LocationExtra | null;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
  onDateFilterChange: (value: DateFilterValue | null) => void;
};

function ShiftsPage({
  rows,
  loading,
  localizationSubset,
  onSelectionChange,
  onDateFilterChange,
  currentLocation,
}: ShiftsPageProps) {
  const intl = useIntl();

  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const dateLabel = [dateFilter?.value.from?.format('MMM D, YYYY'), dateFilter?.value.to?.format('MMM D, YYYY')]
    .filter(Boolean)
    .join(' - ');

  useEffect(() => {
    onDateFilterChange && onDateFilterChange(dateFilter);
  }, [dateFilter]);
  useEffect(() => {
    onSelectionChange && onSelectionChange(rowSelectionModel);
  }, [rowSelectionModel]);

  const localizationSet = `${localizationSubset}.shiftsPickPage`;

  return (
    <PrimaryPaper sx={{ mt: 1 }}>
      <Stack direction={'column'} spacing={2} sx={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container>
          <Grid item xs={7}>
            <StartDateRangeFilterInputControlled
              loading={loading}
              timezone={currentLocation?.timezone || FallbackTimezone}
              onClear={() => setDateFilter(null)}
              onSetFilter={(filter) => setDateFilter(filter as DateFilterValue)}
              dateLabel={dateLabel}
              dateValue={dateFilter?.value}
            />
          </Grid>
          <Grid item xs={5}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                label={intl.formatMessage(
                  { id: `${localizationSet}.shiftsSelectionCounter` },
                  { amount: rowSelectionModel.length },
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Flex height="100%" minHeight={0}>
          <DataGrid
            rows={rows}
            loading={loading}
            rowHeight={64}
            columns={useShiftListColumns()}
            autoPageSize
            hideFooter
            sx={{ minHeight: '300px' }}
            disableVirtualization={false}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            noRowsOverlay={
              <EmptyListPlaceholder
                icon={<ShiftsIcon active />}
                title={<FormattedMessage id="shifts.table.noRows.title" />}
                subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
              />
            }
          />
        </Flex>
      </Stack>
    </PrimaryPaper>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

import { create } from 'zustand';

type State = {
  welcomeDialogOpen: boolean;

  setWelcomeDialogOpen: (open?: boolean) => void;
};

export const useDreeBetaStore = create<State>()((set) => ({
  welcomeDialogOpen: false,

  setWelcomeDialogOpen: (value = true) => {
    set({ welcomeDialogOpen: !!value });
  },
}));

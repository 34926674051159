import { LogLevel, DEFAULT_RENDERERS, Logger, DEFAULT_LOG_LEVEL } from '@shiftnex/framework/src/libs/logger';
import { greater } from './constants';

import { BUILD_INFO, LOG_ENABLED, LOG_LEVEL } from '../config';

const logLevel = LOG_LEVEL as LogLevel;
const isEnabled = LOG_ENABLED;
export const log = new Logger([], isEnabled, logLevel || DEFAULT_LOG_LEVEL, DEFAULT_RENDERERS);
export default log;

export function great() {
  log.service('====================================');
  log.service(greater);
  log.service('====================================');
  log.service('Logger is turned ON');
  log.service('Current log level: ', `${logLevel ?? ''}`.toUpperCase());
  log.service('Current build mode: ', window.location.origin);
  log.service('Build mode:', BUILD_INFO.BUILD_MODE ?? 'N/A');
  log.service('Current env type:', BUILD_INFO.ENV_TYPE ?? 'internal');
  log.service('Working regions:', BUILD_INFO.WORKING_REGIONS);
  log.service('Commit:', `[${BUILD_INFO.COMMIT_DATE}] - ${BUILD_INFO.COMMIT_AUTHOR} - ${BUILD_INFO.COMMIT_MESSAGE}`);
  log.service('Commit link:', BUILD_INFO.COMMIT_URL);
  log.service('Latest version tag:', BUILD_INFO.COMMIT_TAG);
  log.service('Built at:', BUILD_INFO.BUILD_DATE_TIME);
  log.service('====================================');
}

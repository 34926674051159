import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { checkboxClasses } from '@mui/material/Checkbox';
import { alpha, useTheme } from '@mui/material/styles';
import {
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridValidRowModel,
  DataGridPro as MuiDataGrid,
  DataGridProProps as MuiDataGridProps,
  getDataGridUtilityClass,
} from '@mui/x-data-grid-pro';
import { GridColDef } from './gridColDef';
import { GridTreeDataGroupingCell } from './gridTreeDataGroupingCell';

type DataGridProps<T extends GridValidRowModel> = Omit<MuiDataGridProps<T>, 'columns'> & {
  noRowsOverlay?: ReactNode;
  stretchColumns?: boolean;
  columns: GridColDef<T>[];
};
export function DataGrid<T extends GridValidRowModel>({
  slots,
  noRowsOverlay,
  onRowClick,
  columns,
  stretchColumns,
  sx,
  paginationModel,
  onPaginationModelChange,
  treeData,
  groupingColDef,
  ...props
}: DataGridProps<T>) {
  const theme = useTheme();

  return (
    <MuiDataGrid
      columnHeaderHeight={64}
      rowHeight={64}
      columns={useDefaultColumnConfig(columns, { stretchColumns, treeData })}
      pagination
      sx={{
        ...sx,
        borderRadius: 4,
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.grey[100]}`,
        [`& .${getDataGridUtilityClass('row--borderBottom')} .${getDataGridUtilityClass('columnHeader')}`]: {
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.04)}`,
        },
        [`& .${getDataGridUtilityClass('cell')}`]: {
          borderTop: `1px solid ${alpha(theme.palette.divider, 0.04)}`,
        },
        [`& .${getDataGridUtilityClass('columnHeaderTitle')}`]: {
          fontWeight: 400,
          color: theme.palette.text.secondary,
        },
        [`& .${getDataGridUtilityClass('columnHeader')}:focus-within`]: {
          outline: 'none',
        },
        [`& .${innerRowClassName} .${checkboxClasses.root}`]: {
          // Need to hide selection checkboxes for inner rows when `treeData` is activated
          // Checkboxes on the inner rows are disabled for this reason
          display: 'none',
        },
      }}
      paginationMode="server"
      sortingMode="server"
      slots={{ noRowsOverlay: () => noRowsOverlay, ...slots }}
      onRowClick={(...params) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (onRowClick && params[0].row.type !== 'separator') {
          onRowClick(...params);
        }
      }}
      paginationModel={{
        pageSize: paginationModel?.pageSize ?? 10,
        // We want to use 1-based page index
        page: (paginationModel?.page ?? 1) - 1,
      }}
      onPaginationModelChange={(model, details) => {
        onPaginationModelChange?.(
          {
            ...model,
            // We want to use 1-based page index
            page: model.page + 1,
            pageSize: model.pageSize,
          },
          details,
        );
      }}
      treeData={treeData}
      // Don't allow to select inner rows
      isRowSelectable={(params) => (params.row?.path ? params.row.path.length === 1 : true)}
      getRowClassName={(params) => (params.row.path && params.row.path?.length !== 1 ? innerRowClassName : '')}
      getTreeDataPath={getTreePath}
      groupingColDef={treeData ? { ...groupingColDefInternal, ...groupingColDef } : undefined}
      {...props}
    />
  );
}

function useDefaultColumnConfig<T extends GridValidRowModel>(
  columns: readonly GridColDef<T>[],
  options?: { stretchColumns?: boolean; treeData?: boolean },
) {
  const intl = useIntl();

  return useMemo(
    () => {
      return [
        ...columns.map((column) => ({
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          minWidth: 100,
          ...(column.intlHeaderName ? { headerName: intl.formatMessage({ id: column.intlHeaderName }) } : {}),
          ...(options?.stretchColumns && !column.width
            ? {
                flex: 1,
              }
            : {}),
          ...column,
        })),
        ...(options?.treeData
          ? [
              {
                field: GRID_TREE_DATA_GROUPING_FIELD,
                width: 60,
              },
            ]
          : []),
      ];
    },
    // disabling for `options` object
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, intl],
  );
}

const groupingColDefInternal: DataGridProProps['groupingColDef'] = {
  headerName: '',
  width: 60,
  disableReorder: true,
  resizable: false,
  renderCell: (params) => {
    return <GridTreeDataGroupingCell {...params} />;
  },
};

function getTreePath(row: unknown) {
  if (typeof row !== 'object' || row === null) {
    return [];
  }

  if (!('path' in row)) {
    console.warn('`path` property is missing in the row', row);
    return [];
  }

  if (!Array.isArray(row.path)) {
    console.warn('`path` property of the row should be an array of strings, but instead', row.path);
    return [];
  }

  return row.path as string[];
}

const innerRowClassName = 'inner-row';


/**
 * Is a type that represents the data of a shift to be displayed in console
 * WARNING - DO NOT mix the original Shift model type into this one. It should be separate
 */
type ShiftData = {
  id: string | number;
}

export const outputShiftDataToConsole = (shift: ShiftData) => {
  const shiftId = ''+shift.id;
  const shiftIdParts = shiftId.split('-');
  const ulid = shiftIdParts[shiftIdParts.length - 1];
  console.log('');
  console.log('===== Shift ifo =====');
  console.log('Shift system ID:', shiftId);
  console.log('Shift ID Ulid-encoded:', ulid);
  console.log('Shift ID Ulid-decoded:', `0x${bytesToHex(base32Decode(ulid))}`);
  console.log('=====================');
  console.log('');
};

// Crockford's Base32 decoding table
const BASE32_ALPHABET = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";

/**
 * Decodes a Crockford's Base32 encoded string to bytes.
 * @param {string} encodedStr - The Base32 encoded string.
 * @returns {Uint8Array} - The decoded bytes.
 */
function base32Decode(encodedStr: string) {
  let decodedValue = 0n; // Using BigInt for large numbers

  for (const char of encodedStr) {
    decodedValue = decodedValue * 32n + BigInt(BASE32_ALPHABET.indexOf(char.toUpperCase()));
  }

  // Convert BigInt to byte array (16 bytes, big-endian)
  const bytes = new Uint8Array(16);
  for (let i = 15; i >= 0; i--) {
    bytes[i] = Number(decodedValue & 0xFFn);
    decodedValue = decodedValue >> 8n;
  }

  return bytes;
}

/**
 * Converts a byte array to a hex string.
 * @param {Uint8Array} bytes - The byte array.
 * @returns {string} - The hex string.
 */
function bytesToHex(bytes: Uint8Array) {
  return Array.from(bytes)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

export const METHODS = {
  DEBUG: 'debug',
  LOG: 'log',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  FATAL: 'fatal',
  CRITICAL: 'critical',
} as const;

export const LOG_LEVELS = METHODS;

export const DEFAULT_LOG_LEVEL = LOG_LEVELS.FATAL;

export const LOG_LVELS_MAP = {
  [METHODS.DEBUG]: [
    METHODS.DEBUG,
    METHODS.LOG,
    METHODS.INFO,
    METHODS.WARN,
    METHODS.ERROR,
    METHODS.FATAL,
    METHODS.CRITICAL,
  ],
  [METHODS.LOG]: [METHODS.LOG, METHODS.INFO, METHODS.WARN, METHODS.ERROR, METHODS.FATAL, METHODS.CRITICAL],
  [METHODS.INFO]: [METHODS.INFO, METHODS.WARN, METHODS.ERROR, METHODS.FATAL, METHODS.CRITICAL],
  [METHODS.WARN]: [METHODS.WARN, METHODS.ERROR, METHODS.FATAL, METHODS.CRITICAL],
  [METHODS.ERROR]: [METHODS.ERROR, METHODS.FATAL, METHODS.CRITICAL],
  [METHODS.FATAL]: [METHODS.FATAL, METHODS.CRITICAL],
  [METHODS.CRITICAL]: [METHODS.CRITICAL],
};

import { Dayjs } from 'dayjs';
import { TalentsResponseItem } from '@libs/api/talents';
import { Qualification, QualificationPayRates } from '../settings';
import { Staff } from '../staff';

export type TalentStaffingType = 'Internal' | 'Agency' | 'DirectContractor';
export type EmploymentType = 'FullTime' | 'PartTime';
export type StaffingStatus = 'InviteFailed' | 'Invited' | 'Pending' | 'Active' | 'Deactivated' | 'Deleted';

export type TalentEditData = TalentEditGeneralData & TalentEditRolePayRateData & TalentEditAvailabilityData;

export type TalentListItem = Omit<TalentsResponseItem, 'lastWorkedTime'> & { lastWorkedTime: Dayjs };

export type TalentData = TalentEditGeneralData & TalentEditRolePayRateData & TalentEditAvailabilityData;

export type TalentEditGeneralData = {
  image: File | null;
  imageUrl?: string | null;
  isImageUrlNeedToBeUpdated: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: StaffingStatus;
  address: {
    firstAddress: string;
    city: string;
    state: string;
    zipCode: string;
    secondAddress: string;
  };
  hrInfo: {
    primaryRegion: {
      city: string;
      state: string;
    };
    secondaryRegion: {
      city: string;
      state: string;
    };
  };
};

export type TalentEditRolePayRateData = {
  locationId: string;
  jobTitle: string;
  qualificationPayRates: QualificationPayRates[];
};

export type TalentEditAvailabilityData = {
  employmentType: string;
  availableForTravelDistance: number;
  workShifts: {
    dayOfWeek: string;
    startTime: string;
    duration: string;
  }[];
};

export type Talent = Staff & {
  staffingType: 'Internal' | 'Agency' | 'DirectContractor';
  specialities: {
    id: string;
    name: string;
    category: string;
  }[];
  nurseQualification: Qualification;
  otherQualification: Qualification;
  staffingRoles: string[];
  hourlyPayRate: number;
  staffingStatus: TalentStaffingStatus;
};

export type TalentStaffingStatus = 'InviteFailed' | 'Invited' | 'Pending' | 'Active' | 'Deactivated' | 'Deleted';

export type TalentResponse = Omit<Talent, 'lastWorkedTime'> & { lastWorkedTime: string };

export class StaffHasActiveShiftsError extends Error {
  data: StaffHasActiveShiftsErrorDetails;

  constructor(data: StaffHasActiveShiftsErrorDetails) {
    super('Staff has active shifts');
    this.name = 'StaffHasActiveShiftsError';
    this.data = data;
  }
}

export type StaffHasActiveShiftsErrorDetails = {
  detail: string;
  instance: string;
  staff: {
    fullName: string;
    staffId: string;
  }[];
  status: 403;
  title: 'staffHasActiveShifts';
  traceId: string;
  type: 'staffHasActiveShifts';
};

import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Filter, isDateRangeFilter } from '@libs/models/common';
import { FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';
import dayjs from 'dayjs';

type ActiveFiltersProps = {
  filters: FilterItem[];
  onDelete?: (item: FilterItem) => void;
};
export function ActiveFilters({ filters, onDelete }: ActiveFiltersProps) {
  const theme = useTheme();

  return (
    <>
      {filters.map((f) => (
        <Chip
          variant="outlined"
          key={f.key}
          label={<FilterContent filter={f} />}
          color="primary"
          onDelete={() => onDelete?.(f)}
          deleteIcon={<FaIcon color={theme.palette.primary.main} name="circle-xmark" />}
        />
      ))}
    </>
  );
}

type FilterContentProps = {
  filter: FilterItem;
};
function FilterContent({ filter }: FilterContentProps) {
  if (isDateRangeFilter(filter)) {
    return (
      <>
        {filter.label}: {dayjs(filter.value.from).format('MMM D, YYYY')} - {dayjs(filter.value.to).format('MMM D, YYYY')}
      </>
    );
  }

  const { label, value, valueView } = filter;
  const view = valueView || value.toString();
  return (
    <Flex gap={0.5} maxWidth={300}>
      <Typography variant="body2" color="text.secondary">
        {label}:
      </Typography>
      <Typography variant="body2" noWrap title={view}>
        {view}
      </Typography>
    </Flex>
  );
}

export type FilterItem = Filter & { label: ReactNode };

import { create } from 'zustand';
import { getEvents } from '@libs/api/shifts';
import { ShiftList } from '@libs/models/shifts/shift';
import { DateHelper } from '@bryntum/schedulerpro';
import dayjs, { Dayjs } from 'dayjs';
import { TIMEZONE_FALLBACK } from '@libs/config';

const FallbackTimezone = TIMEZONE_FALLBACK;

type DateFilterValue = { from: Dayjs; to: Dayjs; timezone: string };

type State = {
  loading: boolean;
  drawerOpen: boolean;
  rotationId: string;
  initiated: boolean;
  shiftsPassedFromOutside: boolean;
  shiftsToShare: ShiftList[];
  selectedShifts: string[];
  setSelectedShifts: (ids: string[]) => void;
  currentStep: 'shifts' | 'agencies';
  setCurrentStep: (step: 'shifts' | 'agencies') => void;
  loadSharableShifts: (dateFilter: DateFilterValue | null, statusFilter: string[], locationId: string) => void;
  openShiftShareDrawer: (config?: OpenShiftShareDrawerConfig) => void;

  closeShiftShareDrawer: () => void;
};

export const useShareDrawerStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  initiated: false,
  shiftsPassedFromOutside: false,
  rotationId: '',
  currentStep: 'shifts',
  shiftsToShare: [],
  selectedShifts: [],
  loadSharableShifts: async (dateFilter, statusFilter, locationId) => {
    set({ loading: true });

    const statuses = statusFilter.length
      ? { key: 'status', value: statusFilter }
      : { key: 'status', value: ['Unassigned', 'Pending'] };
    try {
      const timezone = dateFilter?.timezone || FallbackTimezone;

      const resp = await getEvents({
        timezone,
        startDate: dateFilter?.from,
        endDate: dateFilter?.to,
        filters: [
          {
            key: 'staffingtypes',
            value: ['External', 'All'],
          },
          statuses,
          { key: 'locations', value: [locationId] },
        ],
      });
      set({
        loading: false,
        shiftsToShare: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftShareDrawer: (config) => {
    const { rotationId, preselectedShifts } = config ?? {};
    set({
      rotationId,
      drawerOpen: true,
      selectedShifts: preselectedShifts ?? [],
      currentStep: preselectedShifts ? 'agencies' : 'shifts',
      shiftsPassedFromOutside: !!preselectedShifts,
    });
  },
  closeShiftShareDrawer: () => {
    set({ drawerOpen: false });
  },
  setSelectedShifts: (ids) => {
    set({ selectedShifts: ids });
  },
  setCurrentStep: (step) => {
    set({ currentStep: step });
  },
}));

type OpenShiftShareDrawerConfig = {
  rotationId?: string;
  preselectedShifts?: string[];
};

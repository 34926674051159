import * as amplitude from '@amplitude/analytics-browser';
import { Analytics as LibAnalytics } from '@shiftnex/framework/src/libs/analytics';

export class Analytics extends LibAnalytics {
  static eventProps = {
    ...LibAnalytics.eventProps,
    ProductID: 'FX',
  };

  static async track(
    eventInput: string,
    eventProperties?: Record<string, string | number | boolean | undefined>,
    eventOptions?: amplitude.Types.EventOptions,
  ) {
    return await super.track(
      eventInput,
      {
        ...this.getEventProps(),
        ...eventProperties,
      },
      eventOptions,
    );
  }
}

import { AutocompleteMultiple, type AutocompleteMultipleProps } from '@atoms/inputs';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type FormAutocompleteMultipleProps<T extends FieldValues> = Omit<
  AutocompleteMultipleProps<string | number>,
  'name'
> & {
  name: Path<T>;
  requiredMessage?: string;
  lockedOptions?: string[];
};
export function FormAutocompleteMultiple<T extends FieldValues>({
  name,
  requiredMessage,
  lockedOptions = [],
  ...props
}: FormAutocompleteMultipleProps<T>) {
  const intl = useIntl();
  const { required } = props;
  const { control } = useFormContext<T>();
  const rules = {
    ...(required
      ? {
          required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
        }
      : {}),
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <AutocompleteMultiple
            {...props}
            onChange={(_, items) => {
              const selectedIds = items.map((i) => i.value);
              // To prevent locked options from being unselected
              const prevSelected = field.value.filter((i: string | number) => {
                return selectedIds.includes(i.toString()) || lockedOptions.includes(i.toString());
              });

              onChange(Array.from(new Set([...prevSelected, ...selectedIds])));
            }}
            {...field}
            error={!!error}
            helperText={error?.message}
          />
        );
      }}
    />
  );
}

import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { Filter, PaginatedResponse } from '@libs/models/common';
import { request } from '../request';
import { filterToQueryParams } from '../utils/filterToQueryParams';

type fetchStaffingPartnersListParams = {
  search?: string;
  page?: number;
  size?: number;
  filters?: Filter[];
};
export async function fetchStaffingPartnersList(
  params?: fetchStaffingPartnersListParams,
): Promise<PaginatedResponse<StaffingPartnerList[]>> {
  const filters = filterToQueryParams((params?.filters ?? []).map((fil) => ({ field: fil.key, value: fil.value })));

  const response = await request.get<PaginatedResponse<AgenciesResponseItem[]>>('/v2/agencies', {
    params: { pageNumber: 1, pageSize: 60, search: params?.search || undefined, ...filters },
  });
  return {
    pageNumber: 1,
    pageSize: 60,
    totalCount: 60,
    hasNextPage: false,
    hasPreviousPage: false,
    items: response.data.items.map((item, idx) => ({
      id: item.id
        ? item.id
        : `${item.name}_${item.mainContact.email}_${item.regions.join(', ')}_${item.mainContact.phoneNumber}_${item.address.firstAddress}_${+new Date()}${idx}`,
      name: item.name,
      icon: item.logoUrl,
      region: item.regions.join(', '),
      contact: {
        email: item.mainContact.email,
        name: item.mainContact.name,
        phoneNumber: item.mainContact.phoneNumber,
        title: item.mainContact.title,
        secondaryPhoneNumber: item.mainContact.secondaryPhoneNumber,
      },
      address: {
        firstAddress: item.address.firstAddress,
        city: item.address.city,
        state: item.address.state,
        zipCode: item.address.zipCode,
        secondAddress: item.address.secondAddress,
      },
      relationType: item.relationType,
      invitationStatus: item.status,
      partnerUrl: item.id,
    })),
  };
}

type AgenciesResponseItem = {
  id: string;
  name: string;
  regions: string[];
  logoUrl: string;
  mainContact: {
    email: string;
    name: string;
    phoneNumber: string;
    title: string;
    secondaryPhoneNumber: string;
  };
  address: {
    firstAddress: string;
    city: string;
    state: string;
    zipCode: string;
    secondAddress: string;
  };
  relationType: 'Direct' | 'Managed';
  status: 'Pending' | 'Disconnected' | 'Active' | 'OnReview';
};

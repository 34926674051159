import { FormattedMessage, useIntl } from 'react-intl';
import { InfoBlocks } from '@atoms/dataDisplay';
import { useShiftNotesStore } from '@libs/store/shifts';
import { useEffect } from 'react';
import { DelayedLinearProgress } from '@molecules/feedback';
import Fade from '@mui/material/Fade';
import { Box } from '@atoms/layout';
import { ShiftOld } from '@libs/models/shifts/shift';

export function ShiftNotes({ shift }: { shift: ShiftOld | null }) {
  const intl = useIntl();

  const notes = [
    shift?.notes
      ? {
          key: shift.id,
          title: '',
          body: shift.notes,
        }
      : {
          key: 'noNotes',
          title: intl.formatMessage({ id: 'shifts.noNotes.title' }),
          body: intl.formatMessage({ id: 'shifts.noNotes.subtitle' }),
        },
  ];

  return (
    <>
      {/* <DelayedLinearProgress show={loading} /> */}
      <Fade in={notes.length > 0}>
        <Box>
          <InfoBlocks
            title={<FormattedMessage id="shifts.dayOneInstructions" />}
            items={notes.map((note) => ({
              key: note.key,
              title: note.title,
              value: note.body,
            }))}
            columns={1}
          />
        </Box>
      </Fade>
      {/* <Fade in={additionalNotes.length > 0}>
        <Box>
          <InfoBlocks
            title={<FormattedMessage id="shifts.additionalNotes" />}
            items={additionalNotes.map((note) => ({
              key: note.id,
              title: note.title,
              value: note.body,
            }))}
            columns={1}
          />
        </Box>
      </Fade> */}
    </>
  );
}

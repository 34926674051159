import { Filter } from '@libs/models/common';
import dayjs from 'dayjs';

export function filterToQueryParams(
  filters: {
    field: string;
    value: Filter['value'];
  }[],
  { removeEmpty }: Options = {},
) {
  return filters?.reduce<Record<string, string[] | string | number | { dateTime: string; timeZone: string }>>(
    (acc, filter) => {
      if (filter.field === 'startDate' && typeof filter.value === 'object' && !Array.isArray(filter.value)) {
        return {
          ...acc,
          ...(filter.value.from && { [`startDate`]: dayjs(filter.value.from).toServerFilterDate() }),
          ...(filter.value.to && { [`endDate`]: dayjs(filter.value.to).toServerFilterDate() }),
          [`timeZone`]: filter.value.timezone,
        };
      }
      if (filter.field === 'search' && typeof filter.value === 'string') {
        return { ...acc, search: filter.value };
      }
      // Legacy next TODO - remove once be sure that it's not used
      if (typeof filter.value === 'object' && !Array.isArray(filter.value)) {
        return {
          ...acc,
          [`${filter.field}_from`]: [filter.value.from?.toISOString() ?? ''],
          [`${filter.field}_to`]: [filter.value.to?.toISOString() ?? ''],
        };
      }
      if (Array.isArray(filter.value) && filter.value.length === 0 && removeEmpty) {
        return { ...acc };
      }
      if (typeof filter.value === 'string' && filter.value.length === 0 && removeEmpty) {
        return { ...acc };
      }
      if (typeof filter.value === 'string') {
        return { ...acc, [`${filter.field}`]: filter.value };
      }
      return { ...acc, [`${filter.field}_in`]: filter.value };
    },
    {},
  );
}

type Options = {
  removeEmpty?: boolean;
};

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useLocationStore } from '@libs/store/locations';
import { Shift } from '@libs/models/shifts';
import { FormSelect } from '@molecules/form';

export type LocationFormData = {
  location: string;
  department: string;
  subDepartment: string;
  speciality: string;
};

type LocationSelectionSectionProps = {
  localizationSubset: string;
  loading?: boolean;
  status?: Shift['status'];
};

export function LocationSelectionSection({
  localizationSubset,
  loading = false,
  status,
}: LocationSelectionSectionProps) {
  const intl = useIntl();
  const { locations, loadLocations, current: currentLocation, reloadCurrentLocation } = useLocationStore();
  const { loadDepartment, loadSubDepartment, currentSubDepartmentDetails, currentDepartmentDetails, clearDetails } =
    useOrgDepartmentsStore();

  const [subDepartmentsOptions, setSubDepartmentsOptions] = useState<{ key: string; value: string; label: string }[]>(
    [],
  );
  const [orgSpecialtiesOptions, setOrgSpecialtiesOptions] = useState<{ key: string; value: string; label: string }[]>(
    [],
  );

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  useEffect(() => {
    reloadCurrentLocation(true); // silently reload current location
  }, [reloadCurrentLocation]);

  const { watch, setValue } = useFormContext<LocationFormData>();
  const department = watch('department');
  const subDepartment = watch('subDepartment');

  const departments = currentLocation?.structure ?? [];
  const realDepartments = departments.filter(
    (dep) => dep.type === 'department' && currentLocation?.usedDepartmentNodes.includes(dep.id),
  );
  const departmentsOptions = realDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  const hasStatus = !!status;
  const disableDepartment = loading || departmentsOptions.length === 1 || hasStatus;
  const disableSubDepartment = loading || !department || !subDepartmentsOptions.length || hasStatus;
  const disableSpecialty = loading || !orgSpecialtiesOptions.length || hasStatus;

  useEffect(() => {
    if (departmentsOptions.length === 1 && department !== departmentsOptions[0].value) {
      setValue('department', departmentsOptions[0].value);
    }
  }, [departmentsOptions, department, setValue]);

  useEffect(() => {
    if (department) {
      setValue('subDepartment', '');
      setValue('speciality', '');
      loadDepartment(department);
    }
  }, [department]);

  const departmentRecord = realDepartments.find((dep) => dep.id === department);
  useEffect(() => {
    if (departmentRecord) {
      setSubDepartmentsOptions(
        [
          {
            key: 'empty',
            label: intl.formatMessage({ id: `common.selectNone` }),
            value: '',
          },
        ].concat(
          (departmentRecord.children || []).map((dep) => ({
            key: dep.id,
            label: dep.label,
            value: dep.id,
          })),
        ),
      );
    }
  }, [department, intl, departmentRecord]);

  useEffect(() => {
    if (subDepartment) {
      setValue('speciality', '');
      loadSubDepartment(subDepartment);
    }
  }, [subDepartment]);

  useEffect(() => {
    const specList = [
      ...(currentDepartmentDetails?.specialties || []),
      ...(currentSubDepartmentDetails?.specialties || []),
    ].filter((spec, index, self) => index === self.findIndex((s) => s.id === spec.id));
    setOrgSpecialtiesOptions(
      [
        {
          key: 'empty',
          label: intl.formatMessage({ id: `common.selectNone` }),
          value: '',
        },
      ].concat(
        specList.map((spec) => ({
          key: spec.id,
          label: spec.name,
          value: spec.id,
        })),
      ),
    );
  }, [currentDepartmentDetails, currentSubDepartmentDetails, intl]);

  useEffect(() => {
    return () => {
      clearDetails();
    };
  }, [clearDetails]);

  const locationOptions = locations.map((l) => ({
    key: l.id,
    label: l.name,
    value: l.id,
  }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <FormSelect
            // We choose location in the header
            disabled
            label={<FormattedMessage id={`${localizationSubset}.location.locationName.label`} />}
            placeholder={intl.formatMessage({
              id: `${localizationSubset}.location.locationName.placeholder`,
            })}
            fullWidth
            name="location"
            options={locationOptions}
            required
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            disabled={disableDepartment}
            label={<FormattedMessage id={`${localizationSubset}.location.department.label`} />}
            labelTooltip={<FormattedMessage id={`${localizationSubset}.location.department.labelTooltip`} />}
            // defaultValue={departmentsOptions.length === 1 ? departmentsOptions[0].value : ''}
            placeholder={intl.formatMessage({
              id: `${localizationSubset}.location.department.placeholder`,
            })}
            fullWidth
            required
            name="department"
            options={departmentsOptions}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            disabled={disableSubDepartment}
            label={<FormattedMessage id={`${localizationSubset}.location.subDepartment.label`} />}
            placeholder={intl.formatMessage({
              id: !department
                ? `${localizationSubset}.location.subDepartment.placeholder`
                : !subDepartmentsOptions.length
                  ? `${localizationSubset}.location.subDepartment.emptyPlaceholder`
                  : `${localizationSubset}.location.subDepartment.placeholder`,
            })}
            toolTipLabel={
              !department || !subDepartmentsOptions.length
                ? intl.formatMessage({
                    id: !department
                      ? `${localizationSubset}.location.subDepartment.departmentIsNotSelectedTooltip`
                      : !subDepartmentsOptions.length
                        ? `${localizationSubset}.location.subDepartment.emptyToolTip`
                        : '',
                  })
                : null
            }
            fullWidth
            name="subDepartment"
            options={subDepartmentsOptions}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            disabled={disableSpecialty}
            label={<FormattedMessage id={`${localizationSubset}.location.specialty.label`} />}
            placeholder={intl.formatMessage({
              id: !department
                ? `${localizationSubset}.location.specialty.placeholder`
                : !orgSpecialtiesOptions.length
                  ? `${localizationSubset}.location.specialty.emptyPlaceholder`
                  : `${localizationSubset}.location.specialty.placeholder`,
            })}
            toolTipLabel={
              !department || !orgSpecialtiesOptions.length
                ? intl.formatMessage({
                    id: !department
                      ? `${localizationSubset}.location.subDepartment.departmentIsNotSelectedTooltip`
                      : !orgSpecialtiesOptions.length
                        ? `${localizationSubset}.location.specialty.emptyToolTip`
                        : '',
                  })
                : null
            }
            fullWidth
            name="speciality"
            options={orgSpecialtiesOptions}
          />
        </Grid>
      </Grid>
    </>
  );
}

import { forwardRef } from 'react';
import MuiAvatar, { AvatarProps as OriginalAvatarProps } from '@mui/material/Avatar';
import { getFullNameAbbr } from '../../../libs/utils/string';

export interface AvatarProps extends OriginalAvatarProps {
  fullName?: string;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
  { src, children, fullName, ...props },
  ref,
) {
  return (
    <MuiAvatar src={src} {...props} ref={ref}>
      {fullName ? getFullNameAbbr(fullName) : children}
    </MuiAvatar>
  );
});

import { create } from 'zustand';
import { getTalents, GetTalentsParams } from '@libs/api/talents';
import { TalentListItem } from '@libs/models/talents';
import { Filter } from '@libs/models/common';
import dayjs from 'dayjs';

type InitialState = {
  loading: boolean;
  initiated: boolean;
  page: number;
  total: number;
  perPage: number;
  load: (params?: GetTalentsParams) => Promise<void>;
  talents: TalentListItem[];
  currentSearch: string;
  currentFilters: {
    field: string;
    value: Filter['value'];
  }[];
};

export const useShiftDrawerTalents = create<InitialState>()((set, get) => ({
  loading: false,
  initiated: false,
  currentSearch: '',
  currentFilters: [],
  page: 1,
  perPage: 10,
  total: 0,
  talents: [],
  load: async (params) => {
    set({
      loading: true,
    });
    const state = get();
    try {
      const { data } = await getTalents({
        page: state.page,
        size: state.perPage,
        ...params,
      });
      set({
        talents: data.items.map((t) => ({
          ...t,
          lastWorkedTime: dayjs(t.lastWorkedTime),
        })),
        total: data.totalCount,
        page: data?.pageNumber ?? 1,
        perPage: data?.pageSize ?? 10,
        initiated: true,
        currentSearch: params?.search ?? '',
        currentFilters: params?.filters ?? [],
        loading: false,
      });
    } finally {
      set({
        loading: false,
      });
    }
  },
}));

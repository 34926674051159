import { create } from 'zustand';
import { StaffingPartnerSimple, StaffingPartnerSimpleListItem } from '@libs/models/staffingPartners/staffingPartners';
import { fetchExternalStaffingPartnersList, inviteAgency, inviteNewAgency } from '@libs/api/staffingPartners';

type InitialState = {
  open: boolean;

  externalStaffingPartnersLoading: boolean;
  externalStaffingPartners: StaffingPartnerSimpleListItem[];
  loadExternalStaffingPartners: () => Promise<void>;
  connectAgency: (agencyId: string, locationIds: string[]) => Promise<void>;
  inviteNewAgency: (data: StaffingPartnerSimple) => Promise<void>;

  openInviteAgencyPopup: () => void;
  closeInviteAgencyPopup: () => void;
};

export const useUSeInviteAgencyDrawerStore = create<InitialState>()((set) => ({
  open: false,

  externalStaffingPartners: [],
  externalStaffingPartnersLoading: false,

  loadExternalStaffingPartners: async () => {
    set({ externalStaffingPartnersLoading: true });
    const externalStaffingPartners = await fetchExternalStaffingPartnersList();
    set({ externalStaffingPartners, externalStaffingPartnersLoading: false });
  },

  connectAgency: async (agencyId: string, locationIds: string[]) => {
    await inviteAgency(agencyId, locationIds);
  },
  inviteNewAgency: async (data: StaffingPartnerSimple) => {
    await inviteNewAgency(data);
  },

  openInviteAgencyPopup: () => {
    set({ open: true });
  },
  closeInviteAgencyPopup: () => {
    set({ open: false });
  },
}));

import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Fade, IconButton, Popover, Stack, Typography, useTheme } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { styled } from '@mui/material/styles';
import { DateHelper } from '@bryntum/schedulerpro';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useLocationStore } from '@libs/store/locations';
import { useShiftsFilter } from '@libs/store/shifts/shiftsFilter';
import { useTalentsStore } from '@libs/store/talents';
import { CalendarShiftsFilterMenu } from '@organisms/shifts/filterMenu';
import { ActiveFilters } from '@molecules/dataDisplay';
import { FilterItem } from '@molecules/dataDisplay/activeFilters';
import { FilterMultiSelect, renderCheckboxOption } from '@atoms/inputs';
import { Button, MenuSplitButton } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';

type CalendarFilterBarProps = {
  loading?: boolean;
  startDate: Date;
  endDate: Date;
  weekStartDay: number;
  onToday: () => void;
  onWeekBack: () => void;
  onWeekForward: () => void;
  onNewShift: () => void;
  onNewSeries: () => void;
  onNewRotation: () => void;
  onListRefresh: () => void;
  onPublish: () => void;
  onShare: () => void;
  onClearFilters: () => void;
  onDateRangeChange?: (start: Dayjs, end: Dayjs) => void;
};

export function CalendarFilterBar({
  loading = false,
  startDate,
  endDate,
  weekStartDay,
  onToday,
  onWeekBack,
  onWeekForward,
  onNewShift,
  onNewSeries,
  onNewRotation,
  onListRefresh,
  onPublish,
  onShare,
  onClearFilters,
  onDateRangeChange,
}: CalendarFilterBarProps) {
  const theme = useTheme();
  const intl = useIntl();
  const { current: currentLocation } = useLocationStore();

  const { activeFilters, clearFilter, getFilter, setFilterFromOptions, loadData } = useShiftsFilter();
  const { departments, loadTree } = useOrgDepartmentsStore();
  const { load: loadTalents } = useTalentsStore();

  useEffect(() => {
    if (!departments.length) {
      loadTree();
    }
  }, [loadTree, departments]);

  useEffect(() => {
    if (currentLocation) {
      loadData({
        locationId: currentLocation.id,
      });
    }
  }, [loadData, currentLocation]);

  const statusFilterOptions = departments.map((department) => ({
    label: department.label,
    value: department.id,
  }));

  const calendarFilters = activeFilters.reduce((acc, f) => {
    if (f.key === 'locations' || f.key === 'startDate') {
      return acc;
    }
    acc.push({
      ...f,
      label: <FormattedMessage id={`shifts.fields.${f.key}`} />,
    });
    return acc;
  }, [] as FilterItem[]);

  const handleClearFilters = () => {
    onClearFilters && onClearFilters();
  };

  const handleDateRangeChanged = (start: Dayjs, end: Dayjs) => {
    onDateRangeChange && onDateRangeChange(start, end);
  };

  useEffect(() => {
    loadTalents({});
  }, [loadTalents]);

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack
        direction={'row'}
        spacing={2}
        sx={(theme) => ({
          width: '100%',
          height: '40px',
          paddingLeft: theme.spacing(1),
        })}
      >
        <WeekPicker
          dateStart={dayjs(startDate)}
          dateEnd={dayjs(endDate)}
          onDateRangeChange={handleDateRangeChanged}
          weekStartDay={weekStartDay}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            backgroundColor: 'white',
            color: loading ? theme.palette.text.disabled : theme.palette.text.primary,
          }}
          onClick={() => {
            onToday && onToday();
          }}
        >
          <FormattedMessage id="shifts.switchForTodayButton.label" />
        </Button>
        <IconButton
          aria-label="week back"
          size="small"
          disabled={loading}
          onClick={() => {
            onWeekBack && onWeekBack();
          }}
        >
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="week forward"
          size="small"
          disabled={loading}
          onClick={() => {
            onWeekForward && onWeekForward();
          }}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
        <Stack
          direction={'row'}
          spacing={2}
          sx={(theme) => ({
            // height: '40px',
          })}
        >
          <FilterMultiSelect
            size="small"
            options={statusFilterOptions}
            inlineLabel
            disabled={loading}
            label={`${intl.formatMessage({ id: 'filters.departments.label' })}:`}
            onApply={(v) => setFilterFromOptions('departments', v, statusFilterOptions)}
            onClear={() => clearFilter('departments')}
            value={getFilter<string[]>('departments')?.value ?? []}
            renderOption={renderCheckboxOption}
          />
          <CalendarShiftsFilterMenu loading={loading} />
          <Fade in={calendarFilters.length > 0} timeout={150}>
            <Button
              variant="passive"
              onClick={() => handleClearFilters()}
              disabled={loading}
              startIcon={<FaIcon name="xmark" />}
              sx={{ ml: 'auto' }}
            >
              <FormattedMessage id="common.clearAllFilters" />
            </Button>
          </Fade>
        </Stack>
        <Box flex={1} />
        <Button
          variant="text"
          disabled={loading}
          onClick={() => {
            onShare && onShare();
          }}
        >
          <FormattedMessage id="shifts.shareShiftButton.label" />
        </Button>
        <Button
          variant="outlined"
          disabled={loading}
          onClick={() => {
            onPublish && onPublish();
          }}
        >
          <FormattedMessage id="shifts.publishShiftButton.label" />
        </Button>
        <MenuSplitButton
          disabled={loading}
          onMainClick={() => {
            onNewShift && onNewShift();
          }}
          label={intl.formatMessage({ id: 'shifts.newShiftButton.label' })}
          menuItems={[
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.singleShift.label' }),
              onAction: () => {
                onNewShift && onNewShift();
              },
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newSeries.label' }),
              onAction: () => {
                onNewSeries && onNewSeries();
              },
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newRotation.label' }),
              onAction: () => {
                onNewRotation && onNewRotation();
              },
            },
          ]}
        />
      </Stack>
      <Box>
        <ActiveFilters
          filters={calendarFilters}
          onDelete={(item) => {
            onListRefresh && onListRefresh();
            clearFilter(item.key);
          }}
        />
      </Box>
    </Stack>
  );
}

type WeekPickerProps = {
  dateStart: Dayjs;
  dateEnd: Dayjs;
  weekStartDay: number;
  onDateRangeChange: (start: Dayjs, end: Dayjs) => void;
};

function WeekPicker({ dateStart, dateEnd, onDateRangeChange, weekStartDay }: WeekPickerProps) {
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(dateStart));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (value) {
      const startDate = dayjs(DateHelper.startOf(value.toDate(), 'week', undefined, weekStartDay));
      const endDate = dayjs(
        DateHelper.add(DateHelper.startOf(value.toDate(), 'week', undefined, weekStartDay), 7, 'days'),
      );
      onDateRangeChange(startDate, endDate);
    }
  }, [value]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Typography
        sx={{
          paddingTop: '8px',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <FaIcon name="calendar-lines" fontSize={24} />
      </Typography>
      <Typography variant="h5" sx={{ paddingTop: '6px', cursor: 'pointer', minWidth: '180px' }} onClick={handleClick}>
        {dateStart.format('MMMM YYYY')}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={(theme) => ({
            padding: theme.spacing(1),
          })}
        >
          <DateCalendar
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              handleClose();
            }}
            showDaysOutsideCurrentMonth
            disableHighlightToday
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) => ({
                selectedDay: value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
          />
        </Box>
      </Popover>
    </>
  );
}

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 0,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.primary.dark,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
  }),
  ':first-of-type': {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ':last-of-type': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

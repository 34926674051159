import { Box, Flex } from '@atoms/layout';
import { ComingSoonBanner } from './comingSoonBanner';

type ComingSoonSpacerProps = {
  disableContent: boolean;
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | null;
};
export function ComingSoonSpacer({ disableContent = true, children }: ComingSoonSpacerProps) {
  return disableContent ? (
    <>
      <Flex
        alignCenter
        justifyCenter
        column
        textAlign="center"
        sx={{
          height: '100%',
          minHeight: '230px',
          width: '100%',
          position: 'absolute',
          backdropFilter: 'blur(5px)',
          zIndex: 1,
        }}
      >
        <ComingSoonBanner />
      </Flex>
      <Box
        sx={(theme) => ({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          opacity: 0.6,
        })}
      >
        {children}
      </Box>
    </>
  ) : (
    children
  );
}

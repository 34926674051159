import { PropsWithChildren, ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { RequiredAsterisk } from './requiredAsterisk';
import { Box, Flex } from '../layout';
import { FaIcon } from '../icons';

export type FormLabelProps = TypographyProps & {
  disabled?: boolean;
  required?: boolean;
  tooltip?: ReactNode;
};
export function FormLabel({ disabled, children, required, tooltip }: PropsWithChildren<FormLabelProps>) {
  const theme = useTheme();
  const asterisk = required ? <RequiredAsterisk /> : '';
  return (
    <Flex alignItems="center" gap={1}>
      <Typography variant="subtitle2" color={disabled ? theme.palette.text.disabled : undefined}>
        {children} {asterisk}
      </Typography>
      {tooltip ? <LabelTooltip tooltip={tooltip} /> : null}
    </Flex>
  );
}

function LabelTooltip({ tooltip }: { tooltip: ReactNode }) {
  return (
    <Tooltip placement="right" arrow title={tooltip}>
      <Box>
        <FaIcon name="circle-info" />
      </Box>
    </Tooltip>
  );
}

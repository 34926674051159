import { Qualification, QualificationSettings } from '@libs/models/settings';
import { request } from '../../request';

export async function saveOrgQualifications(setting: QualificationSettings): Promise<void> {
  await request.put('/v2/settings/qualifications', {
    nurses: setting.direct,
    other: setting.other,
  });
}

export async function getOrgQualifications(): Promise<QualificationSettings> {
  const response = await request.get<{ nurses: Qualification[]; other: Qualification[] }>(
    '/v2/settings/qualifications',
  );

  return {
    direct: response.data.nurses,
    other: response.data.other,
  };
}

export async function getDirectQualifications(): Promise<Qualification[]> {
  const response = await request.get<Qualification[]>('/v2/nurses/qualifications');
  return response.data;
}

import { useEffect } from 'react';
import { useTalentsStore } from '@libs/store/talents';
import { ShiftDetailsDrawer, ShiftsFilters, ShiftTableMenu } from '@organisms/shifts';
import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { SeriesNRotationsTable } from '@organisms/dataDisplay/seriesNRotationsTable';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { EditShiftDrawer } from '../calendar/drawers/editShift';
import { ShiftInProgressDrawer } from '../calendar/drawers/shiftInProgress';
import { EditSeriesDrawer } from '../calendar/drawers/editSeries';
import { EditRotationDrawer } from '../calendar/drawers/editRotation';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShareShifts } from '../calendar/drawers/shareShifts';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { useLocationStore } from '@libs/store/locations';
import { useRotationsListStore } from '@libs/store/shifts/rotationsList';
import { PublishShiftsDrawer } from '../calendar/drawers/publishShifts';
import { usePublishDrawerStore } from '@libs/store/shifts/shiftPublishStore';
import { useRefreshSyncStore } from '@libs/store/refreshSync';

export function RotationsListPage() {
  const { refreshTrigger, callRefresh } = useRefreshSyncStore();
  const { drawerOpen: seriesDrawerOpen } = useShiftSeriesAddEditStore();
  const { drawerOpen: rotationDrawerOpen } = useShiftRotationAddEditStore();
  const { drawerOpen: inProgressDrawerOpen } = useShiftInProgressStore();
  const { drawerOpen: shiftEditDrawerOpen } = useShiftAddEditStore();
  const { drawerOpen: shareDrawerOpen } = useShareDrawerStore();
  const { drawerOpen: publishDrawerOpen } = usePublishDrawerStore();
  const { initiated: talentsListInitiated, load } = useTalentsStore();
  const { initiated: qualListInitiated } = useOrgQualificationsStore();
  const { currentInitiated: currentLocationInitiated, currentLocationID } = useLocationStore();
  const { initiated: rotationsListInitiated, reloadCurrentFilter: reloadLists } = useRotationsListStore();

  const loading = !rotationsListInitiated || !qualListInitiated || !currentLocationInitiated || !talentsListInitiated;

  useEffect(() => {
    const filters = [];
    filters.push({ field: 'locations', value: [currentLocationID] });
    load({
      filters,
      page: 1,
      size: 20,
    });
  }, [load]);

  useEffect(() => {
    if (!currentLocationID) {
      // wait for location to be loaded and then - refresh
      return;
    }
    callRefresh();
  }, [callRefresh, currentLocationID]);

  useEffect(() => {
    reloadLists();
  }, [refreshTrigger]);

  const handleListPossiblyChanged = () => {
    callRefresh();
  };

  return (
    <>
      <ShiftTableMenu loading={loading} />
      <PrimaryPaper sx={{ height: '100%', minHeight: 0, mt: 2 }}>
        <Flex column height="100%">
          <ShiftsFilters loading={loading} />
          <Box mt={2} height="100%" minHeight={0}>
            <SeriesNRotationsTable />
          </Box>
        </Flex>
      </PrimaryPaper>
      <ShiftDetailsDrawer />
      {shiftEditDrawerOpen && <EditShiftDrawer onSaveCall={handleListPossiblyChanged} />}
      {inProgressDrawerOpen && <ShiftInProgressDrawer onSaveCall={handleListPossiblyChanged} />}
      {seriesDrawerOpen && <EditSeriesDrawer onSaveCall={handleListPossiblyChanged} />}
      {rotationDrawerOpen && <EditRotationDrawer onSaveCall={handleListPossiblyChanged} />}
      {publishDrawerOpen && <PublishShiftsDrawer onSaveCall={handleListPossiblyChanged} />}
      {shareDrawerOpen && <ShareShifts onSaveCall={handleListPossiblyChanged} />}
    </>
  );
}

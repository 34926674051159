import { useIntl } from 'react-intl';
import { Divider, PrimaryDialog, PrimaryDialogProps } from '@atoms/layout';

import { FormattedMessage } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';
import { styled, useTheme } from '@mui/material/styles';
import { paperClasses } from '@mui/material/Paper';
import { Avatar, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useChatwootSDK } from '@molecules/supportChat';
import { useDreeBetaStore } from '@libs/store/freeBeta';

type WelcomeDialogProps = Omit<PrimaryDialogProps, 'onSubmit' | 'open'> & {
  open?: boolean;
};
export function WelcomeDialog({ open, ...props }: WelcomeDialogProps) {
  const { welcomeDialogOpen } = useDreeBetaStore();

  return (
    <DialogStyled fullWidth open={open !== undefined ? open : welcomeDialogOpen} {...props}>
      <Content onCloseClick={() => props.onClose?.({}, 'escapeKeyDown')} />
    </DialogStyled>
  );
}

const DialogStyled = styled(PrimaryDialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    bgcolor: theme.palette.background.paper,
    width: '640px',
  },
}));

const CONTACTS = [
  {
    avatarLink: '',
    contactName: 'Oleksandra Matkovska',
    phone: '(415) 336-8953',
    email: 'oleksandra.matkovska@shiftnex.com',
  },
  {
    avatarLink: '',
    contactName: 'Allan Njoroge',
    phone: '(254) 768-6120',
    email: 'a8@shiftnex.com',
  },
];

type ContentProps = {
  onCloseClick: () => void;
};
function Content({ onCloseClick }: ContentProps) {
  const intl = useIntl();
  const theme = useTheme();
  const SDK = useChatwootSDK();
  const { setWelcomeDialogOpen } = useDreeBetaStore();
  const localizationSubSet = 'license.freeBeta.popup';

  const handleKnowledgeBaseAction = () => {
    window.open(
      'https://platformprodstorage30824.blob.core.windows.net/public-files/Beta%20Instructions.pdf',
      '_blank',
    );
  };
  const handleSupportChatAction = () => {
    // SDK.openChatWindow();
    SDK.toggleOpenChatWindow();
    handleOnClose();
  };

  const handleOnClose = () => {
    setWelcomeDialogOpen(false);
    onCloseClick && onCloseClick();
  };

  return (
    <Stack direction="column" spacing={2}>
      <DialogTitle sx={{ px: 3, pt: 4, pb: 2 }}>
        <Flex justifyContent="space-between">
          <Flex column gap={1}>
            <Typography variant="h5">
              <FormattedMessage id={`${localizationSubSet}.heading`} />
            </Typography>
          </Flex>
          <Box>
            <IconButton sx={{ width: '40px', position: 'relative', top: -5, right: -5 }} onClick={handleOnClose}>
              <FaIcon name="xmark" variant="light" />
            </IconButton>
          </Box>
        </Flex>
        <Typography variant="subtitle1">
          <FormattedMessage id={`${localizationSubSet}.headingSubtitle`} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <Sect
            icon={
              <FaIcon
                name="book-sparkles"
                variant="duotone"
                color={theme.palette.primary.main}
                width={35}
                height={35}
                fontSize={32}
              />
            }
            headingLabel={<FormattedMessage id={`${localizationSubSet}.sects.knowledgeBase.heading`} />}
            subtitleLabel={<FormattedMessage id={`${localizationSubSet}.sects.knowledgeBase.headingSubtitle`} />}
            onClick={handleKnowledgeBaseAction}
          />
          <Sect
            icon={
              <FaIcon
                name="messages-question"
                variant="duotone"
                color={theme.palette.primary.main}
                width={35}
                height={35}
                fontSize={32}
              />
            }
            headingLabel={<FormattedMessage id={`${localizationSubSet}.sects.supportChat.heading`} />}
            subtitleLabel={<FormattedMessage id={`${localizationSubSet}.sects.supportChat.headingSubtitle`} />}
            onClick={handleSupportChatAction}
          />
        </Stack>
        <Divider />
        <Typography variant="caption">
          <FormattedMessage id={`${localizationSubSet}.contact.heading`} />
        </Typography>
        <Stack direction={'row'} spacing={3} sx={{ mt: 1 }}>
          {CONTACTS.map((data) => (
            <ContactSect {...data} />
          ))}
        </Stack>
        <Divider />
        <Link to="/licensing/terms-and-conditions">
          <FormattedMessage id={`${localizationSubSet}.links.termsNConditions`} />
        </Link>
        <Link to="/licensing/privacy-policy">
          <FormattedMessage id={`${localizationSubSet}.links.privacyPolicy`} />
        </Link>
        <Link to="https://platformprodstorage30824.blob.core.windows.net/public-files/ShiftNex%20Closed%20Beta%20Agreement_%20Version%2025.1.pdf">
          <FormattedMessage id={`${localizationSubSet}.links.aggreement`} />
        </Link>
        <Link to="/terms">
          <FormattedMessage id={`${localizationSubSet}.links.hipaa`} />
        </Link>
        <Link to="/terms">
          <FormattedMessage id={`${localizationSubSet}.links.ccpa`} />
        </Link>
      </DialogContent>
    </Stack>
  );
}

type LinkProps = {
  to: string;
  target?: string;
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[];
};
function Link({ to, target = '_blank', children }: LinkProps) {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Typography variant="body2" component={'span'} color={theme.palette.primary.main} sx={{ marginRight: '8px' }}>
      <NavLink to={to} target={target}>
        {children}
      </NavLink>
    </Typography>
  );
}

type SectProps = {
  onClick: () => void;
  headingLabel: React.ReactNode;
  subtitleLabel?: React.ReactNode;
  icon: React.ReactNode;
};
function Sect({ onClick, headingLabel, icon, subtitleLabel }: SectProps) {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={(theme) => ({
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme.palette.action.hover,
          borderRadius: theme.spacing(1),
          transition: 'background-color 0.3s',
        },
      })}
      onClick={onClick}
    >
      <Stack direction={'column'}>
        <Box flex={1} />
        {icon}
        <Box flex={1} />
      </Stack>
      <Stack direction={'column'}>
        <Typography variant="subtitle2">{headingLabel}</Typography>
        {subtitleLabel && <Typography variant="body2">{subtitleLabel}</Typography>}
      </Stack>
      <Box flex={1} />
      <Stack direction={'column'}>
        <Box flex={1} />
        <FaIcon name="angle-right" />
        <Box flex={1} />
      </Stack>
    </Stack>
  );
}

type ContactSectProps = {
  avatarLink: string;
  contactName: string;
  phone: string;
  email: string;
};
function ContactSect({ avatarLink, contactName, phone, email }: ContactSectProps) {
  return (
    <Stack direction={'column'} spacing={2}>
      <Stack direction={'row'} spacing={1}>
        <Avatar
          sx={{
            width: 32,
            height: 32,
          }}
          src={avatarLink}
          alt={avatarLink}
        />
        <Stack direction={'column'}>
          <Box flex={1} />
          <Typography variant="subtitle2">
            <FormattedMessage id={contactName} />
          </Typography>
          <Box flex={1} />
        </Stack>
      </Stack>
      {/* <Stack direction={'row'} spacing={2} sx={{ paddingLeft: '8px' }}> */}
      <Stack direction={'row'} spacing={1} sx={{ paddingLeft: '8px' }}>
        <FaIcon name="phone" />
        <Typography variant="body2">
          <FormattedMessage id={phone} />
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={1} sx={{ paddingLeft: '8px' }}>
        <FaIcon name="envelope" />
        <Typography variant="body2">
          <FormattedMessage id={email} />
        </Typography>
      </Stack>
      {/* </Stack> */}
    </Stack>
  );
}

import { useMemo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { useShiftAddEditStore, useShiftsFilter, useShiftsListStore } from '@libs/store/shifts';
import { useRefreshSyncStore } from '@libs/store/refreshSync';
import { useLocationStore } from '@libs/store/locations';
import { ShiftOld } from '@libs/models/shifts/shift';
import { ShiftsIcon } from '@atoms/icons';
import { EmptyListPlaceholder, isDateSeparator, separatorClass } from '@molecules/dataDisplay';
import { DataGrid, DateSeparator } from '@molecules/dataDisplay';
import { GridSortModel, GridSortItem } from '@molecules/dataDisplay/dataGrid';
import { useShiftListColumns } from './shifts/columns';
import { outputShiftDataToConsole } from '@pages/schedule/helpers';

export function ShiftsTable() {
  const { refreshTrigger } = useRefreshSyncStore();
  const {
    data,
    loadShifts,
    loading: shiftsListLoading,
    initiated: shiftsListInitiated,
    total,
    page,
    perPage,
  } = useShiftsListStore();
  const { openShiftDrawer } = useShiftAddEditStore();
  const { activeFilters } = useShiftsFilter();
  const rows = useRowsGrouped(data);
  const { current: currentLocation } = useLocationStore();
  const { sortModel, setSortModel, sortFilter } = useSortHandler();

  const loading = shiftsListLoading || !shiftsListInitiated;

  useEffect(() => {
    if (!currentLocation) {
      // Location is required for shifts list, waiting till location is loaded
      return;
    }
    const filters = [...activeFilters];
    filters.push({ key: 'locations', value: [currentLocation.id] });
    filters.push(sortFilter);
    console.log('filters', filters);
    loadShifts(1, 10, filters, currentLocation.timezone);
  }, [loadShifts, activeFilters, refreshTrigger, sortFilter, currentLocation]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        loading={loading}
        rowHeight={64}
        columns={useShiftListColumns()}
        sortModel={sortModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        onSortModelChange={setSortModel}
        paginationModel={{
          pageSize: perPage,
          page: page,
        }}
        rowCount={total}
        onPaginationModelChange={(params) => {
          const filters = [...activeFilters];
          if (currentLocation) {
            filters.push({ key: 'locations', value: [currentLocation.id] });
          }
          filters.push(sortFilter);
          loadShifts(params.page, params.pageSize, filters, currentLocation?.timezone);
        }}
        pageSizeOptions={[10, 20]}
        disableRowSelectionOnClick
        getRowHeight={({ model }) => {
          if (model.type === 'separator') {
            return 44;
          }

          return null;
        }}
        getRowClassName={(params) => {
          if (isDateSeparator(params.row)) {
            return separatorClass;
          }
          return '';
        }}
        noRowsOverlay={
          <EmptyListPlaceholder
            icon={<ShiftsIcon active />}
            title={<FormattedMessage id="shifts.table.noRows.title" />}
            subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
          />
        }
        onRowClick={(params, evt) => {
          if(evt.ctrlKey){
            outputShiftDataToConsole(params.row);
          }
          openShiftDrawer(`${params.id}`);
        }}
      />
    </Box>
  );
}

function useRowsGrouped(data: ShiftOld[]): (ShiftOld | DateSeparator)[] {
  return useMemo(() => {
    const groupedData: (ShiftOld | DateSeparator)[] = [];
    data.forEach((shift, index) => {
      const lastItem = data[index - 1];
      const isSameDay = dayjs(shift.shiftStart).isSame(dayjs(lastItem?.shiftStart), 'day');
      if (lastItem && isSameDay) {
        groupedData.push(shift);
      } else {
        groupedData.push({
          id: shift.shiftStart.getTime().toString(),
          type: 'separator',
          date: shift.shiftStart,
        });
        groupedData.push(shift);
      }
    });

    return groupedData;
  }, [data]);
}

function useSortHandler() {
  const [sortModel, setSortModel] = useState<GridSortModel>([defaultDateSortModel]);
  const sortFilter = useMemo(
    () => ({
      key: 'orderBy',
      value: sortModel.map((p) => `${propToFilterMap[p.field] ?? p.field}_${p.sort}`),
    }),
    [sortModel],
  );

  return { sortModel, sortFilter, setSortModel };
}

const defaultDateSortModel: GridSortItem = {
  field: 'shiftStart',
  sort: 'asc',
};

const propToFilterMap: Record<string, string> = {
  shiftStart: 'startDate',
};

import { forwardRef } from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { FormLabel } from './formLabel';

export type TextFieldProps = MuiTextFieldProps;
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(function TextField(
  { label, fullWidth, ...props },
  ref,
) {
  const { id, required, disabled } = props;

  return (
    <label id={id ? `${id}-label` : undefined} htmlFor={id} style={{ width: fullWidth ? '100%' : undefined }}>
      <FormLabel disabled={disabled} required={required} sx={{ width: fullWidth ? '100%' : undefined }}>
        {label}
      </FormLabel>
      <MuiTextField {...props} inputRef={ref} fullWidth={fullWidth}/>
    </label>
  );
});

import { FormattedMessage, useIntl } from 'react-intl';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useStaffingTypeFilterMenu(): FilterItem {
  const intl = useIntl();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('staffingtypes');

  return {
    label: <FormattedMessage id="shifts.fields.staffingtypes" />,
    key: 'staffingtypes',
    valueToLabel: (value) =>
      value.map((v) => intl.formatMessage({ id: `shifts.stuffingType.${v.toLowerCase()}` })).join(', '),
    children: [
      {
        label: <FormattedMessage id="shifts.stuffingType.internal" />,
        key: 'Internal',
      },
      {
        label: <FormattedMessage id="shifts.stuffingType.external" />,
        key: 'External',
      },
    ].map((item) => ({
      ...item,
      selected: filter?.value.includes(item.key) || false,
    })),
  };
}

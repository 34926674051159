import { create } from 'zustand';
import {
  createOrgSpecialty,
  getOrgSpecialties,
  getOrgSpecialty,
  getPredefinedSpecialties,
  updateOrgSpecialties,
} from '@libs/api/settings';
import { Specialty } from '@libs/models/settings';

type InitialState = {
  loading: boolean;
  predefinedSpecialtiesLoading: boolean;
  currentSpecialtyLoading: boolean;
  initiated: boolean;
  editSpecialtyDrawerOpen: boolean;
  predefinedSpecialties: Specialty[];
  orgSpecialties: Specialty[];
  allSpecialties: Specialty[];
  currentSpecialtyId: string | null;
  currentSpecialty: Specialty | null;
  page: number;
  total: number;
  perPage: number;
  createSpecialty: (settings: Specialty) => Promise<void>;
  updateSpecialty: (settings: Specialty) => Promise<void>;
  loadSpecialtiesList: (page?: number, perPage?: number) => Promise<void>;
  loadSpecialty: (id: string) => Promise<void>;
  loadPredefinedSpecialties: () => Promise<void>;
  setEditDrawerOpen: (isOpen: boolean) => void;
  openEditSpecialty: (id: string) => void;
  closeEditSpecialty: () => void;
};

export const useOrgSpecialtiesStore = create<InitialState>()((set, get) => ({
  loading: false,
  predefinedSpecialtiesLoading: false,
  currentSpecialtyLoading: false,
  initiated: false,
  editSpecialtyDrawerOpen: false,
  currentSpecialtyId: null,
  currentSpecialty: null,
  predefinedSpecialties: [],
  allSpecialties: [],
  orgSpecialties: [],
  page: 0,
  perPage: 10,
  total: 0,
  createSpecialty: async (specialty) => {
    if (!specialty) return;
    const result = await createOrgSpecialty(specialty);
    const { allSpecialties } = get();
    // Refresh allSpecialties with new id
    set({
      allSpecialties: allSpecialties.reduce<Specialty[]>((acc, elem) => {
        elem.name === specialty.name
          ? acc.push({
              ...specialty,
              id: result.data.id,
            })
          : acc.push(elem);
        return acc;
      }, []),
    });
  },
  updateSpecialty: async (specialty) => {
    if (!specialty) return;
    await updateOrgSpecialties(specialty.id, specialty);
  },
  loadSpecialtiesList: async (page, perPage) => {
    set({ loading: true });
    try {
      const resp = await getOrgSpecialties();
      set({
        loading: false,
        orgSpecialties: resp.data,
        total: resp.total,
        page,
        perPage,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  loadPredefinedSpecialties: async () => {
    const { orgSpecialties } = get();
    set({ predefinedSpecialtiesLoading: true });
    try {
      // [...new Set([...selectedId, ...action.payload])]
      const resp = await getPredefinedSpecialties();
      set({
        predefinedSpecialtiesLoading: false,
        predefinedSpecialties: resp,
        allSpecialties: resp.reduce<Specialty[]>((acc, elem) => {
          const spec = orgSpecialties.find((el) => el.name === elem.name);
          if (spec) {
            acc.push(spec);
          } else {
            acc.push(elem);
          }
          return acc;
        }, []),
      });
    } finally {
      set({ predefinedSpecialtiesLoading: false, initiated: true });
    }
  },
  loadSpecialty: async (id: string) => {
    set({ currentSpecialtyLoading: true });
    try {
      const resp = await getOrgSpecialty(id);
      set({
        currentSpecialty: resp,
      });
    } finally {
      set({ currentSpecialtyLoading: false, initiated: true });
    }
  },
  openEditSpecialty: (id: string) => {
    set({ editSpecialtyDrawerOpen: true, currentSpecialtyId: id });
  },

  closeEditSpecialty: () => {
    set({ editSpecialtyDrawerOpen: false, currentSpecialty: null });
  },

  setEditDrawerOpen: (isOpen) => {
    set({ editSpecialtyDrawerOpen: !!isOpen });
  },
}));

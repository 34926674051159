import { FormattedMessage, useIntl } from 'react-intl';
import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { Stack, styled } from '@mui/material';
import { useShareDrawerStore, useShiftAddEditStore, useShiftsAssignment } from '@libs/store/shifts';
import { SingleShiftEditData } from '@libs/api/shifts/create';
import { useLocationStore } from '@libs/store/locations';
import { useNotification } from '@libs/snackbar';
import { SingleShift } from '@libs/models/shifts/shift';
import { parseError } from '@libs/api/errors';
import { Analytics } from '@libs/analytics/amplitude';
import { Button } from '@atoms/buttons';
import { Box } from '@atoms/layout';
import { CancelShiftButton } from './cancelShiftButton';
import { ShiftFormData } from '../shiftFormData';

type EditShiftControlsProps = {
  onUpdated?: () => void;
  onClose?: () => void;
  loading?: boolean;
  shiftInternalId?: string;
  shiftStatus?: string;
  formMethods: UseFormReturn<ShiftFormData, unknown, undefined>;
};

export function EditShiftControls({
  onUpdated,
  onClose,
  shiftInternalId,
  shiftStatus,
  loading = false,
  formMethods,
}: EditShiftControlsProps) {
  const intl = useIntl();
  const { showSuccessIntl, showError } = useNotification();

  const { shiftId, drawerMode, createShift, updateShift, publishShift } = useShiftAddEditStore();
  const { deleteShift } = useShiftsAssignment();
  const { openShiftShareDrawer } = useShareDrawerStore();
  const { locations } = useLocationStore();

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addShiftDrawer' : 'shifts.editShiftDrawer';
  const showCopy = !isAddDialog && STATUSES_ALLOWED_FOR_COPY.includes(shiftStatus ?? '');
  const showDelete = !isAddDialog && STATUSES_ALLOWED_FOR_DELETE.includes(shiftStatus ?? '');
  const showCancel = !isAddDialog && STATUSES_ALLOWED_FOR_CANCEL.includes(shiftStatus ?? '');
  const showShare = STATUSES_ALLOWED_FOR_SHARE.includes(shiftStatus ?? '');

  const { setError, formState } = formMethods;
  const isDirty = formState.isDirty;

  const handleSave = async () => {
    await formMethods.handleSubmit(handleSubmit.bind(handleSubmit, false))();
    onUpdated && onUpdated();
  };

  const handlePublish = async () => {
    await formMethods.handleSubmit(handleSubmit.bind(handleSubmit, true))();
    onUpdated && onUpdated();
  };

  const handleSubmit = async (
    isPublish: boolean,
    {
      department,
      qualifications,
      speciality,
      location,
      tags,
      notes,
      startDate: startDay,
      startTime,
      endTime,
      subDepartment,
      unassignReasonSelect,
      unassignReasonNote,
      ...data
    }: ShiftFormData,
  ) => {
    if (!startDay || !startTime || !endTime) {
      throw new Error('Please select start and end time');
    }

    if (data.assignees.length > data.numberOfCopies) {
      setError('assignees', {
        type: 'manual',
        message: intl.formatMessage({ id: 'shifts.editShiftDrawer.errorNumberOfAssignees' }),
      });
      return;
    }
    const dayOfStart = dayjs.utc(startDay.format('YYYY-MM-DDT00:00:00.000+00:00'));

    const startDate = dayjs(dayOfStart)
      .set('hour', startTime.get('hour'))
      .set('minute', startTime.get('minute'))
      .set('second', startTime.get('second'));

    let endDate = dayjs(startDate)
      .set('hour', endTime.get('hour'))
      .set('minute', endTime.get('minute'))
      .set('second', endTime.get('second'));

    if (+startDate.toDate() > +endDate.toDate()) {
      endDate = endDate.add(1, 'day');
    }

    const durationInSeconds = endDate.diff(startDate, 'seconds');

    const locationDetails = locations.find((loc) => loc.id === location);

    const tagsParsed = (tags || '').split(',').map((tag) => tag.trim());

    const sendingData: SingleShiftEditData = {
      ...data,
      startDate: startDate.toServerDateTimeFormat(),
      durationInSeconds,
      timeZone: locationDetails!.timeZone,
      staffOtherQualificationFilterIds: [],
      staffNurseQualificationFilterIds: qualifications,
      departmentNodeId: department,
      subDepartmentNodeId: subDepartment,
      specialityId: speciality,
      locationId: location,
      tags: tagsParsed,
      note: notes || '',
      isNeedToPublish: isPublish,
      unassignReason: unassignReasonSelect !== 'other' ? unassignReasonSelect : unassignReasonNote,
    };

    try {
      if (isAddDialog) {
        await createShift(sendingData);
      } else {
        await updateShift(shiftId, sendingData);
      }
      if (!isAddDialog && isPublish) {
        await publishShift(shiftId);
        Analytics.track('Shift: Published', {
          shift_id: shiftInternalId,
          provider_id: data.assignees.join(', '),
          provider_qualification: qualifications.join(', '),
          shift_specialty: speciality,
          shift_location: location,
          shift_department: department,
        });
      }

      onClose?.();
    } catch (error) {
      if (error instanceof Error) {
        showError(intl.formatMessage({ id: `shifts.editShiftDrawer.error.${parseError(error).message}` }));
      }
    }
  };

  const handleDelete = () => {
    deleteShift(shiftId)
      .then(() => {
        onClose?.();
        onUpdated?.();
        showSuccessIntl('shifts.deleteSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };

  const handleCopy = () => {
    const values = formMethods.getValues();
    if (!validateForm(values)) {
      return;
    }

    const locationDetails = locations.find((loc) => loc.id === values.location);
    if (!locationDetails) {
      showError('Location not found');
      return;
    }

    const data = prepareFormToSave(locationDetails.timeZone, false, values);

    createShift({
      ...data,
      // Clear assignees when coping
      assignees: [],
    })
      .then(() => {
        showSuccessIntl('shifts.copySuccessful');
        onClose?.();
        onUpdated?.();
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };

  const validateForm = (form: ShiftFormData) => {
    const { startDate, startTime, endTime, assignees, numberOfCopies } = form;
    if (!startDate || !startTime || !endTime) {
      showError('Please select start and end time');
      return false;
    }

    if (assignees.length > numberOfCopies) {
      setError('assignees', {
        type: 'manual',
        message: intl.formatMessage({ id: 'shifts.editShiftDrawer.errorNumberOfAssignees' }),
      });
      return false;
    }

    return true;
  };

  const isAllowedToPublish = isAddDialog
    ? true
    : SHIFT_STATUSES_ALLOWED_TO_PUBLISH.includes((shiftStatus ?? '').toLowerCase());

  return (
    <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
      {isAllowedToPublish && (
        <MenuButton variant="outlined" onClick={handlePublish} disabled={loading}>
          <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
        </MenuButton>
      )}
      {(isDirty || isAddDialog) && (
        <MenuButton variant="text" onClick={handleSave} disabled={loading || formState.isSubmitting}>
          <FormattedMessage
            id={
              isAllowedToPublish
                ? `${localizationSubset}.menuButtons.saveDraft.label`
                : `${localizationSubset}.menuButtons.save.label`
            }
          />
        </MenuButton>
      )}
      {!isDirty && (
        <>
          {showShare && (
            <MenuButton
              variant="contained"
              onClick={() =>
                openShiftShareDrawer({
                  preselectedShifts: [shiftId],
                })
              }
              disabled={loading}
              sx={{ minWidth: '143px' }}
            >
              <FormattedMessage id={`${localizationSubset}.menuButtons.share.label`} />
            </MenuButton>
          )}
          {showCopy && (
            <MenuButton variant="text" onClick={handleCopy} disabled={loading}>
              <FormattedMessage id={`${localizationSubset}.menuButtons.copy.label`} />
            </MenuButton>
          )}
          {showDelete && (
            <MenuButton variant="text" color={'error'} onClick={handleDelete} disabled={loading}>
              <FormattedMessage id={`${localizationSubset}.menuButtons.delete.label`} />
            </MenuButton>
          )}
          {showCancel && shiftId && (
            <CancelShiftButton disabled={loading} shiftId={shiftId} onReasonDialogClose={onClose}>
              <FormattedMessage id={`${localizationSubset}.menuButtons.cancel.label`} />
            </CancelShiftButton>
          )}
        </>
      )}
      <Box flex={1} />
      <MenuButton onClick={onClose} sx={(theme) => ({ color: theme.palette.text.secondary })} disabled={loading}>
        <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
      </MenuButton>
    </Stack>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  // minWidth: theme.spacing(16.75),
}));

function prepareFormToSave(timeZone: string, isPublish: boolean, form: ShiftFormData): SingleShift {
  const {
    department,
    qualifications,
    speciality,
    location,
    tags,
    notes,
    startDate: startDay,
    startTime,
    endTime,
    subDepartment,
    ...data
  } = form;
  if (!startDay || !startTime || !endTime) {
    throw new Error('Please select start and end time');
  }

  const dayOfStart = dayjs.utc(startDay.format('YYYY-MM-DDT00:00:00.000+00:00'));

  const startDate = dayjs(dayOfStart)
    .set('hour', startTime.get('hour'))
    .set('minute', startTime.get('minute'))
    .set('second', startTime.get('second'));

  let endDate = dayjs(startDate)
    .set('hour', endTime.get('hour'))
    .set('minute', endTime.get('minute'))
    .set('second', endTime.get('second'));

  if (+startDate.toDate() > +endDate.toDate()) {
    endDate = endDate.add(1, 'day');
  }

  const durationInSeconds = endDate.diff(startDate, 'seconds');
  const tagsParsed = (tags || '').split(',').map((tag) => tag.trim());

  return {
    ...data,
    startDate: startDate.toServerDateTimeFormat(),
    durationInSeconds,
    timeZone: timeZone,
    staffOtherQualificationFilterIds: [],
    staffNurseQualificationFilterIds: qualifications,
    departmentNodeId: department,
    subDepartmentNodeId: subDepartment,
    specialityId: speciality,
    locationId: location,
    tags: tagsParsed,
    note: notes || '',
    isNeedToPublish: isPublish,
  };
}

const SHIFT_STATUSES_ALLOWED_TO_PUBLISH = ['opened', 'expired'];
const STATUSES_ALLOWED_FOR_COPY = ['Opened', 'Unassigned', 'Assigned', 'Pending', 'Canceled'];
const STATUSES_ALLOWED_FOR_DELETE = ['Opened'];
const STATUSES_ALLOWED_FOR_CANCEL = ['Unassigned', 'Assigned', 'Pending', 'InProgress', 'Unfilled'];
const STATUSES_ALLOWED_FOR_SHARE = ['Pending'];

import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { capitalize } from '@mui/material/utils';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTalentsStore } from '@libs/store/talents';
import { getShiftType, ShiftOld } from '@libs/models/shifts/shift';
import { FormAutocomplete } from '@molecules/form';
import { Form } from '@molecules/form/form';
import { Box, Flex, PrimaryDialog, PrimaryDialogProps } from '@atoms/layout';
import { LoadingButton, Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { prepareTalentsOptions } from './helpers';
import { FormEventHandler, useEffect, useState } from 'react';
import { useLocationStore } from '@libs/store/locations';

type AssignDialogProps = Omit<PrimaryDialogProps, 'onSubmit'> & {
  shift: ShiftOld;
  onSubmit?: (talent: string) => void;
  loading?: boolean;
};
export function AssignDialog({ shift, onSubmit, loading, ...props }: AssignDialogProps) {
  return (
    <PrimaryDialog fullWidth maxWidth={'xs'} {...props}>
      <Content
        shift={shift}
        onCloseClick={() => props.onClose?.({}, 'escapeKeyDown')}
        onSubmit={onSubmit}
        loading={loading}
      />
    </PrimaryDialog>
  );
}

type ContentProps = {
  shift: ShiftOld;
  onCloseClick: () => void;
  onSubmit?: (talent: string) => void;
  loading?: boolean;
};
function Content({ shift, onCloseClick, onSubmit, loading = false }: ContentProps) {
  const intl = useIntl();
  const shiftType = getShiftType(dayjs(shift?.shiftStart));
  const to = dayjs(shift?.shiftStart).add(shift?.shiftLength ?? 0, 'millisecond');
  const { talents, load } = useTalentsStore();
  const { currentLocationID } = useLocationStore();

  const selectedQualifications = (shift?.qualifications || []).map((q) => q.id) ?? [];
  const talentsOptions = prepareTalentsOptions(talents, selectedQualifications);

  const methods = useForm<AssignForm>({
    defaultValues: {
      talent: '',
    },
  });

  const [assigneeValue, setAssigneeValue] = useState<string>('');

  useEffect(() => {
    const filters = [];
    filters.push({ field: 'locations', value: [currentLocationID] });
    filters.push({ field: 'qualifications', value: shift.qualifications.map((q) => q.id) });
    load({
      search: assigneeValue,
      filters,
      page: 1,
      size: 20,
    });
  }, [assigneeValue, load]);

  const handleAssigneeChange: FormEventHandler<HTMLDivElement> = (evt) => {
    const target = evt.target as HTMLInputElement;
    setAssigneeValue(target.value);
  };

  return (
    <>
      <Form formUtils={methods} onSubmit={(v) => onSubmit?.(v.talent)}>
        <DialogTitle sx={{ px: 3, pt: 4, pb: 2 }}>
          <Flex justifyContent="space-between">
            <Flex column gap={1}>
              <Typography variant="h5">
                <FormattedMessage id="shifts.assignDialog.title" />
              </Typography>
            </Flex>
            <Box>
              <IconButton sx={{ width: '40px', position: 'relative', top: -5, right: -5 }} onClick={onCloseClick}>
                <FaIcon name="xmark" variant="light" />
              </IconButton>
            </Box>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" noWrap>
            <FormattedMessage
              id="shifts.assignDialog.shiftDetails"
              values={{
                period: `${intl.formatTime(shift?.shiftStart, { hour: 'numeric' })} - ${intl.formatTime(to.toDate(), { hour: 'numeric' })}`,
                length: intl.formatMessage(
                  { id: 'shifts.length' },
                  {
                    length: Math.round((shift?.shiftLength ?? 0) / 1000 / 60 / 60),
                  },
                ),
                type: capitalize(shiftType),
                qualifications: shift?.qualifications.map((qual) => qual.abbreviation).join(', '),
                assignee: shift?.assignee?.name,
              }}
            />
          </Typography>

          <Flex mt={2}>
            <FormAutocomplete
              name="talent"
              fullWidth
              options={talentsOptions}
              placeholder={intl.formatMessage({ id: 'shifts.assignDialog.placeholder' })}
              required
              onInput={handleAssigneeChange}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  <Flex justifyContent="space-between" alignCenter width="100%">
                    <Typography>{option.label}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {(option.qualifications || []).map((q) => q.abbreviation).join(', ')}
                    </Typography>
                  </Flex>
                </li>
              )}
            />
          </Flex>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, pt: 0 }}>
          <Box color="text.secondary">
            <Button variant="text" color="inherit" rounded onClick={onCloseClick} size="large">
              <FormattedMessage id="common.cancel" />
            </Button>
          </Box>
          <LoadingButton loading={loading} variant="contained" rounded type="submit" size="large">
            <FormattedMessage id="common.submit" />
          </LoadingButton>
        </DialogActions>
      </Form>
    </>
  );
}

type AssignForm = {
  talent: string;
};

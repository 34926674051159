import { Qualification, Specialty } from '@libs/models/settings';
import { request } from '../../request';

type SpeckList = {
  data: (Specialty & { nurseQualifications: Qualification[] })[];
  total: number;
};

export async function getOrgSpecialties(): Promise<{ data: Specialty[]; total: number }> {
  const response = await request.get<SpeckList>('/v2/settings/specialities');
  const specList: Specialty[] = response.data.data.map(
    (data) =>
      ({
        category: data.category,
        id: data.id,
        name: data.name,
        directQualifications: data.nurseQualifications,
        otherQualifications: data.otherQualifications,
      }) as Specialty,
  );

  return {
    data: specList,
    total: response.data.total,
  };

  // const mock: Specialty[] = [
  //   {
  //     id: 'test_id1',
  //     name: 'Acute Care',
  //     localizationKey: 'acute_care',
  //     category: 'nursing_specialties',
  //     qualifications: [
  //       {
  //         id: 'test_qual_1',
  //         category: 'nursing_and_patient_care',
  //         name: 'Certified Nursing Assistant',
  //         abbreviation: 'CNA',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'test_id2',
  //     name: 'Ambulatory Care',
  //     localizationKey: 'ambulatory_care',
  //     category: 'nursing_specialties',
  //     qualifications: [
  //       {
  //         id: 'test_qual_1',
  //         category: 'nursing_and_patient_care',
  //         name: 'Certified Nursing Assistant',
  //         abbreviation: 'CNA',
  //       },
  //       {
  //         id: 'test_qual_2',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Practical Nurse',
  //         abbreviation: 'LPN',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'test_id3',
  //     name: 'Behavioral Health',
  //     localizationKey: 'behavioral_health',
  //     category: 'nursing_specialties',
  //     qualifications: [
  //       {
  //         id: 'test_qual_1',
  //         category: 'nursing_and_patient_care',
  //         name: 'Certified Nursing Assistant',
  //         abbreviation: 'CNA',
  //       },
  //       {
  //         id: 'test_qual_2',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Practical Nurse',
  //         abbreviation: 'LPN',
  //       },
  //       {
  //         id: 'test_qual_3',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Vocational Nurse',
  //         abbreviation: 'LVN',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'test_id4',
  //     name: 'BSCU (Burns Specialty Care)',
  //     localizationKey: 'bscy_(burns_specialty_care)',
  //     category: 'nursing_specialties',
  //     qualifications: [
  //       {
  //         id: 'test_qual_1',
  //         category: 'nursing_and_patient_care',
  //         name: 'Certified Nursing Assistant',
  //         abbreviation: 'CNA',
  //       },
  //       {
  //         id: 'test_qual_2',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Practical Nurse',
  //         abbreviation: 'LPN',
  //       },
  //       {
  //         id: 'test_qual_3',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Vocational Nurse',
  //         abbreviation: 'LVN',
  //       },
  //       {
  //         id: 'test_qual_4',
  //         category: 'nursing_and_patient_care',
  //         name: 'Registered Nurse',
  //         abbreviation: 'RN',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'test_id5',
  //     name: 'Cardiology',
  //     localizationKey: 'cardiology',
  //     category: 'nursing_specialties',
  //     qualifications: [
  //       {
  //         id: 'test_qual_1',
  //         category: 'nursing_and_patient_care',
  //         name: 'Certified Nursing Assistant',
  //         abbreviation: 'CNA',
  //       },
  //       {
  //         id: 'test_qual_2',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Practical Nurse',
  //         abbreviation: 'LPN',
  //       },
  //       {
  //         id: 'test_qual_3',
  //         category: 'nursing_and_patient_care',
  //         name: 'Licensed Vocational Nurse',
  //         abbreviation: 'LVN',
  //       },
  //       {
  //         id: 'test_qual_4',
  //         category: 'nursing_and_patient_care',
  //         name: 'Registered Nurse',
  //         abbreviation: 'RN',
  //       },
  //       {
  //         id: 'test_qual_5',
  //         category: 'nursing_and_patient_care',
  //         name: 'Nurse Practitioner',
  //         abbreviation: 'NP',
  //       },
  //     ],
  //   },
  // ];

  // const fake = async () =>
  //   new Promise<{ data: Specialty[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: mock, total: 5 });
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getPredefinedSpecialties(): Promise<Specialty[]> {
  const response = await request.get<Specialty[]>('/v2/nurses/specialities');

  return response.data;

  // const mock: Specialty[] = [
  //   // Nursing specialties:
  //   {
  //     id: 'test_id1',
  //     name: 'Acute Care',
  //     localizationKey: 'acute_care',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id2',
  //     name: 'Ambulatory Care',
  //     localizationKey: 'ambulatory_care',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id3',
  //     name: 'Behavioral Health',
  //     localizationKey: 'behavioral_health',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id4',
  //     name: 'BSCU (Burns Specialty Care)',
  //     localizationKey: 'bscy_(burns_specialty_care)',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id5',
  //     name: 'Cardiology',
  //     localizationKey: 'cardiology',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id6',
  //     name: 'Emergency Services',
  //     localizationKey: 'emergency_services',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id7',
  //     name: 'Intensive Care Unit (ICU)',
  //     localizationKey: 'intensive_care_unit_(icu)',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id8',
  //     name: 'Maternity Ward',
  //     localizationKey: 'maternity_ward',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id9',
  //     name: 'Neonatal Intensive Care Unit (NICU)',
  //     localizationKey: 'neonatal_intensive_care_unit_(nicu)',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id10',
  //     name: 'Oncology',
  //     localizationKey: 'oncology',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id11',
  //     name: 'Orthopedics',
  //     localizationKey: 'orthopedics',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id12',
  //     name: 'Pediatrics',
  //     localizationKey: 'pediatrics',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id13',
  //     name: 'Rehabilitation',
  //     localizationKey: 'rehabilitation',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id14',
  //     name: 'Surgical Services',
  //     localizationKey: 'surgical_services',
  //     category: 'nursing_specialties',
  //     qualifications: [],
  //   },

  //   // Medical specialties:
  //   {
  //     id: 'test_id15',
  //     name: 'Cardiology',
  //     localizationKey: 'cardiology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id16',
  //     name: 'Dermatology',
  //     localizationKey: 'dermatology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id17',
  //     name: 'Endocrinology',
  //     localizationKey: 'endocrinology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id18',
  //     name: 'Gastroenterology',
  //     localizationKey: 'gastroenterology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id19',
  //     name: 'Hematology',
  //     localizationKey: 'hematology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id20',
  //     name: 'Infectious Disease',
  //     localizationKey: 'infectious_disease',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id21',
  //     name: 'Internal Medicine',
  //     localizationKey: 'internal_medicine',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id22',
  //     name: 'Nephrology',
  //     localizationKey: 'nephrology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id23',
  //     name: 'Neurology',
  //     localizationKey: 'neurology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id24',
  //     name: 'Oncology',
  //     localizationKey: 'oncology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id25',
  //     name: 'Pediatrics',
  //     localizationKey: 'pediatrics',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id26',
  //     name: 'Psychiatry',
  //     localizationKey: 'psychiatry',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id27',
  //     name: 'Pulmonology',
  //     localizationKey: 'pulmonology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id28',
  //     name: 'Rheumatology',
  //     localizationKey: 'rheumatology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id29',
  //     name: 'Urology',
  //     localizationKey: 'urology',
  //     category: 'medical_specialties',
  //     qualifications: [],
  //   },

  //   // Surgical specialties:
  //   {
  //     id: 'test_id30',
  //     name: 'General Surgery',
  //     localizationKey: 'general_surgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id31',
  //     name: 'Neurosurgery',
  //     localizationKey: 'neurosurgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id32',
  //     name: 'Orthopedic Surgery',
  //     localizationKey: 'orthopedic_surgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id33',
  //     name: 'Cardiothoracic Surgery',
  //     localizationKey: 'cardiothoracic_surgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id34',
  //     name: 'Plastic Surgery',
  //     localizationKey: 'plastic_surgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id35',
  //     name: 'Vascular Surgery',
  //     localizationKey: 'vascular_surgery',
  //     category: 'surgical_specialties',
  //     qualifications: [],
  //   },
  //   // Pediatric specialties:
  //   {
  //     id: 'test_id36',
  //     name: 'Pediatric Cardiology',
  //     localizationKey: 'pediatric_cardiology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id37',
  //     name: 'Pediatric Endocrinology',
  //     localizationKey: 'pediatric_endocrinology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id38',
  //     name: 'Pediatric Gastroenterology',
  //     localizationKey: 'pediatric_gastroenterology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id39',
  //     name: 'Pediatric Hematology/Oncology',
  //     localizationKey: 'pediatric_hematology_oncology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id40',
  //     name: 'Pediatric Neurology',
  //     localizationKey: 'pediatric_neurology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id41',
  //     name: 'Pediatric Pulmonology',
  //     localizationKey: 'pediatric_pulmonology',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id42',
  //     name: 'Pediatric Surgery',
  //     localizationKey: 'pediatric_surgery',
  //     category: 'pediatric_specialties',
  //     qualifications: [],
  //   },
  //   // Other common specialties:
  //   {
  //     id: 'test_id43',
  //     name: 'Anesthesiology',
  //     localizationKey: 'anesthesiology',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id44',
  //     name: 'Obstetrics and Gynecology (OB/GYN)',
  //     localizationKey: 'obstetrics_and_gynecology_(OB/GYN)',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id45',
  //     name: 'Ophthalmology',
  //     localizationKey: 'ophthalmology',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id46',
  //     name: 'Otolaryngology (ENT)',
  //     localizationKey: 'otolaryngology_(ENT)',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id47',
  //     name: 'Pathology',
  //     localizationKey: 'pathology',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id48',
  //     name: 'Radiology',
  //     localizationKey: 'radiology',
  //     category: 'other_common_specialties',
  //     qualifications: [],
  //   },
  //   // Rehabilitation specialties:
  //   {
  //     id: 'test_id49',
  //     name: 'Physical Medicine and Rehabilitation',
  //     localizationKey: 'physical_medicine_and_rehabilitation',
  //     category: 'rehabilitation_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id50',
  //     name: 'Sports Medicine',
  //     localizationKey: 'sports_medicine',
  //     category: 'rehabilitation_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id51',
  //     name: 'Occupational Therapy',
  //     localizationKey: 'occupational_therapy',
  //     category: 'rehabilitation_specialties',
  //     qualifications: [],
  //   },
  //   // Emergency and critical care specialties:
  //   {
  //     id: 'test_id52',
  //     name: 'Emergency Medicine',
  //     localizationKey: 'emergency_medicine',
  //     category: 'emergency_and_critical_care_specialties',
  //     qualifications: [],
  //   },
  //   {
  //     id: 'test_id53',
  //     name: 'Critical Care Medicine',
  //     localizationKey: 'critical_care_medicine',
  //     category: 'emergency_and_critical_care_specialties',
  //     qualifications: [],
  //   },
  //   // Geriatrics:
  //   {
  //     id: 'test_id54',
  //     name: 'Geriatric Medicine',
  //     localizationKey: 'geriatric_medicine',
  //     category: 'geriatrics',
  //     qualifications: [],
  //   },
  // ];

  // const fake = async () =>
  //   new Promise<Specialty[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock);
  //     }, 1000);
  //   });

  // return await fake();
}

export async function updateOrgSpecialties(id: string, setting: Specialty): Promise<void> {
  const response = await request.patch(`/v2/settings/specialities/${id}`, {
    title: setting.name,
    category: setting.category,
    nurseQualificationIds: setting.directQualifications.map((elem) => elem.id),
    otherQualificationIds: setting.otherQualifications.map((elem) => elem.id),
  });

  // const fake = async () =>
  //   new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getOrgSpecialty(id: string): Promise<Specialty> {
  const response = await request.get(`/v2/settings/specialities/${id}`);
  const spec = response.data;
  return {
    id: spec.id,
    category: spec.category,
    name: spec.title,
    directQualifications: spec.nurseQualifications,
    otherQualifications: spec.otherQualifications,
  } as Specialty;
  // const fake = async () =>
  //   new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, 1000);
  //   });

  // return await fake();
}

export async function createOrgSpecialty(setting: Specialty) {
  return await request.post<{ id: string }>('/v2/settings/specialities', {
    title: setting.name,
    category: setting.category,
    nurseQualificationIds: setting.directQualifications.map((elem) => elem.id),
    otherQualificationIds: setting.otherQualifications.map((elem) => elem.id),
  });
}

import { useMemo } from 'react';
import { FormattedDate, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { Flex } from '@atoms/layout';
import { ShiftRecurrenceChip, ShiftStatusChip } from '@atoms/dataDisplay';
import { isDateSeparator } from '@molecules/dataDisplay';
import { ShiftList } from '@libs/models/shifts/shift';
import { Stack } from '@mui/material';
import { ShiftType } from '@organisms/dataDisplay/shifts';

export function useShiftListColumns() {
  const intl = useIntl();

  // Columns should be memoized to prevent table flickering and state loss
  return useMemo(() => {
    const columns: GridColDef<ShiftList>[] = [
      getStartDateColumn(intl),
      getShiftTypeColumn(intl, 'shifts.fields.shiftType'),
      getRecurrenceColumn(intl, 'shifts.fields.recurrence'),
      getStatusColumn(intl),
    ];
    return columns;
  }, [intl]);
}

function getShiftTypeColumn(intl: IntlShape, labelId: string): GridColDef<ShiftList> {
  return {
    field: 'shiftStart',
    headerName: intl.formatMessage({
      id: 'shifts.fields.shiftType',
    }),
    flex: 1,
    minWidth: 120,
    colSpan: (_, row) => {
      if (isDateSeparator(row)) {
        return 8;
      }
      return 1;
    },
    renderCell: (params) => {
      if (isDateSeparator(params.row)) {
        return (
          <Flex alignCenter height="100%" px={0.75}>
            <Typography variant="subtitle1">
              <FormattedDate value={params.row.date} dateStyle="full" />
            </Typography>
          </Flex>
        );
      }

      return (
        <ShiftType
          startTime={dayjs(params.row.startDate).tz(params.row.timeZone)}
          endTime={dayjs(params.row.endDate).tz(params.row.timeZone)}
          boxProps={{ height: '100%' }}
          timeDisplay="short"
        />
      );
    },
  };
}

function getStartDateColumn(intl: IntlShape): GridColDef<ShiftList> {
  return {
    field: 'none',
    headerName: intl.formatMessage({
      id: 'shifts.fields.date',
    }),
    flex: 1,
    minWidth: 80,
    renderCell: (params) => {
      if (!params.row) {
        return null;
      }
      const row = params.row;
      const formattedStart = dayjs(row.startDate).format('MM.DD.YYYY');
      const weekday = dayjs(row.startDate).format('dddd');
      return (
        <Stack direction={'column'} justifyContent={'center'} height={'100%'}>
          <Typography variant="body2">{formattedStart}</Typography>
          <Typography variant="caption">{weekday}</Typography>
        </Stack>
      );
    },
  };
}

function getRecurrenceColumn(intl: IntlShape, labelId: string): GridColDef<ShiftList> {
  return {
    field: 'recurrence',
    headerName: intl.formatMessage({
      id: labelId,
    }),
    flex: 1,
    minWidth: 80,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }

      return (
        <ShiftRecurrenceChip
          variant="outlined"
          type={params.value}
          label={<FormattedMessage id={`shifts.recurrence.${params.value}`} />}
          size="small"
        />
      );
    },
  };
}

function getStatusColumn(intl: IntlShape): GridColDef<ShiftList> {
  return {
    field: 'status',
    headerName: intl.formatMessage({ id: 'shifts.fields.status' }),
    flex: 1,
    minWidth: 80,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }

      return <ShiftStatusChip value={params.value} />;
    },
  };
}

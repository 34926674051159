import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';
import { useLocationStore } from '@libs/store/locations';
import { useShiftsFilter } from '@libs/store/shifts';
import { ActiveFilters } from '@molecules/dataDisplay';
import { FilterAutocomplete, FilterMultiSelect, SearchField, renderCheckboxOption } from '@atoms/inputs';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { StartDateRangeFilterInput } from './startDateRangeFilterInput';
import { ShiftsFilterMenu } from './filterMenu';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { useEffect } from 'react';
import { FilterItem } from '@molecules/dataDisplay/activeFilters';
import { TIMEZONE_FALLBACK } from '@libs/config';

type ShiftsFiltersProps = {
  loading?: boolean;
};

const FallbackTimezone = TIMEZONE_FALLBACK;

export function ShiftsFilters({ loading: upperLoading }: ShiftsFiltersProps) {
  const intl = useIntl();
  const {
    activeFilters,
    clearFilter,
    clearFilters,
    getFilter,
    setFilter,
    setFilterFromOptions,
    filterProviders,
    loadData,
  } = useShiftsFilter();
  const { loading: qualListLoading, orgQualifications, load: loadQualifications } = useOrgQualificationsStore();
  const { current: currentLocation, currentLocationID, loading: locationsLoading } = useLocationStore();

  const loading = upperLoading || locationsLoading || qualListLoading;

  const professionalsOptions = filterProviders
    .filter((t) => t.staffingStatus !== 'Deactivated')
    .map((talent) => ({
      label: [talent.firstName, talent.lastName].filter(Boolean).join(' '),
      value: talent.id,
      qualifications: talent.nurseQualifications.map((q) => q.name).join(', '),
    }));

  useEffect(() => {
    if (currentLocation) {
      loadData({
        locationId: currentLocation.id,
      });
    }
  }, [loadData, currentLocation]);

  useEffect(() => {
    if (!orgQualifications) {
      loadQualifications();
    }
  }, [loadQualifications, orgQualifications]);

  const qualificationsFilterOptions =
    orgQualifications?.direct.map((qual) => ({
      label: `${qual.abbreviation ? qual.abbreviation : qual.name}`,
      value: qual.id,
    })) || [];

  const handleClearFilters = () => {
    if (currentLocationID) {
      clearFilters();
      setFilter({ key: 'locations', value: [currentLocationID] });
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" gap={2}>
        <Flex gap={2} flexWrap="wrap">
          <Box width="220px">
            <StartDateRangeFilterInput loading={loading} timezone={currentLocation?.timezone || FallbackTimezone} />
          </Box>
          <Box width={220}>
            <FilterMultiSelect
              size="small"
              disabled={loading}
              options={qualificationsFilterOptions}
              inlineLabel
              label={`${intl.formatMessage({ id: 'common.qualification' })}:`}
              onApply={(v) => setFilterFromOptions('qualifications', v, qualificationsFilterOptions)}
              onClear={() => clearFilter('qualifications')}
              value={getFilter<string[]>('qualifications')?.value ?? []}
              fullWidth
              renderOption={renderCheckboxOption}
            />
          </Box>
          <Box width={220}>
            <FilterAutocomplete
              options={professionalsOptions}
              loading={loading}
              disabled={loading}
              label={intl.formatMessage({ id: 'fields.talents' })}
              fullWidth
              onClear={() => clearFilter('staff')}
              onApply={(v) => {
                setFilterFromOptions('staff', v, professionalsOptions);
              }}
              value={getFilter<string[]>('staff')?.value ?? []}
              renderLabel={(option) => (
                <Flex justifyContent="space-between" width="100%">
                  <Typography noWrap>{option.label}</Typography>
                  <Typography variant="caption" color="text.secondary" noWrap>
                    {professionalsOptions.find((o) => o.value === option.value)?.qualifications}
                  </Typography>
                </Flex>
              )}
            />
          </Box>
          <Box width={220}>
            <FilterMultiSelect
              size="small"
              options={statusesOptions}
              disabled={loading}
              inlineLabel
              label={`${intl.formatMessage({ id: 'fields.statuses' })}:`}
              fullWidth
              renderOption={renderCheckboxOption}
              onApply={(v) => setFilterFromOptions('statuses', v, statusesOptions)}
              onClear={() => clearFilter('statuses')}
              value={getFilter<string[]>('statuses')?.value ?? []}
            />
          </Box>
        </Flex>
        <SearchField
          disabled={loading}
          sx={{ width: 260, flexShrink: 0 }}
          onDebouncedChange={(v) => setFilter({ key: 'search', value: v })}
        />
      </Flex>
      <Flex mt={1} gap={1} flexWrap="wrap" alignCenter>
        <ShiftsFilterMenu loading={loading} timezone={currentLocation?.timezone} />
        <ActiveFilters
          filters={activeFilters.reduce((acc, f) => {
            if (f.key === 'locations') {
              return acc;
            }
            acc.push({
              ...f,
              label: <FormattedMessage id={`shifts.fields.${f.key}`} />,
            });
            return acc;
          }, [] as FilterItem[])}
          onDelete={(item) => clearFilter(item.key)}
        />
        <Fade in={activeFilters.length > 0} timeout={150}>
          <Button
            variant="passive"
            onClick={() => handleClearFilters()}
            startIcon={<FaIcon name="xmark" />}
            sx={{ ml: 'auto' }}
          >
            <FormattedMessage id="common.clearAllFilters" />
          </Button>
        </Fade>
      </Flex>
    </>
  );
}

const statusesOptions = [
  { label: 'Open', value: 'Opened' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Re-assigned', value: 'Reassigned' },

  { label: 'Canceled', value: 'Canceled' },
  { label: 'In progress', value: 'InProgress' },
  { label: 'Unfilled', value: 'Unfilled' },
  // { label: 'Deleted', value: 'Deleted' },
  { label: 'Expired', value: 'Expired' },
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Completed', value: 'Completed' },
];

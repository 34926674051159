import { FormattedMessage, useIntl } from 'react-intl';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useStatusFilterMenu(): FilterItem {
  const intl = useIntl();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('status');

  return {
    label: <FormattedMessage id="shifts.fields.status" />,
    key: 'status',
    multiple: true,
    valueToLabel: (value) => value.map((v) => intl.formatMessage({ id: `shifts.status.${v}` })).join(', '),
    children: [
      {
        label: <FormattedMessage id="shifts.status.opened" />,
        key: 'Opened',
      },
      {
        label: <FormattedMessage id="shifts.status.pending" />,
        key: 'Pending',
      },
      {
        label: <FormattedMessage id="shifts.status.assigned" />,
        key: 'Assigned',
      },
      {
        label: <FormattedMessage id="shifts.status.reassigned" />,
        key: 'Reassigned',
      },
      {
        label: <FormattedMessage id="shifts.status.canceled" />,
        key: 'Canceled',
      },
      {
        label: <FormattedMessage id="shifts.status.inprogress" />,
        key: 'InProgress',
      },
      {
        label: <FormattedMessage id="shifts.status.unfilled" />,
        key: 'Unfilled',
      },
      {
        label: <FormattedMessage id="shifts.status.deleted" />,
        key: 'Deleted'
      },
      {
        label: <FormattedMessage id="shifts.status.expired" />,
        key: 'Expired'
      },
      {
        label: <FormattedMessage id="shifts.status.unassigned" />,
        key: 'Unassigned'
      },
      {
        label: <FormattedMessage id="shifts.status.completed" />,
        key: 'Completed'
      },
    ].map((item) => ({
      ...item,
      selected: filter?.value.includes(item.key) || false,
    })),
  };
}

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { TalentListItem } from '@libs/models/talents';
import { FormAutocompleteMultiple, FormAutocompleteMultipleProps } from '@molecules/form/formAutocompleteMultiple';
import { Box, Flex } from '@atoms/layout';
import { prepareTalentsOptions } from '@organisms/shifts/helpers';
import { useShiftDrawerTalents } from '@libs/store/shifts';

export function AssigneesSelect({
  localizationSubset,
  name,
  selectedQualifications = [],
  numberOfCopies = 1,
  ...props
}: Omit<FormAutocompleteMultipleProps<string[]>, 'options' | 'name'> & {
  localizationSubset: string;
  name: string;
  numberOfCopies?: number;
  selectedQualifications?: string[];
}) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const selected = watch(name);
  const selectedNumber = selected?.length ?? 0;
  const { talents } = useShiftDrawerTalents();
  const options = prepareTalentsOptions(talents, selectedQualifications);
  const talentsById = makeTalentsDictionary(talents);

  return (
    <FormAutocompleteMultiple
      options={options}
      groupBy={(option) => intl.formatMessage({ id: `talents.selector.stuffingType.${option.groupBy ?? ''}` })}
      label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignees.label`} />}
      textFieldProps={{
        placeholder:
          selectedNumber === 0
            ? intl.formatMessage({
                id: `${localizationSubset}.talentConfiguration.assignees.placeholder`,
              })
            : undefined,
      }}
      disableCloseOnSelect
      name={name}
      getOptionDisabled={selectedNumber >= numberOfCopies ? () => true : undefined}
      // Trying to fit tags in one line
      limitTags={3}
      renderTags={(selected, getTagProps) =>
        selected.map((s, i) => (
          <Chip
            label={
              <Flex gap={0.5}>
                <Box>{s.label}</Box>
                <Box color="text.secondary">
                  {(talentsById[s.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}
                </Box>
              </Flex>
            }
            title={`${s.label} - ${(talentsById[s.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}`}
            {...getTagProps({ index: i })}
            key={s.value}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props}>
          <Flex width="100%" justifyContent="space-between">
            <Flex>{option.label}</Flex>
            <Typography variant="caption" color="text.secondary">
              {(talentsById[option.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}
            </Typography>
          </Flex>
        </li>
      )}
      {...props}
    />
  );
}

function makeTalentsDictionary(talents: TalentListItem[]) {
  return talents.reduce<Record<string, TalentListItem>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

import { request } from '../request';
import { ShiftRotationUpdate } from '@libs/models/shifts';

export async function createShiftRotation(data: ShiftRotationUpdate) {
  const response = request.post<void>('/v2/shifts/rotation', {
    ...data,
    specialityId: data.specialityId || null,
    subDepartmentNodeId: data.subDepartmentNodeId || null,
    departmentNodeId: data.departmentNodeId || null,
    startDate: { dateTime: data.startDate, timeZone: data.timeZone },
    endDate: { dateTime: data.endDate, timeZone: data.timeZone },
    dateTimeSlots: data.dateTimeSlots.map((slot) => ({
      startDate: { dateTime: slot.startDate, timeZone: data.timeZone },
      endDate: { dateTime: slot.endDate, timeZone: data.timeZone },
    })),

    tags: data.tags.reduce((acc, tag) => {
      if (tag) {
        acc.push(tag + '');
      }
      return acc;
    }, [] as string[]),
  });
  return response;
}

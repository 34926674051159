import { METHODS, LOG_LEVELS, LOG_LVELS_MAP } from './constants';
import { type Method, type LogLevel, type IRenderer } from './types';
import { DEFAULT_RENDERERS } from './renderers';

const defaults = {
  enabled: true,
  logLevel: LOG_LEVELS.LOG,
  renderers: [],
  tagline: [],
};

export class Logger {
  protected _enabled: boolean = defaults.enabled;
  protected _logLevel: LogLevel = defaults.logLevel;
  protected _renderers: IRenderer[] = [];
  protected _tagline: string[] = [];

  constructor(
    tagline: string[],
    enabled: boolean = defaults.enabled,
    logLevel: LogLevel = defaults.logLevel,
    renders: IRenderer[] = DEFAULT_RENDERERS,
  ) {
    this._enabled = enabled;
    this._logLevel = logLevel;
    this._tagline = tagline;
    this._renderers = renders;
  }

  tags(...rest: string[]) {
    return new Logger([...this._tagline, ...rest], this._enabled, this._logLevel, this._renderers);
  }

  debug(...rest: any[]) {
    this.shoudlWrite(METHODS.DEBUG) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.DEBUG, this._tagline, ...rest);
      });
  }

  log(...rest: any[]) {
    this.shoudlWrite(METHODS.LOG) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.LOG, this._tagline, ...rest);
      });
  }

  info(...rest: any[]) {
    this.shoudlWrite(METHODS.INFO) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.INFO, this._tagline, ...rest);
      });
  }

  warn(...rest: any[]) {
    this.shoudlWrite(METHODS.WARN) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.WARN, this._tagline, ...rest);
      });
  }

  error(...rest: any[]) {
    this.shoudlWrite(METHODS.ERROR) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.ERROR, this._tagline, ...rest);
      });
  }

  fatal(...rest: any[]) {
    this.shoudlWrite(METHODS.FATAL) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.FATAL, this._tagline, ...rest);
      });
  }

  critical(...rest: any[]) {
    this.shoudlWrite(METHODS.CRITICAL) &&
      this._renderers.forEach((render) => {
        render.write(METHODS.CRITICAL, this._tagline, ...rest);
      });
  }

  // the one - who is not dependant from the log level
  service(...rest: any[]) {
    this.isEnabled() &&
      this._renderers.forEach((render) => {
        render.write(METHODS.LOG, this._tagline, ...rest);
      });
  }

  protected shoudlWrite(method: Method) {
    return this.isEnabled() && this.isAllowed(method);
  }

  protected isEnabled() {
    return this._enabled;
  }

  protected isAllowed(method: Method): boolean {
    return (LOG_LVELS_MAP[this._logLevel] as string[]).includes(method as string);
  }
}

import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRulesToDefaultMessage, getErrorMessage } from '@libs/forms';
import { TextField, TextFieldProps } from '@atoms/inputs';

export type FormNumberFieldProps<T extends FieldValues> = Omit<TextFieldProps, 'name'> & {
  name: Path<T>;
  requiredMessage?: boolean;
  options?: RegisterOptions<T, Path<T>>;
  minValue?: number;
  maxValue?: number;
};
/**
 * Wrapper around `TextField` that integrates with react-hook-form.
 */
export function FormNumberField<T extends FieldValues>({
  name,
  required,
  requiredMessage,
  options,
  helperText,
  minValue = undefined,
  maxValue = undefined,
  ...props
}: FormNumberFieldProps<T>) {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();
  const error = errors[name];
  const parsedOptions = useRulesToDefaultMessage({
    ...options,
  });

  return (
    <TextField
      {...props}
      required={required}
      error={!!error}
      type="number"
      InputProps={{ inputProps: { min: minValue, max: maxValue } }}
      helperText={getErrorMessage(error) ? getErrorMessage(error) : helperText}
      {...register(name, {
        ...parsedOptions,
        ...(minValue
          ? {
              min: {
                value: minValue,
                message: intl.formatMessage({ id: 'forms.minValueError' }, { number: minValue }),
              },
            }
          : {}),
        ...(maxValue
          ? {
              max: {
                value: maxValue,
                message: intl.formatMessage({ id: 'forms.maxValueError' }, { number: maxValue }),
              },
            }
          : {}),
        ...(required
          ? {
              required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
            }
          : {}),
      })}
    />
  );
}

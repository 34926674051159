import { useShiftsFilter } from '@libs/store/shifts';
import { FilterMenuButton } from '@molecules/form/filterMenu';
import { useQualificationsFilterMenu } from './useQualificationsFilterMenu';
import { useProfessionalsFilterMenu } from './useProfessionalsFilterMenu';
import { useDateRangeFilterMenu } from './useDateRangeFilterMenu';
import { useSpecialtyFilterMenu } from './useSpecialtyFilterMenu';
import { useStatusFilterMenu } from './useStatusFilterMenu';
import { TIMEZONE_FALLBACK } from '@libs/config';
import { useStaffingTypeFilterMenu } from './useStaffingTypeFilterMenu';

type ShiftsFilterMenuProps = {
  mode?: 'list' | 'calendar';
  loading?: boolean;
  timezone?: string;
};

const FallbackTimezone = TIMEZONE_FALLBACK;

export function CalendarShiftsFilterMenu({ loading }: ShiftsFilterMenuProps) {
  const filterItem = [
    useQualificationsFilterMenu(),
    useSpecialtyFilterMenu(),
    useStatusFilterMenu(),
    useProfessionalsFilterMenu(),
    useStaffingTypeFilterMenu(),
  ];
  const { setFilter } = useShiftsFilter();

  return (
    <FilterMenuButton
      items={filterItem}
      loading={!!loading}
      onFilterApplied={(key, value, label) => {
        setFilter({
          key,
          value,
          valueView: label,
        });
      }}
    />
  );
}

export function ShiftsFilterMenu({ loading, timezone = FallbackTimezone }: ShiftsFilterMenuProps) {
  const filterItem = [
    useQualificationsFilterMenu(),
    useSpecialtyFilterMenu(),
    useStatusFilterMenu(),
    useProfessionalsFilterMenu(),
    useDateRangeFilterMenu({
      timezone,
    }),
  ];
  const { setFilter } = useShiftsFilter();

  return (
    <FilterMenuButton
      items={filterItem}
      loading={!!loading}
      onFilterApplied={(key, value, label) => {
        setFilter({
          key,
          value,
          valueView: label,
        });
      }}
    />
  );
}

import { Qualification } from '@libs/models/talents';
import { Filter, PaginatedResponse } from '@libs/models/common';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { request } from '../request';

export type GetTalentsParams = {
  orderBy?: { field: string; sort: string }[];
  search?: string;
  page?: number;
  size?: number;
  filters?: {
    field: string;
    value: Filter['value'];
  }[];
};
export function getTalents(params?: GetTalentsParams) {
  const filters = filterToQueryParams(params?.filters ?? [], { removeEmpty: true });
  return request.get<PaginatedResponse<TalentsResponseItem[]>>('/v2/staff', {
    params: {
      pageNumber: params?.page,
      pageSize: params?.size,
      orderBy_in: params?.orderBy?.map((p) => {
        return `${p.field}_${p.sort}`;
      }),
      search: params?.search || '',
      ...filters,
    },
  });
}

export type TalentsResponseItem = {
  id: string;
  staffingType: 'Internal' | 'Agency' | 'DirectContractor';
  imageUrl?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  staffingStatus: 'Active' | 'Deactivated';
  nurseQualifications: Qualification[];
  specialities: {
    id: string;
    name: string;
    category: string;
  }[];
  lastWorkedTime: string;
};

import { create } from 'zustand';
import { Dayjs } from 'dayjs';
import { TIMEZONE_FALLBACK } from '@libs/config';
import { ShiftList } from '@libs/models/shifts/shift';
import { getEvents } from '@libs/api/shifts';

const FallbackTimezone = TIMEZONE_FALLBACK;

type DateFilterValue = { from?: Dayjs; to?: Dayjs; timezone: string };

type State = {
  loading: boolean;
  drawerOpen: boolean;
  rotationId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  shiftsToPublish: ShiftList[];
  loadPublishableShifts: (dateFilter: DateFilterValue | null, locationId: string) => void;
  openShiftPublishDrawer: (id?: string) => void;
  closeShiftPublishDrawer: () => void;
};

export const usePublishDrawerStore = create<State>()((set) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  rotationId: '',
  shiftsToPublish: [],
  loadPublishableShifts: async (dateFilter, locationId) => {
    set({ loading: true });

    try {
      const timezone = dateFilter?.timezone || FallbackTimezone;

      const resp = await getEvents({
        timezone,
        startDate: dateFilter?.from,
        endDate: dateFilter?.to,
        filters: [
          { key: 'status', value: ['Opened'] },
          { key: 'locations', value: [locationId] },
        ],
      });
      set({
        loading: false,
        shiftsToPublish: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftPublishDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', rotationId: id, drawerOpen: true });
  },
  closeShiftPublishDrawer: () => {
    set({ drawerOpen: false });
  },
}));

import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { paperClasses } from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { cancellationReasons } from '@libs/models/shifts';
import { LoadingButton, Button } from '@atoms/buttons';
import { Select, TextField } from '@atoms/inputs';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';

type CancelDialogProps = Omit<DialogProps, 'onSubmit'> & {
  onSubmit?: (reason: string, reasonComment: string) => void;
  loading?: boolean;
};
export function CancelDialog({ sx, onSubmit, loading, ...props }: CancelDialogProps) {
  const theme = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      sx={{
        [`& .${paperClasses.root}`]: {
          bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
        },
        ...sx,
      }}
      {...props}
    >
      <Content onCloseClick={() => props.onClose?.({}, 'escapeKeyDown')} onSubmit={onSubmit} loading={loading} />
    </Dialog>
  );
}

type ContentProps = {
  onCloseClick: () => void;
  onSubmit?: (reason: string, reasonComment: string) => void;
  loading?: boolean;
};
function Content({ onCloseClick, onSubmit, loading = false }: ContentProps) {
  const intl = useIntl();
  const [reason, setReason] = useState('');
  const [reasonComment, setReasonComment] = useState('');
  const validValue = reason === 'custom' ? reasonComment !== '' : reason !== '';

  return (
    <>
      <DialogTitle sx={{ px: 3, pt: 4, pb: 2 }}>
        <Flex justifyContent="space-between">
          <Flex column gap={1}>
            <Typography variant="h5">
              <FormattedMessage id="shifts.cancelDialog.title" />
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <FormattedMessage id="shifts.cancelDialog.subtitle" />
            </Typography>
          </Flex>
          <Box>
            <IconButton sx={{ width: '40px', position: 'relative', top: -5, right: -5 }} onClick={onCloseClick}>
              <FaIcon name="xmark" variant="light" />
            </IconButton>
          </Box>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Flex mt={2}>
          <Select
            options={cancellationReasons.map((r) => ({
              value: r.id,
              label: r.label,
              key: r.id,
            }))}
            value={reason}
            size="small"
            required
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            label={<FormattedMessage id="shifts.cancelDialog.reasonLabel" />}
            placeholder={intl.formatMessage({
              id: 'shifts.cancelDialog.reasonPlaceholder',
            })}
          />
        </Flex>
        <Collapse in={reason === 'custom'} timeout={150}>
          <Box mt={2}>
            <TextField
              value={reasonComment}
              onChange={(e) => setReasonComment(e.target.value)}
              label={<FormattedMessage id="shifts.cancelDialog.reasonCommentLabel" />}
              placeholder={intl.formatMessage({
                id: 'shifts.cancelDialog.reasonCommentPlaceholder',
              })}
              required
              fullWidth
            />
          </Box>
        </Collapse>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 0 }}>
        <Box color="text.secondary">
          <Button variant="text" color="inherit" rounded onClick={onCloseClick} size="large">
            <FormattedMessage id="common.cancel" />
          </Button>
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          rounded
          onClick={() => onSubmit?.(reason, reasonComment)}
          size="large"
          disabled={!validValue}
        >
          <FormattedMessage id="common.submit" />
        </LoadingButton>
      </DialogActions>
    </>
  );
}

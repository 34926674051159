import dayjs, { Dayjs } from 'dayjs';
import { FormattedTime } from 'react-intl';

type TimeRangeProps = {
  from: Dayjs;
  to: Dayjs;
  timeDisplay?: 'full' | 'short';
};
export function TimeRange({ from, to, timeDisplay = 'full' }: TimeRangeProps) {
  return (
    <>
      {timeDisplay === 'short' ? dayjs(from).format('h A') : <FormattedTime value={from.toDate()} />}
      {' - '}
      {timeDisplay === 'short' ? dayjs(to).format('h A') : <FormattedTime value={to.toDate()} />}
    </>
  );
}

import { ComponentProps, useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Avatar, LightChip } from '@atoms/dataDisplay';
import { Stack } from '@mui/material';
import { StaffingPartnerList } from '@libs/models/staffingPartners';

export function useStaffingPartnersListColumns() {
  const intl = useIntl();
  // Columns should be memoized to prevent table flickering and state loss
  return useMemo(() => {
    const columns: GridColDef<StaffingPartnerList>[] = [
      getAgencyNameColumn(intl),
      getRegionColumn(intl),
      getMainContactColumn(intl),
      getPhoneColumn(intl),
      getEmailColumn(intl),
      getInviteStatusColumn(intl),
    ];
    return columns;
  }, [intl]);
}

export function useStaffingPartnersShortListColumns() {
  const intl = useIntl();
  // Columns should be memoized to prevent table flickering and state loss
  return useMemo(() => {
    const columns: GridColDef<StaffingPartnerList>[] = [getAgencyNameColumn(intl), getInviteStatusColumn(intl)];
    return columns;
  }, [intl]);
}

function getAgencyNameColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  return {
    field: 'name',
    headerName: intl.formatMessage({ id: 'staffingPartners.table.fields.agencyName.heading' }),
    minWidth: 160,
    resizable: true,
    display: 'flex',
    flex: 1,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      const row = params.row as StaffingPartnerList;
      return (
        <Stack
          direction={'column'}
          sx={{ height: '100%', overflowX: 'hidden', textOverflow: 'ellipsis' }}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Stack direction={'row'} spacing={1}>
            <Avatar
              sx={{
                width: 32,
                height: 32,
              }}
              src={row.icon}
              fullName={row.name}
              alt={row.name}
            />
            <Stack direction={'column'}>
              <Typography variant="subtitle2" noWrap>
                {row.name}
              </Typography>
              <Typography variant="caption" noWrap color="text.secondary">
                <FormattedMessage id={`staffingPartners.relations.${row.relationType}`} />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    },
  };
}
function getMainContactColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  return {
    field: 'contact',
    headerName: intl.formatMessage({ id: 'staffingPartners.table.fields.mainContact.heading' }),
    minWidth: 160,
    resizable: true,
    display: 'flex',
    flex: 1,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      return (
        <Stack
          direction={'column'}
          sx={{ height: '100%', overflowX: 'hidden', textOverflow: 'ellipsis' }}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Stack direction={'row'} spacing={1}>
            <Avatar
              sx={{
                width: 32,
                height: 32,
              }}
              src=""
              // src={params.row.contact.avatar}
              fullName={params.row.contact.name}
              alt={params.row.contact.name}
            />
            <Stack direction={'column'}>
              <Typography variant="subtitle2" noWrap>
                {params.row.contact.name}
              </Typography>
              <Typography variant="caption" noWrap color="text.secondary">
                {params.row.contact.title ?? ''}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    },
  };
}
function getRegionColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  return {
    field: 'region',
    headerName: intl.formatMessage({
      id: 'staffingPartners.table.fields.region.heading',
    }),
    width: 160,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      return `${params.value}`;
    },
  };
}
function getPhoneColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  return {
    field: 'phone',
    headerName: intl.formatMessage({
      id: 'staffingPartners.table.fields.phone.heading',
    }),
    width: 160,
    renderCell: (params) => {
      if (!params.row) {
        return null;
      }
      const row = params.row as StaffingPartnerList;
      return `${row.contact.phoneNumber}`;
    },
  };
}
function getEmailColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  return {
    field: 'email',
    headerName: intl.formatMessage({
      id: 'staffingPartners.table.fields.email.heading',
    }),
    width: 160,
    renderCell: (params) => {
      if (!params.row) {
        return null;
      }
      const row = params.row as StaffingPartnerList;
      return `${row.contact.email}`;
    },
  };
}
function getInviteStatusColumn(intl: IntlShape): GridColDef<StaffingPartnerList> {
  const statusColors: Record<StaffingPartnerList['invitationStatus'], ComponentProps<typeof LightChip>['color']> = {
    Active: 'success',
    Pending: 'warning',
    Disconnected: 'error',
    Invited: 'info',
    OnReview: 'default',
  };
  return {
    field: 'invitationStatus',
    headerName: intl.formatMessage({
      id: 'staffingPartners.table.fields.status.heading',
    }),
    width: 160,
    renderCell: (params) => {
      if (!params.row) {
        return null;
      }
      const row = params.row as StaffingPartnerList;
      return (
        <Chip
          color={statusColors[row.invitationStatus]}
          label={intl.formatMessage({ id: `staffingPartners.statuses.${row.invitationStatus}` })}
        />
      );
    },
  };
}

import { request } from '../request';
import { Dayjs } from 'dayjs';
import { Shift, SingleShift, ShiftList } from '@libs/models/shifts/shift';
import { TIMEZONE_FALLBACK } from '@libs/config';
import { Filter } from '@libs/models/common';
import { filterToQueryParams } from '../utils/filterToQueryParams';

const FallbackTimezone = TIMEZONE_FALLBACK;

export function createSingleShift(data: SingleShiftEditData) {
  return request.post<void>('/v2/shifts/single', {
    ...data,
    specialityId: data.specialty || null,
    subDepartmentNodeId: data.subDepartmentNodeId || null,
    startDate: { dateTime: data.startDate, timezone: data.timeZone },
  });
}

export type SingleShiftEditData = Omit<SingleShift, 'startDate'> & {
  startDate: string;
  specialty?: string;
  unassignReason?: string;
};

export async function updateSingleShift(shiftId: string, data: SingleShiftEditData) {
  return request.patch<void>(`/v2/shifts/${shiftId}`, {
    staffingType: data.staffingType,
    date: {
      startDate: { dateTime: data.startDate, timezone: data.timeZone },
      durationInSeconds: data.durationInSeconds,
      timeZone: data.timeZone,
    },
    staffNurseQualificationFilterIds: data.staffNurseQualificationFilterIds,
    speciality: {
      isEmpty: !data.specialityId,
      id: data.specialityId || null,
    },
    subDepartment: {
      isEmpty: !data.subDepartmentNodeId,
      id: data.subDepartmentNodeId || null,
    },
    assignee: {
      isEmpty: !data.assignees.length,
      id: data.assignees.length ? data.assignees[0] : null,
      unassignReason: data.unassignReason,
    },
    departmentNodeId: data.departmentNodeId,
    locationId: data.locationId,
    tags: data.tags.reduce((acc, elem) => {
      if (elem) {
        acc.push(elem);
      }
      return acc;
    }, [] as string[]),
    note: data.note,
    isUrgent: data.isUrgent,
  });
}

export async function getSingleShift(shiftId: string): Promise<Shift> {
  const resp = await request.get(`/v2/shifts/${shiftId}`);

  return {
    ...resp.data,
    internalId: resp.data.externalId,
    startDate: resp.data.startDate.dateTime,
    endDate: resp.data.endDate.dateTime,
    timeZone: resp.data.startDate.timeZone,
  };
}

export function cancelSingleShift(shiftId: string, reason: string = '') {
  return request.post<void>(`/v2/shifts/cancel/${shiftId}`, {
    cancellingReason: reason,
  });
}

export function deleteSingleShift(shiftId: string) {
  return request.delete<void>(`/v2/shifts/${shiftId}`);
}

export function shareShifts(shiftIds: string[], agenciesUrls: string[]) {
  return request.post<void>(`/v2/shifts/share`, {
    shiftIds: shiftIds,
    axTenants: agenciesUrls,
    allowSharePastShifts: true,
  });
}

export async function publishShifts(shiftIds: string[]) {
  return request.post<void>(`/v2/shifts/publish`, shiftIds);
}

export async function getEvents(params: { startDate?: Dayjs; endDate?: Dayjs; timezone: string; filters?: Filter[] }) {
  const { timezone, startDate, endDate, filters = [] } = params;
  const applyFilters = filterToQueryParams(
    filters.map((filter) => ({
      field: filter.key === 'specialties' ? 'specialities' : filter.key,
      value: filter.value,
    })),
  );

  const response = await request.get<ShiftList[]>('/v3/calendar/events', {
    params: {
      ...applyFilters,
      startDate: startDate?.toServerFilterDate(),
      endDate: endDate?.toServerFilterDate(),
      timeZone: timezone || FallbackTimezone,
    },
  });

  response.data = response.data.map((evt: any) => ({
    ...evt,
    recurrence: evt.recurrence || 'single',
    nurseQualifications: evt.staffNurseQualificationFilters,
    otherQualifications: evt.staffOtherQualificationFilters,
    timezone: evt.startDate.timeZone,
    startDate: evt.startDate.dateTime,
    endDate: evt.endDate.dateTime,
  }));
  return response;
  // const fake = async () =>
  //   new Promise<{ data: ShiftList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

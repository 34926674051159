export const EDIT_ALLOWED_STATUSES = [
  'opened',
  'pending',
  'reopened',
  'unassigned',
  'unfilled',
  'assigned',
  'inprogress',
  'completed',
  'canceled',
  'reassigned',
];
export const UNASSIGN_ALLOWED_STATUSES = ['assigned', 'reassigned'];
export const CANCEL_ALLOWED_STATUSES = ['reassigned', 'assigned', 'unassigned', 'pending', 'unfilled'];
export const DELETE_ALLOWED_STATUSES = ['opened'];

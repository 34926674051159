import { create } from 'zustand';
import dayjs from 'dayjs';
import { SHIFT_STATUSES, ShiftOld } from '@libs/models/shifts/shift';
import { TIMEZONE_FALLBACK } from '@libs/config';
import { fetchShiftsList } from '@libs/api/shifts';
import { Filter } from '@libs/models/common';

type State = {
  loading: boolean;
  initiated: boolean;
  data: ShiftOld[];
  page: number;
  total: number;
  perPage: number;
  activeFilters: Filter[];
  loadShifts: (page: number, perPage: number, filters?: Filter[], timezone?: string) => Promise<void>;
  reloadCurrentFilter: (timezone?: string) => Promise<void>;
  showDetails: boolean;
  currentShift: ShiftOld | null;
  openShiftDetails: (id: string) => void;
  setCurrentShift: (id: string) => void;
  closeShiftDetails: () => void;
};

export const useShiftsListStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  data: [],
  page: 1,
  activeFilters: [],
  perPage: 10,
  total: 0,

  loadShifts: async (page, perPage, filters = [], timezone) => {
    set({ loading: true });
    const applyFilters = [...filters];
    // add filter for Deleted status, if it's not already there
    if (!applyFilters.find((f) => f.key === 'statuses')) {
      applyFilters.push({
        key: 'status',
        value: [
          SHIFT_STATUSES.ASSIGNED,
          SHIFT_STATUSES.CANCELED,
          SHIFT_STATUSES.COMPLETED,
          // SHIFT_STATUSES.DELETED,
          SHIFT_STATUSES.EXPIRED,
          SHIFT_STATUSES.IN_PROGRESS,
          SHIFT_STATUSES.OPENED,
          SHIFT_STATUSES.PENDING,
          SHIFT_STATUSES.REASSIGNED,
          SHIFT_STATUSES.UNASSIGNED,
          SHIFT_STATUSES.UNFILLED,
        ],
      });
    }

    const hasDateFilter = applyFilters.find((f) => f.key === 'startDate');
    if (!hasDateFilter) {
      applyFilters.push({
        key: 'startDate',
        value: { timezone: timezone ?? '', from: dayjs() },
      });
    }

    try {
      const data = await fetchShiftsList(page, perPage, applyFilters);
      set({
        loading: false,
        data: data.items,
        activeFilters: filters,
        page,
        perPage,
        total: data.total,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  reloadCurrentFilter: async (timezone) => {
    const { page, perPage, activeFilters } = get();
    set({ loading: true });
    try {
      const applyFilters = [...activeFilters];
      const hasDateFilter = applyFilters.find((f) => f.key === 'startDate');
      if (!hasDateFilter) {
        applyFilters.push({
          key: 'startDate',
          value: { timezone: timezone ?? fallbackTimezone, from: dayjs() },
        });
      }

      const data = await fetchShiftsList(page, perPage, applyFilters);
      set({
        loading: false,
        data: data.items,
        page,
        activeFilters,
        perPage,
        total: data.total,
      });
    } finally {
      set({ loading: false });
    }
  },
  currentShift: null,
  showDetails: false,
  setCurrentShift: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift });
  },
  openShiftDetails: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift, showDetails: true });
  },
  closeShiftDetails: () => {
    set({ showDetails: false });
  },
}));

const fallbackTimezone = TIMEZONE_FALLBACK;

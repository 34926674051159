import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from '@uidotdev/usehooks';
import Menu, { menuClasses } from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { SearchField } from '@atoms/inputs';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { FilterItem, FilterOption } from './filterItem';
import { FilterMenuItem } from './filterMenuItem';

type FilterMenuButtonProps = {
  items: FilterItem[];
  onFilterApplied?: (key: string, value: string[], label?: string) => void;
  loading?: boolean;
};
export function FilterMenuButton({ items, onFilterApplied, loading }: FilterMenuButtonProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Rest selected submenu on opening to prevent flickering while closing
    setCurrentItem(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [currentItem, setCurrentItem] = useState<FilterItem | null>(null);

  return (
    <>
      <Button
        id="filter-button"
        aria-controls={open ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        rounded
        disabled={!!loading}
        startIcon={
          <FaIcon
            name="plus"
            fontSize={13}
            color={!!loading ? theme.palette.text.disabled : theme.palette.primary.main}
          />
        }
      >
        <FormattedMessage id="common.filter" />
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-button',
        }}
        sx={{ [`& .${menuClasses.list}`]: { minWidth: '320px' } }}
      >
        {currentItem ? (
          <div>
            <Flex
              column
              sx={{
                borderBottom: `1px solid ${theme.palette.action.hover}`,
                pb: 1,
              }}
            >
              <MenuItem
                onClick={() => setCurrentItem(null)}
                sx={{
                  color: theme.palette.primary.main,
                  height: '40px',
                  pl: 2.5,
                }}
              >
                <FaIcon name="angle-left" color={theme.palette.primary.main} />
                <Box ml={2}>
                  <FormattedMessage id="common.back" />
                </Box>
              </MenuItem>
            </Flex>
            {currentItem.renderChildren ? (
              currentItem.renderChildren({
                onClose: handleClose,
              })
            ) : (
              <FilterMenuOptions
                options={currentItem.children ?? []}
                onApplyClick={(value) =>
                  onFilterApplied?.(
                    currentItem.key,
                    currentItem.transformFilter?.(value) ?? value,
                    currentItem.valueToLabel?.(value),
                  )
                }
                isMultiple={currentItem.multiple ?? false}
                onClose={handleClose}
                searchable={currentItem.searchable}
              />
            )}
          </div>
        ) : (
          <FilterMenu items={items} onItemClick={(item) => setCurrentItem(item)} />
        )}
      </Menu>
    </>
  );
}

type FilterMenuProps = {
  items: FilterItem[];
  onItemClick: (item: FilterItem) => void;
};
function FilterMenu({ items, onItemClick }: FilterMenuProps) {
  return (
    <>
      {items.map((item) => (
        <FilterMenuItem item={item} key={item.key} onClick={() => onItemClick(item)} hasChildren label={item.label} />
      ))}
    </>
  );
}

type FilterMenuOptionsProps = {
  options: FilterOption[];
  isMultiple: boolean;
  onApplyClick: (value: string[]) => void;
  onClose: () => void;
  searchable?: boolean;
};
function FilterMenuOptions({ options, isMultiple, onApplyClick, onClose, searchable }: FilterMenuOptionsProps) {
  const [selected, setSelected] = useState<string[]>(options.filter((o) => o.selected).map((o) => o.key));
  const onItemClick = (item: FilterOption) => {
    if (isMultiple) {
      setSelected((s) => {
        const index = s.indexOf(item.key);
        if (index === -1) {
          return [...s, item.key];
        }
        return [...s.slice(0, index), ...s.slice(index + 1)];
      });
    } else {
      setSelected([item.key]);
    }
  };
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const filtered = filterByWords(options, debouncedSearchTerm);

  return (
    <>
      {searchable && (
        <Flex px={2} py={1} width="100%">
          <SearchField fullWidth value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </Flex>
      )}
      <Flex maxHeight="40vh" overflow="auto" pt={1} column>
        {filtered.map((item) =>
          item.render ? (
            item.render()
          ) : (
            <FilterMenuItem
              item={item}
              key={item.key}
              onClick={() => onItemClick(item)}
              hasChildren={false}
              active={selected.includes(item.key)}
              label={item.label}
            />
          ),
        )}
      </Flex>
      <Flex justifyContent="flex-end" px={2} py={1}>
        <Button
          onClick={() => {
            onClose();
            setSelected([]);
            onApplyClick([]);
          }}
          variant="passive"
        >
          <FormattedMessage id="common.clear" />
        </Button>
        <Button
          onClick={() => {
            onClose();
            onApplyClick(selected);
          }}
        >
          <FormattedMessage id="common.applyFilters" />
        </Button>
      </Flex>
    </>
  );
}

function filterByWords(options: FilterOption[], searchTerm: string) {
  if (searchTerm === '') {
    return options;
  }
  const words = searchTerm.toLowerCase().split(' ');
  return options.filter(({ text = '' }) => words.every((w) => text.toLowerCase().includes(w)));
}

import { BryntumGridProps, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';

export const schedulerConfigFeatureOffs: BryntumSchedulerProProps = {
  zoomOnTimeAxisDoubleClick: false,
  useInitialAnimation: false,
  createEventOnDblClick: false,

  zoomOnMouseWheel: false,

  scheduleMenuFeature: {
    disabled: true,
  },
  showCreationTooltip: false,
  showLagInTooltip: false,
  scheduleTooltipFeature: false,
  showTooltip: false,
  cellTooltipFeature: false,
  labelsFeature: { disable: true },
  timeAxisHeaderMenuFeature: {
    disabled: true,
  },
  cellMenuFeature: {
    disabled: true,
  },
  stickyEventsFeature: {
    disabled: true,
  },
  dependenciesFeature: {
    disabled: true,
  },
  eventResizeFeature: {
    disabled: true,
  },
  eventTooltipFeature: {
    disabled: true,
  },

  stripeFeature: false,

  eventEditFeature: {
    disabled: true,
  },
  eventCopyPasteFeature: {
    disabled: true,
  },
  eventDragCreateFeature: {
    disabled: true,
  },
  cellEditFeature: {
    disabled: true,
  },
  eventDragFeature: {
    disabled: true,
  },
  taskEditFeature: {
    disabled: true,
  },
};

// Custom grid that holds unassigned appointments
export const gridConfigFeatureOffs: BryntumGridProps = {
  cellMenuFeature: {
    disabled: true,
  },
  cellEditFeature: {
    disabled: true,
  },

  disableGridRowModelWarning: true,
};

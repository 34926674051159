import { ENV_TYPE } from '@shiftnex/framework/src/libs/config';

export const API_BASE_URL = import.meta.env.FE_FX_API_BASE_URL as string;
export const FEATURE_FLAG_DEPLOYMENT_KEY = import.meta.env.FE_FX_FEATURE_FLAG_DEPLOYMENT_KEY as string;
export * from '@shiftnex/framework/src/libs/config';

// BUILD INFO
export const BUILD_INFO = {
  BUILD_MODE: import.meta.env.SYSTEM_MODE as string,
  ENV_TYPE,
  WORKING_REGIONS: import.meta.env.SYSTEM_COUNTRY_REGIONS as string,
  API_BASE_URL,
  COMMIT_HASH: import.meta.env.FE_FX_LAST_COMMIT as string,
  COMMIT_DATE: import.meta.env.FE_FX_LAST_COMMIT_DATE as string,
  COMMIT_AUTHOR: import.meta.env.FE_FX_LAST_COMMIT_AUTHOR as string,
  COMMIT_MESSAGE: import.meta.env.FE_FX_LAST_COMMIT_MESSAGE as string,
  COMMIT_TAG: import.meta.env.FE_FX_LAST_COMMIT_TAG as string,
  BUILD_DATE_TIME: import.meta.env.FE_FX_CURRENT_TIME_OF_BUILD as string,
  COMMIT_URL: `https://${(import.meta.env.FE_FX_REPO_GIT_ORIGIN || '').replace(':', '/').replace('https///git@', '').replace('.git', '')}/commit/${import.meta.env.FE_FX_LAST_COMMIT}`,
};

// Logger
export const LOG_LEVEL = import.meta.env.FE_FX_LOG_LEVEL as string;
export const LOG_ENABLED = import.meta.env.FE_FX_LOG_ENABLED as boolean;

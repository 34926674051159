import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import Chip, { chipClasses } from '@mui/material/Chip';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Tooltip from '@mui/material/Tooltip';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { FormAutocompleteMultiple, FormAutocompleteMultipleProps } from '@molecules/form/formAutocompleteMultiple';

export function QualificationSelect({
  localizationSubset,
  name,
  disabledOptions = [],
  ...props
}: Omit<FormAutocompleteMultipleProps<string[]>, 'options' | 'name'> & {
  localizationSubset: string;
  name: string;
  disabledOptions?: string[];
}) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const selected = watch(name);
  const selectedNumber = selected?.length ?? 0;
  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications } = orgQualStore;
  const options =
    orgQualifications?.direct.map((qual) => ({
      key: qual.id,
      label: qual.abbreviation,
      value: qual.id,
    })) ?? [];

  return (
    <FormAutocompleteMultiple
      options={options}
      label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.qualifications.label`} />}
      textFieldProps={{
        placeholder:
          selectedNumber === 0
            ? intl.formatMessage({
                id: `${localizationSubset}.talentConfiguration.qualifications.placeholder`,
              })
            : undefined,
      }}
      renderTags={(selected, getTagProps) =>
        selected.map((s, i) => {
          const tagProps = getTagProps({ index: i });
          const disabled = disabledOptions.includes(s.value.toString());
          return (
            <Chip
              label={s.label}
              {...tagProps}
              deleteIcon={<DeleteQualificationIcon disabled={disabled} />}
              sx={(theme) =>
                disabled
                  ? {
                      [`.${chipClasses.deleteIcon}`]: { color: theme.palette.action.disabled, opacity: 0.38 },
                      [`.${chipClasses.deleteIcon}:hover`]: { color: theme.palette.action.disabled },
                    }
                  : {}
              }
              onDelete={(e) => !disabled && tagProps.onDelete(e)}
              key={s.value}
            />
          );
        })
      }
      lockedOptions={disabledOptions}
      disableCloseOnSelect
      name={name}
      limitTags={6}
      {...props}
    />
  );
}

type DeleteQualificationIconProps = React.ComponentProps<typeof CancelRoundedIcon> & {
  disabled?: boolean;
};
function DeleteQualificationIcon({ disabled, ...props }: DeleteQualificationIconProps) {
  return disabled ? (
    <Tooltip
      title={<FormattedMessage id="shifts.editShiftDrawer.disabledQualificationTooltip" />}
      arrow
      placement="right"
    >
      <CancelRoundedIcon {...props} />
    </Tooltip>
  ) : (
    <CancelRoundedIcon {...props} />
  );
}

import { QualificationPayRates } from '@libs/models/settings';
import { TalentEditData } from '@libs/models/talents';
import { request } from '../request';

export function createInternalTalent(
  data: Omit<TalentEditData, 'qualificationPayRates' | 'status'> & { qualificationPayRate: QualificationPayRates },
) {
  const form = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'image') {
      form.append(key, value ? (value as File) : '');
      return;
    }
    if (key === 'availableForTravelDistance') {
      form.append(key, (value as string) ?? '0');
      return;
    }
    if (typeof value === 'object' && value !== null) {
      form.append(key, JSON.stringify(value));
      return;
    }

    form.append(key, value?.toString() ?? '');
  });

  return request.post<string>('/v2/internal/staff', form);
}

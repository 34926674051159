import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import { ShiftList } from '@libs/models/shifts/shift';
import Chip from '@mui/material/Chip';
import { LightChip } from '.';

export type ShiftStatusChipProps = {
  value: ShiftList['status'];
};
export function ShiftStatusChip({ value }: ShiftStatusChipProps) {
  const colorByStatus: Record<ShiftList['status'], ComponentProps<typeof LightChip>['color']> = {
    Opened: 'info',
    Pending: 'warning',
    Assigned: 'success',
    Reassigned: 'success',
    Canceled: 'error',
    InProgress: 'primary',
    Unfilled: 'warning',
    Deleted: 'default',
    Expired: 'default',
    Unassigned: 'warning',
    Completed: 'default',
  };

  return (
    <Chip
      variant="filled"
      disabled={value === 'Expired'}
      color={colorByStatus[value]}
      label={<FormattedMessage id={`shifts.status.${value.toLowerCase()}`} />}
      size="small"
    />
  );
}

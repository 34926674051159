export const greater = `

███████╗██╗  ██╗
██╔════╝╚██╗██╔╝
█████╗   ╚███╔╝
██╔══╝   ██╔██╗
██║     ██╔╝ ██╗
╚═╝     ╚═╝  ╚═╝

`;

import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRulesToDefaultMessage, getErrorMessage } from '@libs/forms';
import { Select, type SelectProps } from '@atoms/inputs';
import { TooltipNote } from '@atoms/dataDisplay';
import { Box } from '@atoms/layout';

type FormSelectProps<T extends FieldValues> = Omit<SelectProps, 'value'> & {
  name: Path<T>;
  requiredMessage?: boolean;
  registerOptions?: RegisterOptions<T, Path<T>>;
  toolTipLabel?: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | undefined;
};
/**
 * Wrapper around `Select` that integrates with react-hook-form.
 */
export function FormSelect<T extends FieldValues>({
  name,
  requiredMessage,
  registerOptions,
  fullWidth,
  toolTipLabel,
  ...props
}: FormSelectProps<T>) {
  const { required } = props;
  const intl = useIntl();
  const { control } = useFormContext<T>();
  const parsedOptions = useRulesToDefaultMessage(registerOptions);
  const rules = {
    ...parsedOptions,
    ...(required
      ? {
          required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
        }
      : {}),
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <TooltipNote title={toolTipLabel}>
            {/* Span needed to be able to use Tooltip on disabled element */}
            <Box sx={fullWidth ? { width: '100%', overflowX: 'hidden' } : {}}>
              <Select
                {...props}
                fullWidth={fullWidth}
                required={required}
                error={!!error}
                helperText={getErrorMessage(error)}
                {...field}
              />
            </Box>
          </TooltipNote>
        );
      }}
    />
  );
}

import { FormattedMessage } from 'react-intl';
import { Typography, useTheme } from '@mui/material';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';
import { useDreeBetaStore } from '@libs/store/freeBeta';

type LicenseInfoProps = {
  trialDaysLeft?: number;
};
export function LicenseInfo({ trialDaysLeft = 0 }: LicenseInfoProps) {
  const theme = useTheme();
  // @TODO add resolve for the organization payment type
  const isAtFreeBeta = true;
  return <Flex alignCenter>{isAtFreeBeta ? <FreeBeta /> : <Upgrade trialDaysLeft={trialDaysLeft} />}</Flex>;
}

export function Upgrade({ trialDaysLeft = 0 }: LicenseInfoProps) {
  const theme = useTheme();
  return (
    <>
      <Typography variant="body2" color={theme.palette.warning.main} noWrap>
        <FormattedMessage id="license.trial.daysLeft" values={{ days: trialDaysLeft }} />
      </Typography>
      <Box ml={1}>
        <Button size="small" variant="contained" color="warning" rounded sx={{ px: 2 }}>
          <FormattedMessage id="license.trial.headerButtonLabel" />
        </Button>
      </Box>
    </>
  );
}

export function FreeBeta() {
  const theme = useTheme();
  const { setWelcomeDialogOpen } = useDreeBetaStore();
  const localizationSubSet = 'license.freeBeta';

  const handleFreeBetaClick = () => {
    setWelcomeDialogOpen();
  };

  return (
    <>
      <Box ml={1}>
        <Button
          size="small"
          variant="contained"
          color="warning"
          rounded
          sx={{ px: 2, minWidth: '90px' }}
          onClick={handleFreeBetaClick}
        >
          <FormattedMessage id={`${localizationSubSet}.headerButtonLabel`} />
        </Button>
      </Box>
    </>
  );
}

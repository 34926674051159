import { Scheduler } from './scheduler/Scheduler';
import { gridConfig, projectConfig, schedulerConfig } from './config';
import '@bryntum/schedulerpro/schedulerpro.stockholm.css';
import './scheduler/scheduler.scss';
import { Stack, Box } from '@mui/material';
import { Column, DateHelper, Grid, Model } from '@bryntum/schedulerpro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditShiftDrawer } from './drawers/editShift';
import { useShiftAddEditStore, useShiftsAssignment } from '@libs/store/shifts';
import { Shift as SchedulerShiftModel } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { useLocationStore } from '@libs/store/locations';
import { useCalendarShiftsStore } from '@libs/store/shifts/calendarStore';
import { SkeletonProgress } from '@molecules/feedback';
import { useShiftsFilter } from '@libs/store/shifts/shiftsFilter';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { assignShift } from '@libs/api/shifts';
import { ShiftInProgressDrawer } from './drawers/shiftInProgress';
import { EditSeriesDrawer } from './drawers/editSeries';
import { EditRotationDrawer } from './drawers/editRotation';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';
import { CalendarUnassignDialog } from './drawers/calendarUnassign';
import { parseError } from '@libs/api/errors';
import { useNotification } from '@libs/snackbar';
import { Analytics } from '@libs/analytics/amplitude';
import { CalendarFilterBar } from './filtersBar';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShareShifts } from './drawers/shareShifts';
import { PublishShiftsDrawer } from './drawers/publishShifts';
import { usePublishDrawerStore } from '@libs/store/shifts/shiftPublishStore';
import dayjs, { Dayjs } from 'dayjs';
import { useRefreshSyncStore } from '@libs/store/refreshSync';
import {
  CANCEL_ALLOWED_STATUSES,
  DELETE_ALLOWED_STATUSES,
  EDIT_ALLOWED_STATUSES,
  UNASSIGN_ALLOWED_STATUSES,
} from '../constants';
import { Qualification } from '@libs/models/settings';
import { ShiftList } from '@libs/models/shifts/shift';
import { TalentsResponseItem } from '@libs/api/talents';
import { ResourceItem } from './components/resourceItem';
import { CalendarItem } from './components/calendarItem';
import { CancelDialog } from '@organisms/shifts/cancelDialog';
import { outputShiftDataToConsole } from '../helpers';

const WeekDayStartMap: Record<string, number> = {
  Saturday: 6,
  Sunday: 0,
  Monday: 1,
};

export function ShiftsSchedulerPage() {
  const { refreshTrigger, callRefresh } = useRefreshSyncStore();
  const { showSuccessIntl, showError } = useNotification();
  const { drawerOpen: seriesDrawerOpen, openShiftSeriesDrawer } = useShiftSeriesAddEditStore();
  const { drawerOpen: rotationDrawerOpen, openShiftRotationDrawer } = useShiftRotationAddEditStore();
  const { drawerOpen: inProgressDrawerOpen, openInProgressDrawer } = useShiftInProgressStore();
  const { drawerOpen: shiftEditDrawerOpen, openShiftDrawer } = useShiftAddEditStore();
  const { drawerOpen: shareDrawerOpen, openShiftShareDrawer } = useShareDrawerStore();
  const { drawerOpen: publishDrawerOpen, openShiftPublishDrawer } = usePublishDrawerStore();
  const { unassignDialogOpen, openShiftUnassignDialog, cancelShift, deleteShift } = useShiftsAssignment();
  const { current: currentLocation, currentLocationID, loading: locationsLoading } = useLocationStore();
  const currentTimezone = currentLocation?.timezone;
  const {
    initiated: isCalendarInitiallyLoaded,
    eventsList,
    resourcesInitiated,
    resourcesList,
    loadCalendarData,
    assignmentsList,
    assignmentsInitiated,
    flushInitiated,
  } = useCalendarShiftsStore();
  const { activeFilters, setFilter, clearFilters } = useShiftsFilter();

  const {
    loading: qualListLoading,
    initiated: qualListInitiated,
    load: loadQualifications,
  } = useOrgQualificationsStore();

  let loading = !(isCalendarInitiallyLoaded && resourcesInitiated && assignmentsInitiated && qualListInitiated);

  const [dateRange, setDateRange] = useState({
    startDate: DateHelper.startOf(
      new Date(),
      'week',
      undefined,
      WeekDayStartMap[currentLocation?.workingWeekStart || 'Sunday'],
    ),
    endDate: DateHelper.add(
      DateHelper.startOf(new Date(), 'week', undefined, WeekDayStartMap[currentLocation?.workingWeekStart || 'Sunday']),
      7,
      'days',
    ),
  });

  const [projectData, setProjectData] = useState<{
    events: SchedulerShiftModel[];
    resources: Doctor[];
    assignments: any[];
  }>({
    events: [],
    resources: [],
    assignments: [],
  });

  useEffect(() => {
    callRefresh();
  }, [callRefresh]);

  useEffect(() => {
    if (!qualListLoading) {
      loadQualifications();
    }
  }, [loadQualifications]);

  useEffect(() => {
    if (dateRange && currentLocation && currentLocationID) {
      // it is important ti flush all the states before to load new data
      const filters = [...activeFilters];
      filters.push({ key: 'locations', value: [currentLocationID] });
      loadCalendarData(
        dateRange.startDate,
        dayjs(dateRange.endDate).subtract(1, 'day').toDate(),
        currentLocation!.timezone,
        filters,
      );
    }
  }, [
    dateRange.startDate,
    dateRange.endDate,
    currentLocationID,
    isCalendarInitiallyLoaded,
    activeFilters,
    refreshTrigger,
    currentTimezone,
  ]);

  useEffect(() => {
    setProjectData({
      assignments: assignmentsList.map((assig, idx) => ({
        id: idx,
        event: assig.shiftId,
        resource: assig.staffId,
      })),
      resources: resourcesList.map((tal) => ({
        id: tal.id,
        avatar: tal.imageUrl,
        requiredRole: 'employee',
        staffingType: tal.staffingType || 'Agency',
        firstName: tal.firstName,
        lastName: tal.lastName,
        phoneNumber: tal.phoneNumber,
        email: tal.email,
        staffingStatus: tal.staffingStatus,
        specialities: tal.specialities,
        lastWorkedTime: tal.lastWorkedTime,
        nurseQualifications: tal.nurseQualifications,
        calendar: 'allday',
        search: combineSearchStringForTalent(tal),
      })) as unknown as Doctor[],
      events: eventsList.map((evt) => {
        const startDateInLocation = dayjs.tz(evt.startDate, currentLocation?.timezone);
        const endDateInLocation = dayjs.tz(evt.endDate, currentLocation?.timezone);
        const duration = endDateInLocation.diff(startDateInLocation, 'hours');
        const durationToApply = duration > 0 ? duration : 0.1;
        let startDate = new Date(evt.startDate);
        let endDate = new Date(evt.endDate);
        // for legacy cases, to avoid broken data of an old shifts, which was created not in a proper way
        if (+startDate > +endDate) {
          startDate = new Date(evt.startDate);
          endDate = dayjs(evt.endDate).add(1, 'day').toDate();
        }
        return {
          id: evt.id,
          // timeZone: evt.timeZone,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: evt.status,
          nurseQualifications: evt.nurseQualifications,
          otherQualifications: evt.otherQualifications,
          requiredRole: 'employee',
          isUrgent: evt.isUrgent,
          manuallyScheduled: true,
          actualStartDate: evt.startDate,
          actualEndDate: evt.endDate,
          grouping: `${DateHelper.format(new Date(evt.startDate), 'MMM D, YYYY')}`,
          duration: durationToApply,
          search: combineSearchStringForEvent(evt),
        };
      }) as unknown as SchedulerShiftModel[],
    });
  }, [assignmentsList, eventsList, resourcesList]);

  const handleTodayClick = () => {
    const weekStart = DateHelper.startOf(
      new Date(),
      'week',
      undefined,
      WeekDayStartMap[currentLocation?.workingWeekStart || 'Sunday'],
    );
    loading = true;
    flushInitiated();

    setDateRange({
      startDate: weekStart,
      endDate: DateHelper.add(weekStart, 7, 'days'),
    });
    loading = false;
  };

  // sync with location settings
  useEffect(() => {
    handleTodayClick();
  }, [currentLocationID]);

  const handleWeekBackClick = () => {
    setDateRange({
      startDate: DateHelper.add(dateRange.startDate, -7, 'days'),
      endDate: dateRange.startDate,
    });
  };
  const handleWeekForwardClick = () => {
    setDateRange({
      startDate: dateRange.endDate,
      endDate: DateHelper.add(dateRange.endDate, 7, 'days'),
    });
  };

  const handleDayRangeChange = (startDate: Dayjs, endDate: Dayjs) => {
    flushInitiated();
    setDateRange({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  const handlePublishShift = () => {
    openShiftPublishDrawer();
  };

  const handleShareShift = () => {
    openShiftShareDrawer();
  };

  const handleNewShift = () => {
    openShiftDrawer();
  };

  const openEditPopup = (shift: SchedulerShiftModel) => {
    if (EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
      // open edit mode drawer
      shift.id && openShiftDrawer(shift.id + ''); // edit mode
    }
  };
  const handleShiftDetailsOpen = (shift: SchedulerShiftModel) => {
    openEditPopup(shift); // try to open up edit

    if (['Assigned', 'InProgress', 'Completed'].includes(shift.status)) {
      // open "in progress" view drawer
      // openInProgressDrawer();
    }
  };

  const handleCreateRotation = () => {
    openShiftRotationDrawer();
  };
  const handleCreateSeries = () => {
    openShiftSeriesDrawer();
  };

  const assignDebounced = debounce_leading(
    (async (resource: Doctor, shifts: SchedulerShiftModel[]) => {
      // commit to the store of events that event is assigned
      for (let i = 0; i < shifts.length; i++) {
        shifts[i].assign(resource);
      }
      // call assign at backend for each of events
      for (let i = 0; i < shifts.length; i++) {
        await assignShift(shifts[i].id as string, resource.id as string);
      }
      // finally call the refresh of the data, coming from server
      callRefresh();
    }) as () => Promise<void>,
    10,
  ) as (resource: Doctor, shifts: SchedulerShiftModel[]) => void;

  const handleAssign = useCallback(
    (resource: Doctor, shifts: SchedulerShiftModel[]) => {
      assignDebounced(resource, shifts);
    },
    [useCallback],
  );

  const handleUnassign = (shift: SchedulerShiftModel) => {
    openShiftUnassignDialog(shift.id as string);
  };

  const [opedCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancellingShift, setCancellingShift] = useState<SchedulerShiftModel | null>(null);
  const handleOpenCancelDialog = (shift: SchedulerShiftModel) => {
    setCancellingShift(shift);
    setOpenCancelDialog(true);
  };
  const handleCancel = (reason: string, reasonComment: string) => {
    if (cancellingShift && cancellingShift.id) {
      cancelShift(cancellingShift.id as string, reason === 'custom' ? reasonComment : reason)
        .then(() => {
          callRefresh();
          setOpenCancelDialog(false);
          showSuccessIntl('shifts.unassignSuccessful');

          Analytics.track('Shift: Canceled', {
            shift_id: cancellingShift.id,
            provider_qualification:
              cancellingShift.nurseQualifications.join(', ') + cancellingShift.otherQualifications.join(', '),
          });
        })
        .catch((error: unknown) => showError(parseError(error).message));
    }
  };

  const handleDelete = (shift: SchedulerShiftModel) => {
    deleteShift(shift.id as string)
      .then(() => {
        callRefresh();
        showSuccessIntl('shifts.deleteSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };

  const handleClearFilters = () => {
    if (currentLocationID) {
      clearFilters();
      setFilter({ key: 'locations', value: [currentLocationID] });
    }
  };

  const handleShowShiftDetailsInConsole = (evt: { record: SchedulerShiftModel; event: MouseEvent }) => {
    if (evt.event.ctrlKey) {
      outputShiftDataToConsole(evt.record);
    }
  };

  return (
    <>
      <CalendarFilterBar
        loading={loading}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        weekStartDay={WeekDayStartMap[currentLocation?.workingWeekStart || 'Sunday']}
        onToday={handleTodayClick}
        onWeekBack={handleWeekBackClick}
        onWeekForward={handleWeekForwardClick}
        onNewShift={handleNewShift}
        onNewRotation={handleCreateRotation}
        onNewSeries={handleCreateSeries}
        onListRefresh={callRefresh}
        onPublish={handlePublishShift}
        onShare={handleShareShift}
        onClearFilters={handleClearFilters}
        onDateRangeChange={handleDayRangeChange}
      />
      {loading ? (
        <Stack direction={'column'} height={'100%'}>
          <Box flex={1} />
          <SkeletonProgress show={loading} />
          <Box flex={1} />
        </Stack>
      ) : (
        <Scheduler
          loading={loading}
          onAssign={handleAssign}
          gridConfig={{
            ...gridConfig,
            cellMenuFeature: {
              disabled: false,
              processItems: ({ record, items }) => {
                const shift = record as SchedulerShiftModel;
                if (!EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.editEvent as any).disabled = true;
                }
                if (!CANCEL_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.cancel as any).disabled = true;
                }
                if (!DELETE_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.removeRow as any).disabled = true;
                }
              },
              items: {
                editEvent: {
                  text: 'Edit',
                  disabled: false,
                  weight: 50,
                  onItem: ({ record }: { record: SchedulerShiftModel }) => {
                    openEditPopup(record);
                  },
                },
                cut: false,
                copy: false,
                paste: false,
                filterMenu: false,
                splitGrid: false,
                splitHorizontally: false,
                splitVertically: false,
                splitBoth: false,
                search: false,
                cancel: {
                  text: 'Cancel',
                  disabled: false,
                  onItem: ({ record }: { record: SchedulerShiftModel }) => {
                    handleOpenCancelDialog(record);
                  },
                },
                removeRow: {
                  text: 'Delete',
                  disabled: false,
                  onItem: ({ record }: { record: SchedulerShiftModel }) => {
                    handleDelete(record);
                  },
                },
                splitEvent: false,
                renameSegment: false,
              },
            },
            onCellDblClick: (evt) => {
              handleShowShiftDetailsInConsole({ ...evt, record: evt.record as SchedulerShiftModel });
              handleShiftDetailsOpen(evt.record as SchedulerShiftModel);
            },
            onCellClick: (evt) => {
              handleShowShiftDetailsInConsole({ ...evt, record: evt.record as SchedulerShiftModel });
              handleShiftDetailsOpen(evt.record as SchedulerShiftModel);
            },
          }}
          // timeZone={currentTimeZone?.name}
          projectConfig={{
            ...projectConfig,
            ...projectData,
            // resources: talentInternal,
            // events: eventsInternal,
            // assignments,
            // eventsData: eventsInternal,
            // resourcesData: talentInternal,
            // assignmentsData: assignments,
            timeRanges: [
              {
                id: 1, // will highlight the Today
                cls: 'todayColumnHighlight',
                name: DateHelper.format(new Date(), 'ddd DD'),
                startDate: DateHelper.startOf(new Date(), 'day'),
                duration: 1,
                durationUnit: 'day',
                style: 'background: #ecf2fc',
              },
            ],
          }}
          schedulerConfig={{
            ...schedulerConfig,
            startDate: dateRange.startDate,
            // timeZone: currentTimeZone?.currentTimeOffsetInMinutes,
            endDate: dateRange.endDate,
            // columns: columnConfig,
            onEventClick: (evt) => {
              handleShowShiftDetailsInConsole({ event: evt.event, record: evt.eventRecord as SchedulerShiftModel });
              handleShiftDetailsOpen(evt.eventRecord as SchedulerShiftModel);
            },
            onEventMouseUp: (evt) => {
              handleShowShiftDetailsInConsole({ event: evt.event, record: evt.eventRecord as SchedulerShiftModel });
              // Need to use `onEventMouseUp` instead of `onEventClick` because we call `highlightResourceCalendars`
              // when selection changes (onSelectionChange). This method forces the event to rerender (recreate element).
              // In this case click doesn't work because it needs to be the same element (for MouseDown and MouseUp)
              // for the click event to be triggered.
              if (evt.event.button === 0) {
                handleShiftDetailsOpen(evt.eventRecord as SchedulerShiftModel);
              }
            },
            eventMenuFeature: {
              disabled: false,
              processItems: ({ eventRecord, items }) => {
                const shift = eventRecord as SchedulerShiftModel;
                if (!EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.editEvent as any).disabled = true;
                }
                if (!UNASSIGN_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.unassignEvent as any).disabled = true;
                }
                if (!CANCEL_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.cancelEvent as any).disabled = true;
                }
                if (!DELETE_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                  (items.deleteEvent as any).disabled = true;
                }
              },
              items: {
                editEvent: {
                  text: 'Edit',
                  disabled: false,
                  onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                    openEditPopup(eventRecord);
                  },
                },
                unassignEvent: {
                  text: 'Unassign',
                  disabled: false,
                  onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                    handleUnassign(eventRecord);
                  },
                },
                cutEvent: false,
                copyEvent: false,
                cancelEvent: {
                  text: 'Cancel',
                  disabled: false,
                  onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                    handleOpenCancelDialog(eventRecord);
                  },
                },
                deleteEvent: {
                  text: 'Delete',
                  disabled: false,
                  onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                    handleDelete(eventRecord);
                  },
                },
                splitEvent: false,
                renameSegment: false,
              },
            },
          }}
        />
      )}
      {shiftEditDrawerOpen && (
        <EditShiftDrawer
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {inProgressDrawerOpen && (
        <ShiftInProgressDrawer
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {seriesDrawerOpen && (
        <EditSeriesDrawer
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {rotationDrawerOpen && (
        <EditRotationDrawer
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {unassignDialogOpen && (
        <CalendarUnassignDialog
          onAction={() => {
            callRefresh();
          }}
        />
      )}
      {shareDrawerOpen && (
        <ShareShifts
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {publishDrawerOpen && (
        <PublishShiftsDrawer
          onSaveCall={() => {
            callRefresh();
          }}
        />
      )}
      {opedCancelDialog && (
        <CancelDialog
          open={opedCancelDialog}
          onClose={() => setOpenCancelDialog(false)}
          onSubmit={handleCancel}
          loading={loading}
        />
      )}
      <Box id="schedulerHiddenContainer_resources" sx={{ width: '0px', height: '0px', overflow: 'hidden' }}>
        {projectData.resources.map((tal) => {
          return <ResourceItem data={tal} hoursScheduled={0} />;
        })}
      </Box>
      <Box id="schedulerHiddenContainer_events" sx={{ width: '0px', height: '0px', overflow: 'hidden' }}>
        {projectData.events.map((event) => {
          return <CalendarItem data={event as SchedulerShiftModel} mode="grid" />;
        })}
      </Box>
    </>
  );
}

function debounce_leading(func: () => void, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return (...args: []) => {
    if (!timer) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = undefined;
      }, timeout);
      func.apply(func, args);
    }
  };
}

function combineSearchStringForTalent(tal: TalentsResponseItem) {
  const specs = tal.specialities.map((spec: { name: string }) => spec.name).join(' ');
  const qualNurseList = tal.nurseQualifications.map((qual: Qualification) => `${qual.abbreviation}`).join(' ');
  return [
    tal.firstName,
    tal.lastName,
    tal.phoneNumber,
    tal.email,
    tal.staffingStatus,
    tal.staffingType,
    specs,
    qualNurseList,
  ].join(' ');
}
function combineSearchStringForEvent(evt: ShiftList) {
  const duration = Math.floor(((+new Date(evt.endDate) - +new Date(evt.startDate)) / 1000 / 60 / 60) * 10) / 10;
  const durationToApply = duration > 0 ? duration : 0.1;
  const qualNurseList = evt.nurseQualifications.map((qual) => qual.abbreviation).join(' ');
  const qualOtherList = evt.otherQualifications.map((qual) => qual.abbreviation).join(' ');
  const startDateDisplayed = dayjs(new Date(evt.startDate)).tz(evt.timeZone).format('h a');
  const durationDisplay =
    durationToApply < 1
      ? `${DateHelper.as('minutes', +new Date(evt.endDate) - +new Date(evt.startDate))}min`
      : `${durationToApply}h`;
  return [qualNurseList, qualOtherList, evt.status, startDateDisplayed, durationDisplay].join(' ');
}

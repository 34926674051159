import Toolbar from '@mui/material/Toolbar';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';
import { ContainedIconButton } from '@atoms/buttons';
import { HeaderSelect } from '@molecules/headerSelect';
import { OnboardingProgress } from '@organisms/onboarding';
import { LicenseInfo } from '@organisms/licenseInfo';
import { ProfileMenu } from './profileMenu';
import { useOnboardingStore } from '@libs/store/onboarding';
import { useLocationStore } from '@libs/store/locations';
import { useEffect, useState } from 'react';
import { Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { getTimeZones } from '@vvo/tzdb';
import dayjs from 'dayjs';
import { TIMEZONE_FALLBACK } from '@libs/config';
import { RefreshButton } from './components/refreshButton';

const WeekDayStartMap: Record<string, number> = {
  Saturday: 6,
  Sunday: 0,
  Monday: 1,
};

export const Header = ({ ...props }: AppBarProps) => {
  const { items, completedSteps } = useOnboardingStore();
  const completedStepsCount = Object.values(completedSteps).filter(Boolean).length;
  const onboardingCompleted = completedStepsCount >= items.length;
  const [timezoneLabel, setTimezoneLabel] = useState<string>('');
  const [timezoneTooltip, setTimezoneTooltip] = useState<string>('');
  const [timezone, setTimezoneName] = useState<string>(TIMEZONE_FALLBACK);
  const [tzAbbreviation, setTzAbbreviation] = useState<string>('');
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

  const {
    locationsSimple,
    loadSimple,
    loadingSimple: locationsLoading,
    current: currentLocation,
    currentLocationID,
    setCurrentLocation,
  } = useLocationStore();

  const loading = locationsLoading;
  const txPreparing = loading || !tzAbbreviation || !timezoneLabel;

  useEffect(() => {
    // setup infinite timer as a trigger for state updates
    setInterval(() => {
      setLastUpdate(new Date());
    }, 1000 * 60);
  }, [setLastUpdate]);

  useEffect(() => {
    if (!locationsSimple.length) {
      loadSimple();
    }
  }, [locationsSimple, loadSimple]);

  useEffect(() => {
    if (!currentLocation) {
      setCurrentLocation(currentLocationID);
    }
  }, [currentLocation, currentLocationID]);

  useEffect(() => {
    if (currentLocation) {
      dayjs.updateLocale('en', {
        weekStart: WeekDayStartMap[currentLocation?.workingWeekStart || 'Sunday'],
      });
      const currentTZData = getTimeZones({ includeUtc: true }).find((tz) => tz.name === currentLocation.timezone);
      if (currentTZData) {
        setTimezoneTooltip(currentTZData.currentTimeFormat);
        setTimezoneName(currentTZData.name);
        setTzAbbreviation(currentTZData.abbreviation);
        const shift = dayjs(new Date()).tz(currentTZData.name).format('HH:mm A');
        setTimezoneLabel(`${shift} (${currentTZData.abbreviation})`);
      }
    }
  }, [currentLocation]);

  useEffect(() => {
    const shift = dayjs(new Date()).tz(timezone).format('HH:mm A');
    setTimezoneLabel(`${shift} (${tzAbbreviation})`);
  }, [lastUpdate, tzAbbreviation, timezone]);

  return (
    <AppBar position="fixed" {...props}>
      <Toolbar>
        <Flex justifyContent="space-between" width="100%" alignCenter gap={3}>
          <Stack sx={{ minWidth: '220px' }} spacing={2} direction={'row'}>
            <Box>
              {loading ? (
                <Skeleton variant="rounded" animation="wave" height={48} width={220} />
              ) : (
                <HeaderSelect
                  options={locationsSimple.map((loc) => ({
                    key: loc.id,
                    value: loc.id,
                    label: loc.name,
                  }))}
                  fullWidth
                  sx={{ minWidth: '220px' }}
                  value={currentLocationID ?? ''}
                  onChange={(e) => setCurrentLocation(e.target.value)}
                />
              )}
            </Box>
            <Stack direction={'row'} sx={{ paddingTop: '6px', minWidth: '130px', width: '100%' }}>
              {txPreparing ? (
                <Skeleton width={'150px'} />
              ) : (
                <Tooltip title={timezoneTooltip}>
                  <Typography variant="subtitle1">{timezoneLabel}</Typography>
                </Tooltip>
              )}
            </Stack>
            <RefreshButton />
          </Stack>

          <Flex gap={3} alignCenter>
            {/* Size is hardcoded to be able to make `grow` animation */}
            {!onboardingCompleted && (
              <Box width="180px" height="40px">
                <OnboardingProgress />
              </Box>
            )}
            <LicenseInfo trialDaysLeft={14} />
            <Flex gap={1} alignCenter>
              <ContainedIconButton size="small" sx={{ height: '32px' }}>
                <FaIcon name="bell" />
              </ContainedIconButton>
              <ContainedIconButton size="small" sx={{ height: '32px' }}>
                <FaIcon name="circle-question" />
              </ContainedIconButton>
              <ProfileMenu />
            </Flex>
          </Flex>
        </Flex>
      </Toolbar>
    </AppBar>
  );
};

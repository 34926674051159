import { create } from 'zustand';
import {
  createDepartment,
  getDepartment,
  getOrgDepartmentsTree,
  removeDepartment,
  saveOrgDepartmentsTree,
  updateDepartment,
} from '@libs/api/settings';
import { DEPARTMENT_TYPES, DepartmentDetails, DepartmentTreeEntity, SUB_DEPARTMENT_TYPES } from '@libs/models/settings';

type InitialState = {
  departmentsTreeLoading: boolean;
  departments: DepartmentTreeEntity[];
  departmentsIDPathMap: Record<string, string[]>;

  currentDepartmentID: string | null;
  currentDepartmentLocationId: string | null;
  currentDepartmentLoading: boolean;
  currentDepartmentDetails: DepartmentDetails | null;
  editDepartmentDrawerOpen: boolean;

  currentSubDepartmentId: string | null;
  currentSubDepartmentLoading: boolean;
  currentSubDepartmentDetails: DepartmentDetails | null;
  editSubDepartmentDrawerOpen: boolean;

  currentSubDepartmentLocationId: string | null;
  currentSubDepartmentParentId: string | null;
  currentSubdepartmentParentLoading: boolean;
  currentSubDepartmentParentDetails: DepartmentDetails | null;

  departmentTypes: string[];
  subDepartmentTypes: string[];

  saveTree: (departments: DepartmentTreeEntity[]) => Promise<void>;
  loadTree: () => Promise<void>;
  setDepartments: (departments: DepartmentTreeEntity[]) => void;
  loadDepartment: (id: string) => Promise<void>;
  loadSubDepartment: (id: string) => Promise<void>;
  loadSubDepartmentParent: (id: string) => Promise<void>;
  createDepartment: (deptInfo: DepartmentDetails, locationId?: string) => Promise<void>;
  updateDepartment: (id: string, deptInfo: DepartmentDetails, locationId?: string) => Promise<void>;
  removeDepartment: (id: string) => Promise<void>;
  openEditDepartment: (id: string) => void;
  openAddDepartment: (locationId?: string | null) => void;
  closeDepartmentDrawer: () => void;
  openEditSubDepartment: (id: string, parentId: string) => void;
  openAddSubDepartment: (parentId: string, locationId?: string | null) => void;
  clearDetails: () => void;
};

export const useOrgDepartmentsStore = create<InitialState>()((set) => ({
  departmentsTreeLoading: false,
  departments: [],
  departmentsIDPathMap: {},

  currentDepartmentID: null,
  currentDepartmentLocationId: null,
  currentDepartmentLoading: false,
  currentDepartmentDetails: null,
  editDepartmentDrawerOpen: false,

  currentSubDepartmentId: null,
  currentSubDepartmentLoading: false,
  currentSubDepartmentDetails: null,
  editSubDepartmentDrawerOpen: false,

  currentSubDepartmentParentId: null,
  currentSubDepartmentLocationId: null,
  currentSubdepartmentParentLoading: false,
  currentSubDepartmentParentDetails: null,

  departmentTypes: Object.values(DEPARTMENT_TYPES),
  subDepartmentTypes: Object.values(SUB_DEPARTMENT_TYPES),

  saveTree: async (departments) => {
    if (!departments) return;
    try {
      // TODO: use real ID
      await saveOrgDepartmentsTree('e0426c93-4c30-479a-9c42-b0864f461b60', departments);
    } finally {
      set({ departments: departments });
    }
  },

  loadTree: async () => {
    set({ departmentsTreeLoading: true });
    try {
      // TODO: use real ID
      const resp = await getOrgDepartmentsTree();
      const info = recursiveSetIDPath(resp);
      set({
        departments: info.departments,
        departmentsIDPathMap: info.map,
      });
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  async createDepartment(departmentInfo: DepartmentDetails, locationId?: string) {
    set({ departmentsTreeLoading: true });
    try {
      await createDepartment(departmentInfo, locationId);
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  async updateDepartment(id: string, deptInfo: DepartmentDetails, locationId?: string) {
    set({ departmentsTreeLoading: true });
    try {
      const resp = await updateDepartment(id, deptInfo, locationId);
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  setDepartments: (departments: DepartmentTreeEntity[]) => {
    const info = recursiveSetIDPath(departments);
    set({
      departments: info.departments,
      departmentsIDPathMap: info.map,
    });
  },

  removeDepartment: async (id: string) => {
    set({ departmentsTreeLoading: true });
    try {
      await removeDepartment(id);
      set({ departmentsTreeLoading: false });
    } catch (error) {
      set({ departmentsTreeLoading: false });
      throw error;
    }
  },

  loadDepartment: async (departmentId: string) => {
    set({ currentDepartmentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentDepartmentDetails: resp,
      });
    } finally {
      set({ currentDepartmentLoading: false });
    }
  },
  loadSubDepartment: async (departmentId: string) => {
    set({ currentSubDepartmentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentSubDepartmentDetails: resp,
      });
    } finally {
      set({ currentSubDepartmentLoading: false });
    }
  },
  loadSubDepartmentParent: async (departmentId: string) => {
    set({ currentSubdepartmentParentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentSubDepartmentParentDetails: resp,
      });
    } finally {
      set({ currentSubdepartmentParentLoading: false });
    }
  },

  openEditDepartment: (id: string) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editDepartmentDrawerOpen: true, currentDepartmentID: id });
  },

  openAddDepartment: (locationId: string | null = null) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editDepartmentDrawerOpen: true, currentDepartmentLocationId: locationId });
  },

  openEditSubDepartment: (id: string, parentId: string) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editSubDepartmentDrawerOpen: true, currentSubDepartmentId: id, currentSubDepartmentParentId: parentId });
  },

  openAddSubDepartment: (parentId: string, locationId: string | null = null) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({
      editSubDepartmentDrawerOpen: true,
      currentSubDepartmentParentId: parentId,
      currentSubDepartmentLocationId: locationId,
    });
  },

  closeDepartmentDrawer: () => {
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
  },

  clearDetails: () => {
    set({
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
    });
  },
}));

type RecursiveSetIDPathProps = { departments: DepartmentTreeEntity[]; map: Record<string, string[]> };
export function recursiveSetIDPath(
  departments: DepartmentTreeEntity[],
  parentIDPath: string[] = [],
): RecursiveSetIDPathProps {
  let map: Record<string, string[]> = {};
  const depts: DepartmentTreeEntity[] = departments.map((dept: DepartmentTreeEntity) => {
    const path = parentIDPath.concat([dept.id]);
    map[dept.id] = path;
    let deep;
    if (dept.children) {
      deep = recursiveSetIDPath(dept.children, path);
      map = { ...map, ...deep.map };
    }

    return {
      ...dept,
      children: deep && deep.departments,
    };
  });

  return {
    departments: depts,
    map,
  };
}

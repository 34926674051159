import { FaIcon } from '@atoms/icons';
import { ContainedIconButton } from '@atoms/buttons';

import { Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useRefreshSyncStore } from '@libs/store/refreshSync';
import { FormattedMessage } from 'react-intl';

export const RefreshButton = () => {
  const theme = useTheme();
  const { lastUpdate, callRefresh } = useRefreshSyncStore();
  return (
    <Stack direction={'row'} sx={{ width: '100%' }} spacing={1}>
      <ContainedIconButton
        onClick={() => callRefresh()}
        sx={(theme) => ({ width: '32px', height: '32px', color: theme.palette.primary.main, top: '2px' })}
        aria-label="Refresh"
      >
        <FaIcon width={16} height={16} name="arrows-rotate" color={theme.palette.primary.main} />
      </ContainedIconButton>
      <Stack direction={'column'} sx={{ minWidth: '120px' }}>
        <Typography variant="caption" sx={{ fontSize: '12px' }}>
          <FormattedMessage id={`header.refresh.label`} />
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: '12px' }}
        >{`${dayjs(lastUpdate).format('MMM DD')} at ${dayjs(lastUpdate).format('HH:mm A')}`}</Typography>
      </Stack>
    </Stack>
  );
};

import { useNotification } from '@libs/snackbar';
import { ServerErrorData } from '@libs/utils/error';
import { AxiosError, isAxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseError(
  error: any,
  localizationPrefix: string = '',
  defaultError: string = 'common.errors.base',
): Error {
  if (localizationPrefix) {
    localizationPrefix = `${localizationPrefix}.`;
  }
  if (!(error instanceof Error)) {
    return new Error(`${localizationPrefix}${defaultError}`);
  }
  if (!isAxiosError(error) || !error.response) {
    return error;
  }

  if (!isServerError(error)) {
    return error;
  }

  if (typeof error.response.data === 'string') {
    return new Error(`${localizationPrefix}${error.response.data}`);
  }
  if (typeof error.response.data.title === 'string') {
    return new Error(`${localizationPrefix}${error.response.data.title}`);
  }

  return new Error(`${localizationPrefix}${defaultError}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isServerError(error: any): error is AxiosError<ServerErrorData, any> {
  if (!isAxiosError(error) || !error.response) {
    return false;
  }

  return !!error.response.data;
}

export function useErrorHandling() {
  const { showErrorIntl } = useNotification();

  const handleError = (err: unknown, localizationPrefix?: string, defaultError?: string) => {
    if (isAxiosError(err) && err.response?.status === 500) {
      showErrorIntl('common.errors.base');
      return;
    }
    showErrorIntl(parseError(err, localizationPrefix, defaultError).message);
  };

  return { handleError };
}

import * as amplitude from '@amplitude/analytics-browser';
import { getDeviceId } from './helpers';

type AnalyticsOptions = {
  envType: 'development' | 'production' | 'uat';
};

/**
 * @static
 * @class Analytics
 */
export class Analytics {
  private static options: AnalyticsOptions = {
    envType: 'development',
  };

  static eventProps = {
    ProductID: '',
    TenantID: '',
  };

  static getEventProps() {
    if (!this.eventProps.TenantID) {
      this.eventProps.TenantID = this.resolveTenantId();
    }
    return this.eventProps;
  }

  private static resolveTenantId() {
    if (this.options.envType === 'development' || this.options.envType === 'uat') {
      return this.options.envType;
    }

    return window.location.hostname.split('.')[0];
  }

  static init(token: string | undefined, options: AnalyticsOptions = { envType: 'development' }) {
    if (token) {
      amplitude.setOptOut(false); //just in case
      amplitude.init(token, { autocapture: true });
      amplitude.setDeviceId(getDeviceId());
    } else {
      amplitude.setOptOut(true);
    }
    this.options = { ...this.options, ...options };
  }

  static async track(
    eventInput: string,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitude.Types.EventOptions,
  ) {
    // add any additional prefix\postfix for event names
    const evtName = eventInput;
    // add any default properties for all events around the app
    const evtProps = {
      'Page Domain': window.location.hostname,
      'Page Path': window.location.pathname,
      ...(eventProperties || {}),
    };
    // add any custom event options for all events
    const opts = { ...(eventOptions || {}) };
    return amplitude.track(evtName, evtProps, opts);
  }
}
